import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Section } from '../Components';
import { buyGamePassCTA, freeGamePassExpiryText } from '../../constants';
import { formatDate } from '../../utils/dateConverter';
import './GamePassInventory.scss';

export const GamePassInventoryList = ({ title, kind, list, emptyText, handleActivate }) => (
  <Section title={title} sectionClassName="gamepasses-section">
    {isEmpty(list) ?
      <div className="gamepasses-empty">
        <p className="gamepasses-empty-text">{emptyText}</p>
        <button
          className="gamepasses-empty-button"
          onClick={() => window.location.href = buyGamePassCTA}
        >
          Buy Now
        </button>
      </div>
      : list.map((l, lIdx) => {
          const activateData = {
            pass: l,
            activateKind: kind
          };
          let thumbnailImage = l.image;
          if (l.imageUrls?.thumbUrl) {
            thumbnailImage = l.imageUrls?.thumbUrl;
          }
          return (
            <div className="gamepasses" key={lIdx}>
              <div className="gamepasses-container">
                <div className="gamepasses-pass">
                  <img
                    src={thumbnailImage}
                    className="gamepasses-ticket"
                    alt="gamepass-ticket-mini"
                  />
                  <div className="gamepasses-texts">
                    <p className="gamepasses-name">{l.name}</p>
                    <p className="gamepasses-quantity">Qty: {l.quantity}</p>
                  </div>
                </div>
                <button
                  className="gamepasses-button"
                  onClick={() => handleActivate(activateData)}
                >
                  Activate
                </button>
              </div>
              {l.expiresAt && 
                <p className="gamepasses-expiry">
                  {freeGamePassExpiryText}
                  <b className="gamepasses-expiry-date">{formatDate(l.expiresAt)}</b>
                </p>}
            </div>
          );
        })
      }
  </Section>
);