import React from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import './BlurBackgroundModal.scss';

export const BlurBackgroundModal = ({ open, hide, children, title, hideClose, containerClassName, dialogClassName }) => (
  <Modal
    backdrop
    show={open}
    className={classNames(
      "blur-modal-container",
      {"blur-modal-container-exit": !open}
    )}
    contentClassName={classNames(
      containerClassName,
      "blur-modal",
    )}
    dialogClassName={classNames(
      dialogClassName, 
      "blur-modal-dialog"
    )}
    backdropClassName="blur-modal-bg"
    centered
    onHide={hide}
    autoFocus
  >
    {title &&
      <Modal.Header className="blur-modal-header">
        {!hideClose &&
          <div onClick={hide} className="blur-modal-close" />}
        {title}
      </Modal.Header>}
    <Modal.Body className="blur-modal-body">
      {children}
    </Modal.Body>
  </Modal>
);
