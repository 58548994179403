import { useEffect, useState } from 'react';
import { 
  useDispatch, 
  useSelector 
} from 'react-redux';
import { useNavigate } from 'react-router-dom'
import ReactCountryFlag from 'react-country-flag';
import { 
  LayoutContent, 
  PageTitle, 
  Section 
} from '../Components';
import { getCurrencyConversion } from '../../actions';
import { 
  mgcCurrency, 
  phpCurrency, 
  regexConstants, 
  topUpChannels, 
  topUpDirection, 
  withdrawalCurrency 
} from '../../constants';
import { 
  topUpCurrencySelector, 
  walletCryptoSelector 
} from '../../selectors';
import { getWalletCredits } from '../../utils/getWalletCredits';
import GeneralDropdown from '../Commons/GeneralDropdown/GeneralDropdown';
import './Withdrawal.scss';

export const Withdrawal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const crypto = useSelector(state => walletCryptoSelector(state));

  const creditsObject = getWalletCredits(crypto);
  let creditsValue = null;
  if (creditsObject) creditsValue = creditsObject.val;

  const [currencyValue, setValue] = useState(0);
  const [phBalance, setPHBalance] = useState();
  const [mgcWithdrawal, setMGCWithdrawal] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState(withdrawalCurrency[0]);
  const [withdrawal, setWithdrawal] = useState({
    currency: mgcCurrency,
    icon: <div className="withdrawal-currency-icon" />,
    balance: creditsValue,
    toCurrency: phpCurrency,
    toConvert: 0,
  });

  const currency = useSelector(state => topUpCurrencySelector(state));
  let convertedValue = 0;
  if (currency.converted) {
    convertedValue = currency.converted.value.toString().replace(regexConstants.numberComma, ',');
  }
  let mgcValue = 0;
  if (currency.amountToReceive) {
    mgcValue = currency.amountToReceive.value.toString().replace(regexConstants.numberComma, ',');
  }
  const rate = currency.rate ? currency.rate.value : 0;

  const inputChange = (e) => {  
    let inputValue = 0;
    if (e.target.value) {
      const inputValueString = e.target.value.toString();
      const inputValueTrimmed = inputValueString?.replace(regexConstants.startingZeroes, '');
      inputValue = inputValueTrimmed?.replace(regexConstants.comma, '');
    }
    setValue(inputValue);
  };

  const convertAll = () => {
    let value = phBalance;
    if (mgcWithdrawal) value = creditsValue;
    setValue(value?.replace(regexConstants.comma, ''));
  }

  const switchMethod = (isMGC) => {
    let withdrawal = {
      currency: phpCurrency,
      icon: <ReactCountryFlag countryCode='PH' className="withdrawal-country" svg />,
      balance: phBalance,
      toCurrency: mgcCurrency,
      toConvert: convertedValue
    };
    if (isMGC) {
      withdrawal = {
        currency: mgcCurrency,
        icon: <div className="withdrawal-currency-icon" />,
        balance: creditsValue,
        toCurrency: selectedCurrency.name,
        toConvert: convertedValue,
      };
    }
    setWithdrawal(withdrawal);
    setMGCWithdrawal(!mgcWithdrawal);
  }

  useEffect(() => {
    const direction = mgcWithdrawal ? topUpDirection.to : topUpDirection.from;
    const fromCurrency = phpCurrency;
    const toCurrency = mgcCurrency;
    const channel = topUpChannels.shopeePay;
    let value = currencyValue;
    if (!value && value !== 0) return;
    dispatch(getCurrencyConversion({
      fromCurrency, 
      toCurrency, 
      direction, 
      value, 
      channel
    }));
  }, [dispatch, currencyValue, mgcWithdrawal]);

  // Convert MGC balance to PHP
  useEffect(() => {
    const direction = topUpDirection.to;
    const fromCurrency = phpCurrency;
    const toCurrency = mgcCurrency;
    const channel = topUpChannels.shopeePay;
    let value = creditsValue?.replace(regexConstants.comma, '');
    if (!value && value !== 0) return;
    dispatch(getCurrencyConversion({
      fromCurrency, 
      toCurrency, 
      direction, 
      value, 
      channel
    }));
  }, [dispatch, creditsValue]);

  // Save PH Balance
  useEffect(() => {
    if (!phBalance) {
      setPHBalance(convertedValue);
    } else {
      setWithdrawal({
        ...withdrawal,
        toCurrency: mgcWithdrawal ? selectedCurrency.name : mgcCurrency,
        toConvert: selectedCurrency.name === phpCurrency ? convertedValue : mgcValue,
      })
    }
  }, [convertedValue, selectedCurrency]);
  
  return (
    <LayoutContent>
      <PageTitle title="Withdraw" />
      <Section 
        sectionClassName="gamepass-games-section"
        backButtonRoute
        backRoute={() => navigate(-1)}
        title="Withdraw MGC"
        titleCentered
        text="Back"
      />
      <div className="withdrawal">
        <div className="withdrawal-container">
          <div className="withdrawal-row-container">
            <div className="withdrawal-title">You are converting from:</div>
            <div className="withdrawal-row-container">
              {withdrawal.icon}
              <div className="withdrawal-currency">{withdrawal.currency}</div>
            </div>
          </div>
          <input 
            className="withdrawal-input" 
            value={currencyValue}
            onChange={inputChange}
          />
          <div className="withdrawal-row-container">
            <div className="withdrawal-text"> 
              Balance: 
              <b className="withdrawal-text-balance"> 
                {withdrawal.balance} {withdrawal.currency} 
              </b>
            </div>
            <div 
              className="withdrawal-convert-all" 
              onClick={() => convertAll()}
            > 
              Convert All 
            </div>
          </div>
          <div 
            className="withdrawal-convert-icon-container" 
            onClick={() => switchMethod(!mgcWithdrawal)}
          >
            <div className="withdrawal-convert-icon" />
          </div>
        </div>
        <div>
          <div className="withdrawal-row-container">
          <div className="withdrawal-title">You will withdraw:</div>
          {mgcWithdrawal ?
            <GeneralDropdown 
              options={withdrawalCurrency} 
              title={withdrawalCurrency[0].name}
              icon={withdrawalCurrency[0].icon}
              setSelected={selected => setSelectedCurrency(selected)}
              className="general-dropdown"
            /> : 
            <div className="withdrawal-row-container">
              <div className="withdrawal-currency-icon" />
              <div className="withdrawal-currency">MGC</div>
            </div>}
          </div>
          <div className="withdrawal-title-amount"> 
            {withdrawal.toConvert} 
            <span className="withdrawal-title-currency"> 
              {withdrawal.toCurrency} 
            </span>
          </div>
          {!mgcWithdrawal &&
            <div className="withdrawal-conversion-container"> 
              <div className="withdrawal-conversion-title">Current Rate:</div>
                1 MGC = {rate} PHP
            </div>}
        </div>
        <button className="withdrawal-button" disabled>
          <div className="withdrawal-button-coming-soon">Coming Soon!</div>
          Continue
        </button>
      </div>
    </LayoutContent>
  )
}