import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Tabs, { Tab } from 'react-best-tabs';
import { LayoutContent, PageTitle, Section } from '../Components';
import { Toast } from '../Commons';
import { Rewards } from './Rewards';
import { VoucherInput } from './VoucherInput';
import {
  getRewards,
  setLoading,
  claimItemViaVoucher,
  setItemRedemptionError,
  setDisplayToast
} from '../../actions';
import {
  itemRedemptionErrorSelector,
  rewardsListSelector,
  vouchersToastSelector
} from '../../selectors';
import {
  defaultTimeoutMS,
  maxWidths,
  rewardStatuses
} from '../../constants';
import {
  rewardsCenterBannerTablet,
  rewardsCenterBannerDesktop,
  rewardsCenterBannerMobile
}  from './images';
import 'react-best-tabs/dist/index.css';
import './RewardsCenter.scss';

export const RewardsCenter = () => {
  const tabletDisplay = useMediaQuery({ maxWidth: maxWidths.tablet });
  const mobileDisplay = useMediaQuery({ maxWidth: maxWidths.mobileXS });

  const handleBanner = () => {
    if (mobileDisplay) return rewardsCenterBannerMobile;
    if (tabletDisplay) return rewardsCenterBannerTablet;
    return rewardsCenterBannerDesktop;
  }

  const rewards = useSelector(state => rewardsListSelector(state));
  const toast = useSelector(state => vouchersToastSelector(state));
  const errorMessage = useSelector(state => itemRedemptionErrorSelector(state));
  const hasError = errorMessage !== '';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [voucherCode, setVoucherCode] = useState('');
  const [toastOpen, openToast] = useState(false);

  const backToPreviousPage = () => {
    navigate(-1);
  }

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getRewards());
  }, [dispatch]);

  useEffect(() => {
    if (toast) openToast(true);
  }, [toast]);

  let activeRewards = [];
  let usedRewards = [];
  let expiredRewards = [];
  if (rewards && !isEmpty(rewards)) {
    activeRewards = rewards.filter(r => r.status === rewardStatuses.active);
    usedRewards = rewards.filter(r => r.status === rewardStatuses.used);
    expiredRewards = rewards.filter(r => r.status === rewardStatuses.expired);
  }

  const handleVoucherCode = (e) => {
    setVoucherCode(e.target.value);
    if (hasError) {
      dispatch(setItemRedemptionError(''));
    }
  }

  const claimItem = () => {
    dispatch(setLoading(true));
    dispatch(claimItemViaVoucher(voucherCode));
  }

  const closeToast = () => {
    openToast(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  return (
    <LayoutContent outerClassName="rewards-center-container" innerClassName="rewards-center-inner">
      <PageTitle title="Reward Center" />
      <Section
        sectionClassName="rewards-center-section"
        sectionHeaderClassName="rewards-center-section-header"
        backButtonRoute
        backRoute={backToPreviousPage}
      >
        <img className="rewards-center-banner" src={handleBanner()} alt="banner"/>
        <VoucherInput
          voucherCode={voucherCode}
          handleVoucherCode={handleVoucherCode}
          claimItem={claimItem}
          error={errorMessage}
        />
        <Tabs
          activeTab="1"
          className="rewards-center-tabs"
          ulClassName="rewards-center-tabs-lists"
          activityClassName="rewards-center-tabs-indicator"
        >
          <Tab title="All" className="rewards-center-tab-title">
            <Rewards rewards={rewards} title="All" />
          </Tab>
          <Tab title="Active" className="rewards-center-tab-title">
            <Rewards rewards={activeRewards} title="Active" />
          </Tab>
          <Tab title="Used" className="rewards-center-tab-title">
            <Rewards rewards={usedRewards} title="Used" />
          </Tab>
          <Tab title="Expired" className="rewards-center-tab-title">
            <Rewards rewards={expiredRewards} title="Expired" />
          </Tab>
        </Tabs>
      </Section>
      {toast &&
        <Toast
          toast={toast}
          open={toastOpen}
          hide={closeToast}
        />}
    </LayoutContent>
  );
}