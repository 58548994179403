import { takeLatest, call, put, all, delay, select } from 'redux-saga/effects';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import { phone } from 'phone';
import { requestEmailOTP, requestLogin, requestValidateOTP } from '../api/login';
import { requestSetNewPIN } from '../api/profile';
import {
  TRY_LOGIN,
  SUBMIT_LOGIN,
  SUBMIT_LOGIN_OTP,
  SUBMIT_LOGIN_PIN,
  RESEND_EMAIL_OTP
} from '../actions/constants';
import {
  setLoading,
  displayRegistrationForm,
  loginSuccess,
  loginFailure,
  loginOTPSuccess,
  loginOTPFailure,
  startLoginOTPTimer,
  startEmailLoginOTPTimer,
  setDisplayToast,
  setErrorMessage,
  setSubmitNewPIN,
  getWithdrawNft,
  payGamePassOnCheckout,
  loginData,
  getFlags,
  failedRequests,
  navigateTo,
  getUserProfile,
  setAuthStep,
  enableEmailOTP,
  setEmailOTPToEnabled
} from '../actions';
import {
  defaultTimeoutMS,
  firebaseEvents,
  flagKeys,
  loginTexts,
  partnerRedirectUrl,
  responseStatus,
  storageKeys
} from '../constants';
import {
  isEmailOTPEnabledSelector,
  registrationFormVisibleSelector
} from '../selectors';
import {
  logFirebaseEvent,
  logFirebaseEventWithTimestamp
} from '../utils/logFirebaseEvent';

const signInWithCustomTokenAsync = (auth, sessionToken) => {
  return new Promise((resolve, reject) => {
    signInWithCustomToken(auth, sessionToken)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}

const submitLoginSaga = function* (payload) {
  const registrationFormVisible = yield select(registrationFormVisibleSelector);
  yield put(setDisplayToast());
  try {
    const isEmailOTPEnabled = yield select(isEmailOTPEnabledSelector);
    const { 
      mobileNumber,
      email,
      username,
      earlyAccessCode,
      deviceInfo,
      checkoutData,
      customer,
      recaptchaToken
    } = payload.formData;
    yield put(loginData({}));
    const payloadData = {
      mobnum: mobileNumber,
      email,
      username,
      earlyAccessCode,
      deviceInfo
    };
    let data = payloadData;
    if (recaptchaToken && recaptchaToken !== '') {
      data = { ...payloadData, recaptchaToken };
    }
    const response = yield call(requestLogin, data);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      let event = firebaseEvents.loginInitiate;
      if (!isEmpty(checkoutData)) {
        event = firebaseEvents.initiateBuy;
      } else {
        if (registrationFormVisible) {
          event = firebaseEvents.registerInitiate;
        }
      }
      logFirebaseEventWithTimestamp(event);
      if (response.data.d?.isEmailAvailable) {
        yield put(enableEmailOTP(response.data.d?.isEmailAvailable));
      }
      yield put(loginSuccess(response.data));
      yield put(loginData({ 
        mobnum: mobileNumber,
        recaptchaToken,
        checkoutData,
        customer
      }));
      yield put(startLoginOTPTimer(true));
      yield put(setErrorMessage({}));
      if (isEmailOTPEnabled) {
        yield put(setEmailOTPToEnabled(false));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    // unauthorized
    let failedEvent = firebaseEvents.loginFailed;
    if (registrationFormVisible) {
      failedEvent = firebaseEvents.registerFailed;
    }
    logFirebaseEventWithTimestamp(failedEvent);
    yield put(setLoading(false));
    yield put(loginFailure(e));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true,
        disabled: true,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const tryLoginSaga = function* ({ payload }) {
  yield put(setDisplayToast());
  try {
    const isEmailOTPEnabled = yield select(isEmailOTPEnabledSelector);
    const { 
      mobileNumber,
      email,
      username,
      earlyAccessCode,
      deviceInfo,
      withoutCountryCodeMobnum,
      recaptchaToken
    } = payload;
    const payloadData = {
      mobnum: mobileNumber,
      email,
      username,
      earlyAccessCode,
      deviceInfo
    };
    let data = payloadData;
    if (recaptchaToken && recaptchaToken !== '') {
      data = { ...payloadData, recaptchaToken };
    }
    const response = yield call(requestLogin, data);
    const phoneNumberDetails = phone(mobileNumber);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      logFirebaseEvent(
        firebaseEvents.resentOTPSMS, {
          country_code: phoneNumberDetails.countryCode,
          username: username,
          mobile_number: withoutCountryCodeMobnum
        });
      if (response.data.d?.isEmailAvailable) {
        yield put(enableEmailOTP(response.data.d?.isEmailAvailable));
      }
      yield put(loginSuccess(response.data));
      yield put(startLoginOTPTimer(true));
      yield put(setErrorMessage({}));
      if (isEmailOTPEnabled) {
        yield put(setEmailOTPToEnabled(false));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    if (e.response.status === responseStatus.notFound) {
      // user is not registered yet
      yield put(setLoading(false));
      yield put(displayRegistrationForm(true));
    } else {
      yield put(setLoading(false));
      // TODO: display error (too many requests, etc)
    }
    yield put(setLoading(false));
    yield put(loginFailure(e));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true,
        showErrorToast: true,
        disabled: true,
      }
      yield put(failedRequests(failedData));
    }
  }
}

const resendEmailOTPSaga = function* ({ payload }) {
  try {
    const isEmailOTPEnabled = yield select(isEmailOTPEnabledSelector);
    const { 
      deviceInfo,
      mobileNumber,
      withoutCountryCodeMobnum,
      username,
      recaptchaToken
    } = payload
    const phoneNumberDetails = phone(mobileNumber);
    const payloadData = {
      deviceInfo,
      mobnum: mobileNumber
    };
    let data = payloadData;
    if (recaptchaToken && recaptchaToken !== '') {
      data = { ...payloadData, recaptchaToken };
    }
    const response = yield call(requestEmailOTP, data);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setLoading(false));
      yield put(startEmailLoginOTPTimer(true));
      yield put(setErrorMessage({}));
      logFirebaseEvent(
        firebaseEvents.resentOTPEmail, {
          country_code: phoneNumberDetails.countryCode,
          username: username,
          mobile_number: withoutCountryCodeMobnum
        });
      if (!isEmailOTPEnabled) {
        yield put(setEmailOTPToEnabled(true));
      }
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const submitLoginOTPSaga = function* (payload) {
  const { checkoutData, otp, mobnum, referralCode, customer, withdrawal, route } = payload.params;
  const payloadParams = { otp, mobnum };
  let newParams = payloadParams;
  if (referralCode) {
    newParams.referralCode = referralCode;
  }
  if (!isEmpty(checkoutData)) {
    newParams.mobnum = checkoutData?.mobnum;
  }
  if (!isEmpty(customer)) {
    newParams.mobnum = customer?.phoneNumber;
  }
  try {
    const registrationFormVisible = yield select(registrationFormVisibleSelector);
    const response = yield call(requestValidateOTP, newParams);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      let successEvent = firebaseEvents.loginSuccess;
      if (registrationFormVisible) {
        successEvent = firebaseEvents.registerComplete;
      }
      logFirebaseEventWithTimestamp(successEvent);
      const redirectUri = localStorage.getItem(storageKeys.redirectUri);
      const clientId = localStorage.getItem(storageKeys.clientId);
      Cookies.remove(storageKeys.isDailyRewardsShown);
      Cookies.remove(storageKeys.officialLaunch);
      Cookies.remove(storageKeys.wenLamboOfficialLaunch);
      Cookies.remove(storageKeys.referralCode);
      Cookies.remove(storageKeys.showSetPIN);
      for (let key in storageKeys.switchToApp) {
        Cookies.remove(storageKeys.switchToApp[key]);
      }
      const sessionToken = response.data.token;
      const tokenData = JSON.parse(atob(sessionToken.split('.')[1]));
      localStorage.setItem(storageKeys.sessionToken, sessionToken);
      yield put(loginOTPSuccess(sessionToken));
      if (withdrawal) {
        yield put(getWithdrawNft(withdrawal));
      }
      let redirectRoute = '';
      if (redirectUri && clientId) {
        redirectRoute = `${partnerRedirectUrl}?redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${clientId}&uid=${tokenData.uid}`;
      }
      const auth = getAuth();
      try {
        yield call(signInWithCustomTokenAsync, auth, sessionToken);
        if (isEmpty(checkoutData)) {
          if (redirectRoute) {
            yield put(navigateTo({ path: redirectRoute }));
          } else {
            if (!withdrawal) {
              yield put(getFlags({ key: flagKeys.isGamePassTermsAndConditionsShown, isLogin: true, route }));
              yield put(getUserProfile());
            }
          }
        } else {
          yield put(getFlags({ key: flagKeys.isGamePassTermsAndConditionsShown, isCheckout: true }));
          yield put(payGamePassOnCheckout({ checkoutData, customer }));
        }
        yield put(setLoading(false));
        localStorage.removeItem(storageKeys.isTutorial);
        localStorage.removeItem(storageKeys.redirectUri);
        localStorage.removeItem(storageKeys.clientId);
      } catch (error) {
        yield put(setLoading(false));
        let toastTitle = loginTexts.otp.error.title;
        let toastMessage = loginTexts.otp.error.description;
        if (error.code && error.message) {
          toastTitle = error.code;
          toastMessage = error.message;
        }
        const toast = {
          result: false,
          title: toastTitle,
          message: toastMessage,
        };
        localStorage.setItem(storageKeys.firebaseAuthErrorMessage, JSON.stringify(toast));
      }
      yield delay(defaultTimeoutMS);
      if (localStorage.getItem(storageKeys.firebaseAuthErrorMessage) &&
        localStorage.getItem(storageKeys.firebaseAuthErrorMessage) !== '') {
        const firebaseAuthErrorMessage = JSON.parse(localStorage.getItem(storageKeys.firebaseAuthErrorMessage));
        yield put(setDisplayToast(firebaseAuthErrorMessage));
        localStorage.removeItem(storageKeys.firebaseAuthErrorMessage);
      }
    }
  } catch (e) {
    yield put(setLoading(false));
    yield put(loginOTPFailure(e));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true,
        disabled: true,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const submitLoginPINSaga = function* (payload) {
  const { pin, urlParams, slug } = payload?.params;
  const registrationFormVisible = yield select(registrationFormVisibleSelector);
  yield put(setSubmitNewPIN(true));
  const newParams = { pin };
  try {
    const previousRoute = localStorage.getItem(storageKeys.previousRoute);
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const tokenData = JSON.parse(atob(sessionToken.split('.')[1]));
    const response = yield call(requestSetNewPIN, newParams, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      let newRoute = '';
      const newSessionToken = response.data.token;
      let paramsRoute = `${previousRoute}?email=${urlParams?.email}&code=${urlParams?.voucherCode}`;
      if (isEmpty(urlParams)) {
        paramsRoute = previousRoute;
      }
      let slugRoute = `${previousRoute}?slug=${slug?.slug}`;
      if (isEmpty(slug)) {
        slugRoute = previousRoute;
      }
      newRoute = !isEmpty(slug) ? slugRoute : paramsRoute;
      const auth = getAuth();
      localStorage.setItem(storageKeys.sessionToken, newSessionToken);
      const redirectUri = localStorage.getItem(storageKeys.redirectUri);
      const clientId = localStorage.getItem(storageKeys.clientId);
      let redirectRoute = '';  
      if (redirectUri && clientId) {
        redirectRoute = `${partnerRedirectUrl}?redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${clientId}&uid=${tokenData.uid}`;
      }
      try {
        yield call(signInWithCustomTokenAsync, auth, newSessionToken);
        if (!Cookies.get(storageKeys.slug) && !Cookies.get(storageKeys.dynamicSlug) && registrationFormVisible) {
          localStorage.setItem(storageKeys.isNew, true);
          Cookies.set(storageKeys.isNewlyRegistered, true);
          Cookies.remove(storageKeys.isDailyRewardsShown);
          Cookies.remove(storageKeys.isWalkthroughFinished);
          Cookies.remove(storageKeys.officialLaunch);
          Cookies.remove(storageKeys.wenLamboOfficialLaunch);
          Cookies.remove(storageKeys.showSetPIN);
          for (let key in storageKeys.switchToApp) {
            Cookies.remove(storageKeys.switchToApp[key]);
          }
          localStorage.removeItem(storageKeys.isTutorial);
          localStorage.removeItem(storageKeys.redirectUri);
          localStorage.removeItem(storageKeys.clientId);
        }
        if (redirectRoute) {
          if (previousRoute && previousRoute !== '') {
            if (redirectUri && clientId) {
              window.location.replace(redirectRoute);
            } else {
              yield put(navigateTo({ path: newRoute, replace: true }));
              localStorage.removeItem(storageKeys.previousRoute);
            }
          } else {
            yield put(navigateTo({ path: redirectRoute, replace: true  }));
          }
        } 
        yield put(setAuthStep());
        Cookies.remove(storageKeys.showSetPIN);
        yield put(setLoading(false));
      } catch (error) {
        yield put(setLoading(false));
        let toastTitle = loginTexts.pin.error.title;
        let toastMessage = loginTexts.pin.error.description;
        if (error.code && error.message) {
          toastTitle = error.code;
          toastMessage = error.message;
        }
        const toast = {
          result: false,
          title: toastTitle,
          message: toastMessage
        };
        localStorage.setItem(storageKeys.firebaseAuthErrorMessage, JSON.stringify(toast));
      }
      yield put(setSubmitNewPIN(false));
      yield delay(defaultTimeoutMS);
      if (localStorage.getItem(storageKeys.firebaseAuthErrorMessage) &&
        localStorage.getItem(storageKeys.firebaseAuthErrorMessage) !== '') {
        const firebaseAuthErrorMessage = JSON.parse(localStorage.getItem(storageKeys.firebaseAuthErrorMessage));
        yield put(setDisplayToast(firebaseAuthErrorMessage));
        localStorage.removeItem(storageKeys.firebaseAuthErrorMessage);
      }
    }
  } catch (e) {
    yield put(setLoading(false));
    yield put(setSubmitNewPIN(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* loginSaga() {
  yield all([
    takeLatest(TRY_LOGIN, tryLoginSaga),
    takeLatest(SUBMIT_LOGIN, submitLoginSaga),
    takeLatest(SUBMIT_LOGIN_OTP, submitLoginOTPSaga),
    takeLatest(SUBMIT_LOGIN_PIN, submitLoginPINSaga),
    takeLatest(RESEND_EMAIL_OTP, resendEmailOTPSaga)
  ]);
}
