import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  requestSpendLoadProducts,
  requestSpendLoadProductConfirm,
  requestSpendLoadProductPay,
  requestSpendCountry
} from '../api/spend';
import {
  GET_SPEND_MOBILE_LOAD_PRODUCTS,
  CONFIRM_SPEND_MOBILE_LOAD_PRODUCT,
  PAY_MOBILE_LOAD_PRODUCT,
  GET_SPEND_COUNTRY,
  SUBMIT_SPEND_OTP,
  SEND_SPEND_OTP
} from '../actions/constants';
import {
  setLoading,
  setSpendMobileLoadProducts,
  setSpendMobileLoadProductConfirmation,
  setPaymentStep,
  setSpendLoading,
  setSpendError,
  setSpendCountry,
  startPINOTPTimer,
  setSpendOTPSuccess,
  setSpendReferenceId,
  setSpendLoadingText,
  failedRequests,
  setShowLoading
} from '../actions';
import { requestLogin, requestValidateOTP } from '../api/login';
import { 
  defaultProductsLoadingText, 
  firebaseEvents, 
  mgcCurrency, 
  storageKeys,
  regexConstants,
  responseStatus
} from '../constants';
import { logFirebaseEvent } from '../utils/logFirebaseEvent';

var CryptoJS = require("crypto-js");

const getSpendMobileLoadProductsSaga = function* (payload) {
  yield put(setShowLoading(true));
  yield put(setSpendLoadingText(defaultProductsLoadingText));

  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestSpendLoadProducts, payload.formData, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const { matched_operator, products } = response.data.d;
      const mobileLoadProducts = {
        operator: matched_operator,
        list: products
      };
      yield put(setSpendMobileLoadProducts(mobileLoadProducts));
      yield put(setSpendLoadingText(''));
    }
    yield put(setShowLoading(false));
  } catch (e) {
    yield put(setShowLoading(false));
    yield put(setSpendLoadingText(''));    
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const sendOTPSaga = function* (payload) {
  try {
    const encryptedMobNum = localStorage.getItem(storageKeys.mobileNumber);
    const bytes = CryptoJS.AES.decrypt(encryptedMobNum, storageKeys.mobileNumber.toLowerCase());
    const mobnum = bytes.toString(CryptoJS.enc.Utf8).replace(regexConstants.quotes, '');
    if (mobnum && mobnum !== '') {
      const formData = { mobnum, ...payload.payload };
      const response = yield call(requestLogin, formData);
      if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
        yield put(startPINOTPTimer(true));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const confirmSpendMobileLoadProductSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestSpendLoadProductConfirm, payload.confirmData, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const { d } = response.data;
      const confirmation = {
        productId: d.product.id,
        productAmount: `${d.product.product} ${d.product.product_currency}`,
        priceAmount: `${d.product.price}`,
        paymentAddress: d.payment_wallet_address,
        waived: d.fee.waived,
        fee: `${d.fee.amount} ${d.fee.amount_currency}`
      };
      yield put(setSpendMobileLoadProductConfirmation(confirmation));
      yield put(setPaymentStep(3));
    }
    yield put(setLoading(false));
    yield put(setSpendOTPSuccess(false));
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
    yield put(startPINOTPTimer(true));
    yield put(setSpendOTPSuccess(false));
    yield put(setLoading(false));
  }
}

const payMobileLoadProductSaga = function* (payload) {
  yield put(setSpendLoading(true));
  yield put(setSpendError({
    result: false,
    message: ''
  }));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestSpendLoadProductPay, payload.paymentData, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      logFirebaseEvent(
        firebaseEvents.buyLoad, {
          item_name: payload.paymentData.cur, 
          value: payload.paymentData.total, 
          item_type: 'Load', 
          virtual_currency_name: mgcCurrency
        });
      yield put(setSpendLoading(false));
      yield put(setPaymentStep(4));
      yield put(setSpendReferenceId(response.data.d.referenceId));
    }
  } catch (e) {
    yield put(setPaymentStep(4));
    yield put(setSpendError({
      result: true,
      message: e.response.data.message
    }));
    yield put(setSpendLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getSpendCountrySaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestSpendCountry, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const { d } = response.data;
      yield put(setSpendCountry(d));
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const submitSpendOTPSaga = function* (payload) {
  try {
    const response = yield call(requestValidateOTP, payload.params);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const sessionToken = response.data.token;
      localStorage.setItem(storageKeys.sessionToken, sessionToken);
      yield put(setSpendOTPSuccess(true));
      yield put(setPaymentStep(3));
      yield put(startPINOTPTimer(false));
    }
    yield put(setLoading(true));
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
    yield put(setLoading(false));
  }
}

export default function* spendSaga() {
  yield all([
    takeLatest(GET_SPEND_MOBILE_LOAD_PRODUCTS, getSpendMobileLoadProductsSaga),
    takeLatest(CONFIRM_SPEND_MOBILE_LOAD_PRODUCT, confirmSpendMobileLoadProductSaga),
    takeLatest(PAY_MOBILE_LOAD_PRODUCT, payMobileLoadProductSaga),
    takeLatest(GET_SPEND_COUNTRY, getSpendCountrySaga),
    takeLatest(SUBMIT_SPEND_OTP, submitSpendOTPSaga),
    takeLatest(SEND_SPEND_OTP , sendOTPSaga)
  ]);
}