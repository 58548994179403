import { put, takeLatest, all, call } from 'redux-saga/effects';
import isNil from 'lodash/isNil';
import {
  requestReferralLink,
  requestReferralHistory,
  requestReferralInvite,
  requestReferrerDetails
} from '../api/referral';
import {
  setLoading,
  setReferralLink,
  setReferralCode,
  setReferralShareMessage,
  setReferralHistory,
  setReferrerDetails,
  failedRequests,
  getFlags
} from '../actions';
import {
  GET_REFERRAL_LINK,
  GET_REFERRAL_HISTORY,
  INVITE_FRIEND,
  GET_REFERRER_DETAILS
} from '../actions/constants';
import { 
  flagKeys, 
  referralHistory, 
  responseStatus,
  storageKeys 
} from '../constants';
import { getDeviceInfo } from '../utils/getDeviceInfo';

const getReferralLinkSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestReferralLink, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const { referralLink, referralCode, message } = response.data.d;
      yield put(setReferralLink(referralLink));
      yield put(setReferralCode(referralCode));
      yield put(setReferralShareMessage(message));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getReferralHistorySaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestReferralHistory, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const history = referralHistory(response.data.d);
      yield put(setReferralHistory(history));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const inviteFriendSaga = function* (payload) {
  const { mobileNumber, message } = payload;
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const data = { mobnum: mobileNumber };
    const response = yield call(requestReferralInvite, data, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield call(getReferralHistorySaga);
      console.debug('Success');
    }
    yield put(setLoading(false));
    yield call(sendMessageToFriendSaga, mobileNumber, message);
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
    yield call(sendMessageToFriendSaga, mobileNumber, message);
  }
}

const sendMessageToFriendSaga = function (mobileNumber, message) {
  const deviceInfo = getDeviceInfo();
  const ios = /iPad|iPhone|iPod/i.test(deviceInfo.os);
  const android = /android/i.test(deviceInfo.os);
  const encodedMessage = encodeURIComponent(message);

  let url = null;
  if (ios) url = `sms://${mobileNumber}&body=${encodedMessage}`;
  if (android) url = `sms://${mobileNumber}?body=${encodedMessage}`;

  // open default SMS app (for Android and iOS only)
  if (!isNil(url)) window.location.assign(url);
}

const getReferrerDetailsSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    let query = `referralCode=${payload.referrer}`;
    const response = yield call(requestReferrerDetails, query, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setReferrerDetails(response.data.d));
      yield put(getFlags({ key: flagKeys.isGamePassTermsAndConditionsShown }));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* referralSaga() {
  yield all([
    takeLatest(GET_REFERRAL_LINK, getReferralLinkSaga),
    takeLatest(GET_REFERRAL_HISTORY, getReferralHistorySaga),
    takeLatest(INVITE_FRIEND, inviteFriendSaga),
    takeLatest(GET_REFERRER_DETAILS, getReferrerDetailsSaga)
  ]);
}