import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { getModalNotifications, setHomeTutorial } from '../../../actions';
import { 
  buttonActionText, 
  firebaseEvents, 
  modalNotificationsTags, 
  tutorialText 
} from '../../../constants';
import { homeTutorialStepSelector } from '../../../selectors';
import { logFirebaseEventWithTimestamp } from '../../../utils/logFirebaseEvent';
import './TutorialBox.scss';

const TutorialBox = ({ footer, setTutorial, boxRef }) => {
  const dispatch = useDispatch();
  const { ref: inViewRef, inView } = useInView({ threshold: 0.9 });
  
  const tutorialStep = useSelector(state => homeTutorialStepSelector(state));

  const handleClose = () => {
    localStorage.removeItem('isNew');
    setTutorial(false);
    dispatch(getModalNotifications({ tags: [modalNotificationsTags.rewards] }));
    logFirebaseEventWithTimestamp(firebaseEvents.homeWalkthroughSkip);
  }

  const handleNextStep = () => {
    if (tutorialStep === 8) {
      localStorage.removeItem('isNew');
      setTutorial(false);
      dispatch(getModalNotifications({ tags: [modalNotificationsTags.rewards] }));
      logFirebaseEventWithTimestamp(firebaseEvents.homeWalkthroughEnd);
    } else {
      dispatch(setHomeTutorial({ step: tutorialStep + 1 }));
      if (tutorialStep === 2) logFirebaseEventWithTimestamp(firebaseEvents.homeWalkthroughStart);
    }
  }

  const handlePreviousStep = () => {
    dispatch(setHomeTutorial({ step: tutorialStep - 1 }));
  }

  useEffect(() => {
    if (boxRef && !inView) {
      boxRef.current.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
        alignToTop: false
      });
    }
  }, [inView, boxRef])

  const setRefs = useCallback(() => {
    if (boxRef) inViewRef(boxRef.current);
  }, [inViewRef, boxRef]);

  return (
    <>
      <div 
        ref={setRefs} 
        className={classNames({
          "tutorial-box": !footer,
          "tutorial-box-small": tutorialStep === 2,
          "tutorial-box-right": (tutorialStep === 5 || tutorialStep === 8),
          "tutorial-box-footer": footer
        })}
      >
        <div className="tutorial-box-container">
          <CloseRoundedIcon onClick={handleClose} className="tutorial-box-close" />
          <div className="tutorial-box-content">
            <img 
              src={tutorialText[tutorialStep - 1].image} 
              alt={tutorialText[tutorialStep - 1].title} 
              className={classNames(
                "tutorial-box-step-image",
                {"tutorial-box-step-image-right": tutorialStep === 2}
              )}
            />
            <div className={classNames(
              "tutorial-box-content-details",
              {"tutorial-box-content-details-right": tutorialStep === 2},
              {"tutorial-box-content-details-gap": tutorialStep === tutorialText.length}
            )}>
              <div className="tutorial-box-content-details-step">{tutorialStep - 1} of 7</div>
              <div className="tutorial-box-content-details-title">{tutorialText[tutorialStep - 1].title}</div>
              <div className="tutorial-box-content-details-desc">{tutorialText[tutorialStep - 1].desc}</div>
              <div className={classNames(
                "tutorial-box-content-details-button", 
                {"tutorial-box-content-details-button-translate": tutorialStep > 2}
              )}>
                {tutorialStep > 2 && <div 
                  onClick={handlePreviousStep}
                  className="d-flex align-items-center"
                >
                  <KeyboardArrowLeftRoundedIcon className="tutorial-box-content-details-button-arrow"/>
                  <div className="tutorial-box-content-details-button-text">{buttonActionText.previous}</div>
                </div>}
                <div 
                  onClick={handleNextStep}
                  className={classNames(
                    "d-flex align-items-center",
                    {"tutorial-box-content-details-button-text-translate": tutorialStep > 2}
                  )}
                >
                  <div className="tutorial-box-content-details-button-text">
                    {tutorialStep === 8 ? buttonActionText.finish : buttonActionText.next}
                  </div>
                  <KeyboardArrowRightRoundedIcon className="tutorial-box-content-details-button-arrow"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default TutorialBox;