import classNames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { setIsBuy } from '../../../actions';
import { minWidths } from '../../../constants';
import './DesktopModal.scss';

export const DesktopModal = ({ 
  children, 
  open, 
  handleOpen, 
  isOwned, 
  isList, 
  isBuy, 
  cancelList
}) => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const isBuyContent = !isList && !isOwned && !cancelList;
  const handleNavigate = () => {
    if (isBuy) dispatch(setIsBuy(false));
    handleOpen(false)
  }

  return (
    <Modal
      backdrop
      show={open}
      className={classNames(
        "desktop-modal-container", 
        {"desktop-modal-container-full": isOwned && !isList},
        {"desktop-modal-container-front": isList || isBuy}
      )}
      contentClassName={classNames(
        "desktop-modal",
        {"desktop-modal-half": !isDesktop && (cancelList || isBuyContent)}, 
        {"desktop-modal-mobile": (isOwned && !isList) || isBuy}, 
        {"desktop-modal-mobile-list": !isDesktop && isList}
      )}
      dialogClassName={classNames(
        "desktop-modal-dialog",
        {"desktop-modal-dialog-half": (cancelList || isBuyContent)}
      )}
      backdropClassName="desktop-modal-bg"
      centered
      onHide={handleNavigate}
    >
      <Modal.Body>
        {!isDesktop && 
          !isList && 
          (cancelList || isBuyContent) && 
          <div className="desktop-modal-indicator" />}
        {children}
      </Modal.Body>
    </Modal>
  )
}
