import axios from 'axios';
import { baseUrl } from './url';

export function requestGamePassCheckout(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/cart/checkout')
  });
}

export function requestGamePassCheckoutUpdate(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/cart/checkout')
  });
}

export function requestGamePassCheckoutSelectCurrency(params, sessionToken) {
  return axios.request({
    method: 'GET',
    params: { ...params },
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/cart/checkout/select-crypto')
  });
}

export function requestGamePassCheckoutSelectedCurrency(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/cart/checkout/select-crypto')
  });
}

export function requestGamePassCheckoutPay(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/cart/checkout/pay')
  });
}

export function requestGamePassCheckoutPayInstant(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/cart/checkout/payInstant')
  });
}

export function requestGamePassTokenizedPayments(params, sessionToken) {
  return axios.request({
    method: 'GET',
    params: { ...params },
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/tokenized-payments')
  });
}

export function requestGamePassAddTokenizedPayments(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/tokenized-payments')
  });
}

export function requestGamePassDeleteTokenizedPayments(body, sessionToken) {
  return axios.request({
    method: 'DELETE',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/tokenized-payments')
  });
}

export function requestOnboardingGamePassCheckout(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('anonymous/cart/checkout/onboarding')
  });
}

export function requestUpdateOnboardingGamePassCheckout(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('anonymous/cart/checkout/onboarding')
  });
}