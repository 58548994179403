import { connect } from 'react-redux';
import { 
  getUserProfile,
  getUserCollections, 
  getUserTransactions, 
  setBarcodeVisible, 
  setTopUpVisible, 
  getGamePassInventory, 
  activateGamePass,
  getGamePassFlag,
  setGamePassFlag,
  getGamePassActiveEntitlements,
  connectFromTopUp
} from '../actions';
import {
  userCollectionListSelector,
  walletCryptoSelector,
  sessionExpiredSelector,
  pageContentsRenderedSelector,
  userTransactionsSelector,
  transactionOpenTopUpSelector,
  gamePassInventorySelector,
  gamePassInventoryActivateSelector,
  isGamePassTermsAndConditionsShownSelector,
  entitlementsSelector,
  betaUserSelector
} from '../selectors';
import { Wallet } from '../components';

const mapStateToProps = (state) => ({
  collections: userCollectionListSelector(state),
  crypto: walletCryptoSelector(state),
  pageContentsRendered: pageContentsRenderedSelector(state),
  transactions: userTransactionsSelector(state),
  isTopUpVisible: transactionOpenTopUpSelector(state),
  gamePassInventory: gamePassInventorySelector(state),
  gamePassActivateDetails: gamePassInventoryActivateSelector(state),
  isGamePassTermsPopupShown: isGamePassTermsAndConditionsShownSelector(state),
  entitlements: entitlementsSelector(state),
  betaUser: betaUserSelector(state),
  sessionExpiredSelector
});

const mapDispatchToProps = (dispatch) => ({
  getUserProfile: () => dispatch(getUserProfile()),
  getUserCollections: (category) => dispatch(getUserCollections(category)),
  getUserTransactions: () => dispatch(getUserTransactions()),
  setBarcodeVisible: (isBarcodeVisible) => dispatch(setBarcodeVisible(isBarcodeVisible)),
  setTopUpVisible: (isTopUpVisible) => dispatch(setTopUpVisible(isTopUpVisible)),
  getGamePassInventory: (redirectIfUnauthorized) => dispatch(getGamePassInventory(redirectIfUnauthorized)),
  getGamePassActiveEntitlements: () => dispatch(getGamePassActiveEntitlements()),
  activateGamePass: (itemData) => dispatch(activateGamePass(itemData)),
  getGamePassFlag: (flagName) => dispatch(getGamePassFlag(flagName)),
  setGamePassFlag: (flagName, isShown) => dispatch(setGamePassFlag(flagName, isShown)),
  connectFromTopUp: (isFromTopUp) => dispatch(connectFromTopUp(isFromTopUp))
});

const WalletContainer = connect(mapStateToProps, mapDispatchToProps)(Wallet);

export default WalletContainer;