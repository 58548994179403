import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Tabs, { Tab } from 'react-best-tabs';
import { useMediaQuery } from 'react-responsive';
import { CircularProgress } from '@mui/material';
import { Barcode, TransactionHistory } from '../Components';
import { 
  getUserTransactions, 
  setBarcodeVisible, 
  setSelectedMethod, 
  setTransactionVisible, 
  setClearUserTransactions, 
  setUserTransactionsSize, 
  setTransactionActiveTab
} from '../../actions';
import { 
  transactionBarcodeSelector, 
  transactionSelectedMethodSelector, 
  transactionSizeSelector, 
  transactionActiveTabSelector, 
  transactionVisibleSelector 
} from '../../selectors';
import { 
  mgcCurrency,
  minWidths,
  offsetIncrementValue, 
  transactionsTab, 
} from '../../constants';
import { getTransactionAssets } from '../../utils/getTransactionAssets';
import 'react-best-tabs/dist/index.css';

export const WalletTransactions = ({ transactions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isBarcodeVisible = useSelector(state => transactionBarcodeSelector(state));
  const selectedMethod = useSelector(state => transactionSelectedMethodSelector(state));
  const isTransactionVisible = useSelector(state => transactionVisibleSelector(state));
  const data = useSelector(state => transactionSizeSelector(state));
  const activeTab = useSelector(state => transactionActiveTabSelector(state));
  
  const [assets, setAssets] = useState(getTransactionAssets(transactionsTab, transactionsTab[activeTab - 1]));
  
  useEffect(() => {
    dispatch(getUserTransactions({
      kinds: ["offchain"],
      assets: assets,
    }));
  }, [dispatch, activeTab, assets])

  useEffect(() => {
    dispatch(setClearUserTransactions([]));
    dispatch(setUserTransactionsSize({ loading: false, size: offsetIncrementValue }));
  }, [dispatch])

  const handleMediaQueryChange = (matches) => {
    if (!isTransactionVisible) return;
    if (!matches) return navigate(`/wallet/transactions/${selectedMethod.id}`);
    navigate(`/wallet?id=${selectedMethod.id}`);
    dispatch(setTransactionVisible(true));
  }
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet }, undefined, handleMediaQueryChange);

  const handleTransaction = (method) => {
    if (!isDesktop) return navigate({pathname: `/wallet/transactions/${method.id}`}, {state: location.pathname});
    dispatch(setTransactionVisible(true));
    dispatch(setSelectedMethod(method));
  }
  
  const showBarcode = (e, method) => {
    e.stopPropagation();
    dispatch(setSelectedMethod(method));
    dispatch(setBarcodeVisible(true));
    if (searchParams.has('id')){
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }

  useEffect(() => {
    if (isTransactionVisible && isDesktop && selectedMethod){
      searchParams.set('id', selectedMethod.id);
      setSearchParams(searchParams);
    }
  }, [isTransactionVisible, selectedMethod, isDesktop, setSearchParams, searchParams])

  const handleTab = (event, tab) => {
    dispatch(setClearUserTransactions([]));
    dispatch(setTransactionActiveTab(tab));
    const assetText = event.target.innerText;
    const assetsArr = getTransactionAssets(transactionsTab, assetText);
    setAssets(assetsArr);
  }

  return (
    <Fragment>
      <Tabs
        activeTab={activeTab}
        className="transactions-tabs"
        ulClassName="transactions-tabs-lists"
        activityClassName="transactions-tabs-indicator"
        onClick={(event, tab) => handleTab(event, tab)}
      >
        {transactionsTab.map((t, tIdx) => {
          return (
            <Tab 
              key={`TAB_KEY_${tIdx}`} 
              title={t} 
              className="transactions-center-tab-title"
            >
              {transactions.length > 0 ? 
                <div className="position-relative">
                  <TransactionHistory
                    handleTransaction={handleTransaction}
                    setIsBarcodeVisible={showBarcode}
                    transactions={transactions.slice(0, 3)}
                  />
                </div> : 
                <div>
                  {!data.loading ?
                    <div className="wallet-empty mt-0">
                      You have no transactions yet
                    </div> :
                    <div className="d-flex justify-content-center">
                      <CircularProgress sx={{ color: "#615374" }} />
                    </div>}
                </div>}
            </Tab>
          )
        })}
      </Tabs>
      <Barcode 
        selectedMethod={selectedMethod} 
        isBarcodeVisible={isBarcodeVisible}
        hide={() => dispatch(setBarcodeVisible(false))}
      />
    </Fragment>
  )
};
