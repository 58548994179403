import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getRemoteConfig } from 'firebase/remote-config';
import { firebaseConfig, isDev } from '../constants';

const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);
export const remoteConfig = getRemoteConfig(app);
const minimumFetchIntervalMillis = isDev ? 60000 : 180000;
remoteConfig.settings.minimumFetchIntervalMillis = minimumFetchIntervalMillis;
