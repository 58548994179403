import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { Modal } from 'react-bootstrap';
import { MenuList } from './MenuList';
import { firebaseEvents, remoteConfigKeys } from '../../constants';
import { setRemoteConfigKeys } from '../../actions';
import { remoteConfig } from '../../utils/firebase';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';
import './Menu.scss';

export const Menu = ({ isOpen, openMenu, sideMenuItems, sideMenuOtherItems, ...props }) => {
  const dispatch = useDispatch();
  const [selected, selectMenuItem] = useState('');
  
  useEffect(() => {
    // for testing spin the wheel and games
    if (!isOpen) return;
    fetchAndActivate(remoteConfig)
      .then(() => {
        const strippedStwEnabled = getValue(remoteConfig, remoteConfigKeys.strippedStwEnabled);
        const strippedGameUrls = getValue(remoteConfig, remoteConfigKeys.strippedGameUrls);
        const strippedStwUsers = getValue(remoteConfig, remoteConfigKeys.strippedStwUsers);
        dispatch(
          setRemoteConfigKeys({
            strippedStwEnabled: strippedStwEnabled.asBoolean(),
            strippedGameUrls: JSON.parse(strippedGameUrls?._value),
            strippedStwUsers: JSON.parse(strippedStwUsers?._value)
          })
        );
      })
      .catch((err) => {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: err
          });
      });
  }, [isOpen])

  return (
    <Modal
      backdrop
      show={isOpen}
      className="menu-container"
      contentClassName="menu"
      dialogClassName="menu-dialog"
      backdropClassName="menu-bg"
      onHide={openMenu}
      centered
    >
      <Modal.Body>
        <MenuList
          {...props}
          selected={selected}
          items={sideMenuItems}
          others={sideMenuOtherItems}
          selectMenuItem={selectMenuItem}
          openMenu={openMenu}
        />
      </Modal.Body>
    </Modal>
  );
}