import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { BlurBackgroundModal } from '../Commons';
import { 
  loginTexts, 
  pinError, 
  pinFormInputs,
  storageKeys,
} from '../../constants';
import { 
  getGamePassInventory,
  setLoading,
  showGamePassActivated,
  submitLoginPIN
} from '../../actions';
import {
  loadingSelector,
  setSubmitNewPinSelector
} from '../../selectors';
import './PINModal.scss';

export const PINModal = ({ open, handleBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [invalidInputs, setInvalidInputs] = useState([]);
  const [pinFormData, setPINFormData] = useState({
    pin: '',
    confirm: ''
  });
  const [passwordRevealed, setPasswordRevealed] = useState({
    pin: false,
    confirm: false
  });

  const isSubmit = useSelector(state => setSubmitNewPinSelector(state));
  const loading = useSelector(state => loadingSelector(state));

  useEffect(() => {
    dispatch(showGamePassActivated({ result: false, shouldNotShow: true }));
    dispatch(getGamePassInventory());
  }, [dispatch])

  useEffect(() => {
    if (open) {
      Cookies.set(storageKeys.showSetPIN, true);
    }
  }, [open])

  const handlePINInput = (e, fieldName) => {
    setInvalidInputs([]);
    setPINFormData({
      ...pinFormData,
      [fieldName]: e.target.value
    })
  };

  const submitPIN = (e) => {
    e.preventDefault();
    if (!loading) {
      const { pin, confirm } = pinFormData;
      const pinFormDataValues = pinFormInputs.map((i) => pinFormData[i.name]);
      if (!pinFormDataValues.includes('')) {
        if (pin === confirm) {
          setInvalidInputs([]);
          dispatch(setLoading(true));
          dispatch(submitLoginPIN({ 
            pin, 
            navigate,
            dispatch
          }));
        } else {
          setInvalidInputs(['confirm']);
        }
      } else {
        setInvalidInputs(pinFormInputs.filter(i => pinFormData[i.name] === '').map(i => i.name));
      }
    }
  }

  const revealPassword = (key, revealed) => {
    setPasswordRevealed({
      ...passwordRevealed,
      [key]: revealed
    });
  }

  return (
    <BlurBackgroundModal 
      open={open}
      title={loginTexts.pin.title}
      buttonTitle={loginTexts.button.next}
      hide={handleBack}
    >
      <div className="pin-modal-description">{loginTexts.pin.description}</div>
      <form className="pin-modal-form">
        {pinFormInputs.map(i => (
          <div className="pin-modal-form-row" key={`FormData_${i.name}`}>
            <span className="pin-modal-form-label">{i.label}</span>
            <div className={classNames(
              "pin-modal-form-group",
              {"pin-modal-form-group-error": invalidInputs.includes(i.name)}
            )}>
              <input
                type={passwordRevealed[i.name] ? 'text' : 'password'}
                className="pin-modal-form-input"
                onChange={(e) => handlePINInput(e, i.name)}
                value={pinFormData[i.name]}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }} 
                maxLength={6}
              />
              <div
                onClick={() => revealPassword(i.name, !passwordRevealed[i.name])}
                className="pin-modal-form-password-reveal"
              >
                {passwordRevealed[i.name] ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
              </div>
            </div>
            {invalidInputs.length > 0 && i.hasErrorMessage &&
              <div className="pin-modal-error">{pinError}</div>}
          </div>
        ))}
      </form>
      {/*toast &&
        <div className="pin-modal-error">{toast?.message}</div>*/}
      <div className="pin-modal-buttons">
        <button 
          className={classNames(
            "blur-modal-button",
            "pin-modal-submit",
            {"blur-modal-button-disabled": isSubmit || loading})} 
          onClick={(e) => submitPIN(e)}
        >
          {loginTexts.otp.submit}
        </button>
        <button 
          onClick={handleBack} 
          className="pin-modal-dismiss"
        >
          {loginTexts.pin.dismiss}
        </button>
      </div>
    </BlurBackgroundModal>
  );
}