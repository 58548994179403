export const menuItems = [
  { name: 'Home', route: '/' },
  { name: 'Wallet', route: '/wallet', step: 6 },
  { name: 'Game Pass', route: '/gamepass' },
  { name: 'Inbox', route: '/messages', step: 7 },
  { name: 'Profile', route: '/profile', step: 8 }
];
export const sideMenuItems = [
  // { name: 'Quests', route: '/quests' },
  { name: 'Messages', route: '/messages' },
  { name: 'Spend', route: '/spend' },
  { name: 'Help and Support', route: 'https://help.metaversego.gg/hc/en-us' },
  { name: 'Walkthrough', route: '/walkthrough' }
];
export const sideMenuOtherItems = [
  { name: 'Partner with Us', route: 'https://business.metaversego.gg/', icon: 'partner-icon' },
  { name: 'Terms & Conditions', route: '/terms-and-conditions' },
  { name: 'Privacy Policy', route: '/privacy-policy', },
  { name: 'Fair Play Policy', route: '/fair-play-policy', }
];
export const profileMenuItems = [
  // { name: 'Transactions', route: '/profile/transactions' },
  { name: 'Linked Accounts', route: '#', comingSoon: true },
  // { name: 'Invite Friends', route: '#', comingSoon: true },
  // { name: 'Report', route: '#' },
  { name: 'Security', route: '/profile/security' }
];
export const securityMenuItems = [
  // { name: 'Security', route: '#' },
  { name: 'Reset PIN', route: '/profile/security/reset-pin' }
];
export const homeMenuItemRoutes = {
  gamePass: '/gamepass',
  gameCenter: '/game-center',
  launchpad: '/launchpad',
  community: '/community'
};
export const homeMenuItems = [
  {
    name: 'Win Prizes',
    route: homeMenuItemRoutes.gamePass,
    dot: true,
    step: 2
  },
  {
    name: 'Games',
    route: homeMenuItemRoutes.gameCenter,
    step: 3
  },
  {
    name: 'NFTs',
    route: homeMenuItemRoutes.launchpad,
    step: 4
  },
  {
    name: 'Guilds',
    route: homeMenuItemRoutes.community,
    step: 5
  }
];