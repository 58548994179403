import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

export const CollectiblesSection = ({ item }) => {
  const navigate = useNavigate();
  const [openAboutSection, setOpenAboutSection] = useState(false);
  const [openPropertiesSection, setOpenPropertiesSection] = useState(false);

  return (
    <>
      <div className="collectibles-details-section-container">
        <div 
          onClick={() => setOpenAboutSection(!openAboutSection)}
          className="d-flex justify-content-between align-items-center w-100" 
          role="button">
          <div className="d-flex justify-content-center align-items-center gap-1">
            <div className="collectibles-details-section-about"/>
            <div className="collectibles-details-section-title">About the Collection</div>
          </div>
          {openAboutSection ? <KeyboardArrowUpRoundedIcon/> : <KeyboardArrowDownRoundedIcon/>}
        </div>
        <div className={classNames(
          "collectibles-details-section-content", 
          {"collectibles-details-section-content-hide": !openAboutSection})}>
          <div className="collectibles-details-section-desc" dangerouslySetInnerHTML={{__html: item.description}}/>
          <div 
            onClick={() => navigate(`/launchpad/${item.slug}`)}
            className="d-flex justify-content-center align-items-center gap-1 mt-2 collectibles-details-section-view-wrapper">
            <div className="collectibles-details-section-view">View collection</div>
            <ArrowForwardIosRoundedIcon className="collectibles-details-section-view-icon"/>
          </div>
        </div>
      </div>
      {
        !isEmpty(item.stats) &&
        <div className={classNames(
          "collectibles-details-section-container-properties", {
          "collectibles-details-section-container-properties-hide": openPropertiesSection
        })}>
          <div 
            onClick={() => setOpenPropertiesSection(!openPropertiesSection)}
            className="d-flex justify-content-between align-items-center w-100" 
            role="button">
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="collectibles-details-section-properties"/>
              <div className="collectibles-details-section-title">Properties</div>
            </div>
            {openPropertiesSection ? <KeyboardArrowUpRoundedIcon/> : <KeyboardArrowDownRoundedIcon/>}
          </div>
          <div 
            className={classNames(
            "collectibles-details-section-stats", {
            "collectibles-details-section-stats-hide": !openPropertiesSection
          })}>
            {item.stats.map((s, sIdx) => (
              <div key={`Stats_${sIdx}`} className="collectibles-details-section-stats-wrapper">
              <div className="collectibles-details-section-stats-type">{s.label && s.label}</div>
              <div className="collectibles-details-section-stats-value">{s.value}</div>
              </div>
            ))}
          </div>
        </div>
      }
    </>
  )
}