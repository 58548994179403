import { all, call, put, select, takeLatest } from "redux-saga/effects";
import jwtDecode from "jwt-decode";
import { getAnalytics, setUserId } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import Cookies from "js-cookie";
import { 
  authSteps,
  errorCodes, 
  firebaseEvents, 
  siteRoutes, 
  storageKeys 
} from "../constants";
import { logFirebaseEvent } from "../utils/logFirebaseEvent";
import { SET_FAILED_REQUESTS, SET_UNAUTHORIZED_REDIRECT } from "../actions/constants";
import { 
  getUserProfile,
  navigateTo,
  resetState,
  setAuthStep, 
  setDisplayToast,
  setGuest,
  setLoading,
  setSessionExpired, 
} from "../actions";
import { sessionExpiredSelector } from "../selectors";

const signOutAsync = (auth) => {
  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}

const failedRequestsSaga = function* ({ payload }) {
  const { 
    status, 
    message, 
    code, 
    url, 
    showErrorToast, 
    isExemptedFromRedirection, 
    description, 
    redirectUri, 
    disabled
  } = payload || {};

  const sessionToken = localStorage.getItem(storageKeys.sessionToken);
  const isUnauthorized = yield select(sessionExpiredSelector);

  let decodedSessionToken = {};
  if (sessionToken) {
    decodedSessionToken = jwtDecode(sessionToken);
  }

  if (isUnauthorized) {
    yield put(setGuest(true));
  }
  
  if (code === errorCodes.unauthorized && 
    (!isExemptedFromRedirection || isUnauthorized)) {
    if (decodedSessionToken?.claims?.hasPIN) {
      if (!window.location.href.includes(siteRoutes.pin)) {
        localStorage.setItem(storageKeys.previousRoute, window.location.href);
        yield put(navigateTo({ path: siteRoutes.pin }));
      }
      yield put(setAuthStep());
    } else {
      const auth = getAuth();
      const analytics = getAnalytics();
    
      if (localStorage.getItem(storageKeys.sessionToken)) localStorage.removeItem(storageKeys.sessionToken);
      if (localStorage.getItem(storageKeys.username)) localStorage.removeItem(storageKeys.username);
      if (localStorage.getItem(storageKeys.mobileNumber)) localStorage.removeItem(storageKeys.mobileNumber);
      if (localStorage.getItem(storageKeys.previousRoute)) localStorage.removeItem(storageKeys.previousRoute);
      if (Cookies.remove(storageKeys.dynamicSlug)) Cookies.remove(storageKeys.dynamicSlug);
      if (Cookies.remove(storageKeys.slug)) Cookies.remove(storageKeys.slug);
      if (Cookies.get(storageKeys.pmSource)) Cookies.remove(storageKeys.pmSource);
      if (sessionStorage.getItem(storageKeys.pmSource)) sessionStorage.removeItem(storageKeys.pmSource);
      
      yield put(setSessionExpired(false));
      setUserId(analytics, null);

      try {
        yield call(signOutAsync, auth);
        yield put(resetState());
        yield put(getUserProfile());
        yield put(setGuest(true));
        yield put(navigateTo({ path: '/' }));
        yield put(setAuthStep(authSteps.login));
      } catch(err) {
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: err
          });
      }
    }
  }
  
  logFirebaseEvent(firebaseEvents.failedEvent, {
    status_code: status,
    error_message: message,
    request_url: url
  });

  // error toast
  if (showErrorToast && 
    code !== errorCodes.unauthorized) {
    const toast = {
      result: false,
      title: code,
      message,
      description,
      redirectUri,
      disabled
    };
    yield put(setDisplayToast(toast));
  }

  yield put(setLoading(false));
}

const unauthorizedRedirectSaga = function* ({ payload }) {
  const { isGuest, authSteps, route, navigateData } = payload || null;
  if (isGuest) {
    return yield put(setAuthStep(authSteps));
  }

  if (route) {
    yield put(
      navigateTo({ 
        path: route, 
        navigateData
      })
    );
    return 
  }
}

export default function* globalSaga() {
  yield all([
    takeLatest(SET_FAILED_REQUESTS, failedRequestsSaga),
    takeLatest(SET_UNAUTHORIZED_REDIRECT, unauthorizedRedirectSaga)
  ]);
}