import React from 'react';
import { Link } from 'react-router-dom';
import wenLamboIcon from '../../assets/wen-lambo.png';
import './Footer.scss';

export const CustomFooterItem = ({ route }) => (
  <div className="footer-menu-item">
    <Link to={route} className="footer-menu-item-custom-link">
      <img src={wenLamboIcon} className="footer-menu-item-custom-link-icon" />
    </Link>
  </div>
);