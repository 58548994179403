import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useCountdown } from '../../../hooks/useCountdown';
import ActiveGamePass from '../ActiveGamePass/ActiveGamePass';
import CompactActiveGamePass from '../CompactActiveGamePass/CompactActiveGamePass';
import EmptyGamePass from '../EmptyGamePass/EmptyGamePass';

const GamePassInventoryTimer = ({ gamePasses, emptyImageUrl, title, handleGamePassClick, compact, isTutorial }) => {
  const formatTimeVal = (time) => {
    return time > 9 ? time : `0${time}`;
  }

  const handleCountdownVal = ([days, hours, minutes, seconds]) => {
    const daysVal = days > 0 ? `${days}d ` : '';
    const hoursVal = formatTimeVal(hours);
    const minutesVal = formatTimeVal(minutes);
    const secondsVal = formatTimeVal(seconds);
    const countdownVal = `${daysVal}${hoursVal}:${minutesVal}:${secondsVal}`;
    return countdownVal;
  }

  const handleImageUrls = (passes) => {
    let imageUrls = [];
    if (!isEmpty(passes)) {
      imageUrls = passes.slice(-3).map(gp => gp.data.imageUrl);
    }
    return imageUrls;
  }

  const remainingGamePasses = gamePasses.slice(1, gamePasses.length);

  const endDate = gamePasses[gamePasses.length - 1].validityEndDate;
  const countdown = useCountdown(endDate);
  const countdownVal = handleCountdownVal(countdown);
  const accumulatedCountdown = countdown.reduce((a, b) => a + b, 0);

  const firstEndDate = gamePasses[0].validityEndDate;
  const firstCountdown = useCountdown(firstEndDate);
  const firstCountdownTotal = firstCountdown.reduce((a, b) => a + b, 0);

  let remainingEndDate = '';
  if (remainingGamePasses.length > 0) {
    remainingEndDate = remainingGamePasses[remainingGamePasses.length - 1].validityEndDate;
  }

  let imageUrls = handleImageUrls(gamePasses);
  let validityEndDate = endDate;
  if (firstCountdownTotal <= 0) {
    imageUrls = handleImageUrls(remainingGamePasses);
    validityEndDate = remainingEndDate;
  }
  
  if (accumulatedCountdown <= 0) {
    return (
      <EmptyGamePass
        imgUrl={emptyImageUrl}
        title={title}
        compact={compact}
      />
    );
  } else {
    if (compact) {
      return (
        <CompactActiveGamePass
          imageUrls={imageUrls}
          title={title}
          countdownVal={countdownVal}
        />
      );
    } else {
      return (
        <ActiveGamePass
          imageUrls={imageUrls}
          title={title}
          validityEndDate={validityEndDate}
          countdownVal={countdownVal}
          handleGamePassClick={handleGamePassClick}
        />
      );
    }
  }
};

export default GamePassInventoryTimer;