import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import { GET_FLAGS, UPDATE_FLAG } from '../actions/constants';
import {
  setLoading,
  setFlags,
  setUpdateFlag,
  setAuthStep,
  getUserProfile,
  failedRequests,
  navigateTo,
  setClaimFreeGamePass,
  getGamePassInventory,
  updateFlag
} from '../actions';
import {
  requestFlags,
  requestUpdateFlag
} from '../api/flags';
import { 
  authSteps, 
  flagKeys, 
  gamePassCTA, 
  onBoardingData, 
  responseStatus, 
  storageKeys 
} from '../constants';
import { authStepSelector, gamePassInventorySelector } from '../selectors';

const getFlagsSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const tokenData = jwtDecode(sessionToken);
    const slug = Cookies.get(storageKeys.slug);
    const dynamicSlug = Cookies.get(storageKeys.dynamicSlug);
    const inventory = yield select(gamePassInventorySelector);
    const hasGamePass = inventory?.find(i => {
      const isSelectedSkuIncluded = i?.subKind.includes(onBoardingData.selectedSku);
      if (window.location.pathname.includes('launchpad') &&
        (!isEmpty(slug) || !isEmpty(dynamicSlug))) {
        return isSelectedSkuIncluded && i.expiresAt;
      } else {
        return isSelectedSkuIncluded;
      }
    });
    const authStep = yield select(authStepSelector);
    const response = yield call(requestFlags, payload.payload.key, 'v1', sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setFlags(response.data.d));
      if (!response.data.d?.value) {
        if (payload.payload?.isCheckout) {
          Cookies.set(storageKeys.isLoggedInTutorial, true);
          Cookies.set(storageKeys.isNewUser, true);
          const flagsData = {
            key: flagKeys.isGamePassTermsAndConditionsShown,
            body: {
              value: true
            },
            shouldNotDoAction: true
          };
          yield put(updateFlag(flagsData));
          yield put(setAuthStep(authSteps.default));
        } else if (payload.payload?.isLogin) {
          if (payload.payload.key === storageKeys.isGamePassTermsAndConditionsShown) {
            Cookies.set(storageKeys.isGamePassTermsAndConditionsShown, true);
          }
          yield put(setAuthStep(authSteps.termsAndConditions));
        } else {
          if (authStep !== authSteps.pin && hasGamePass) {
            yield put(setClaimFreeGamePass(true));
          }
        }
      } else {
        if (payload.payload.key === storageKeys.isGamePassTermsAndConditionsShown) {
          yield put(setAuthStep(authSteps.default));
          if (!tokenData?.claims?.hasPIN) {
            yield put(setAuthStep(authSteps.pin));
            Cookies.set(storageKeys.showSetPIN, true);
          }
          if (payload.payload.route) {
            yield put(navigateTo({ path: payload.payload.route }));
          }
        }
        if (payload.payload.key === storageKeys.isFreeGamePassModalShown) {
          Cookies.remove(storageKeys.isFreeGamePassModalShown);
        }
        if (!tokenData?.claims?.hasPIN) {
          yield put(setAuthStep(authSteps.pin));
          Cookies.set(storageKeys.showSetPIN, true);
        }
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const updateFlagSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const tokenData = jwtDecode(sessionToken);
    const slug = Cookies.get(storageKeys.slug);
    const dynamicSlug = Cookies.get(storageKeys.dynamicSlug);
    const response = yield call(requestUpdateFlag, payload.payload.key, payload.payload.body, 'v1', sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      if (!payload.payload.shouldNotDoAction) {
        yield put(setUpdateFlag(response.data.d));
        yield put(setAuthStep());
        yield put(getUserProfile());
        if (payload.payload.key === storageKeys.isGamePassTermsAndConditionsShown) {
          Cookies.remove(storageKeys.isGamePassTermsAndConditionsShown);
        } else if (payload.payload.key === storageKeys.isFreeGamePassModalShown) {
          Cookies.remove(storageKeys.isFreeGamePassModalShown);
          if (!tokenData?.claims?.hasPIN) {
            yield put(setAuthStep(authSteps.pin));
            Cookies.set(storageKeys.showSetPIN, true);
          }
        }
        if (payload.payload.key === storageKeys.isGamePassTermsAndConditionsShown && 
          window.location.pathname.includes('launchpad') && 
          (!isEmpty(slug) || !isEmpty(dynamicSlug))) {
          yield put(setAuthStep());
          if (!tokenData?.claims?.hasPIN) {
            yield put(setAuthStep(authSteps.pin));
            yield put(getGamePassInventory());
          }
        } else {
          if (payload.payload?.isNewUser) {
            yield put(navigateTo({ 
              path: `${gamePassCTA}?onboard=${onBoardingData.flags.modal}`, 
              state: { isNewUser: payload.payload?.isNewUser 
            }}))
          }
        }
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* flagsSaga() {
  yield all([
    takeLatest(GET_FLAGS, getFlagsSaga),
    takeLatest(UPDATE_FLAG, updateFlagSaga),
  ]);
}