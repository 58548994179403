export const failedStatus = {
  title: 'Retry Payment',
  subTitle: 'There has been an issue with the payment provider. Please choose another payment method or try again in a few minutes.',
  buttonText: 'Retry Payment'
};
export const successStatus = {
  title: 'Payment Processing',
  subTitle: 'There has been an issue with the payment provider. Please choose another payment method or try again in a few minutes.',
  buttonText: 'View Balance',
  description: 'We are processing your top-up request. You will receive an in-app notification on the status of your payment. Your MGC balance will be updated if top-up is successful.',
  orderDescription: 'We are processing your order. You will receive an in-app notification on the status of your transaction. You will receive your Game Pass or booster if transaction is successful.'
};
export const completedStatus = {
  title: 'Payment Successful',
  description: 'MGC has been added to your wallet',
  buttonText: 'View Balance'
};
export const cancelStatus = {
  title: 'Payment Cancelled',
  subTitle: 'You have cancelled the payment request.',
  buttonText: 'Back to Wallet'
};
export const transactionIdText = 'Transaction ID:';
export const orderProcessing = 'Order Processing';
export const orderCompleted = 'Order Completed!';
export const paymentFailed = 'Payment Failed';
export const primaryType = {
  topup: 'topup'
};
export const paymentKind = {
  card: 'CARD'
};
export const billingType = {
  billing_country: 'billing_country',
  billing_phone: 'billing_phone',
  billing_zip: 'billing_zip',
  billing_email: 'billing_email'
};
export const welcomeGamePassBundleSkus = ['game-pass-30M', 'gold-boost-10x-1H'];