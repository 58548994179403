import React from 'react';
import { DateTime } from 'luxon';
import { ActiveGamePassTickets } from './ActiveGamePassTickets';
import { dateTimeFormats } from '../../../constants';
import './ActiveGamePass.scss';

const ActiveGamePass = ({ imageUrls, title, validityEndDate, countdownVal, handleGamePassClick }) => {
  const date = DateTime.fromISO(validityEndDate);
  const fmtDateTime = date.toFormat(dateTimeFormats.gamePassExpiryDate);
  return (
    <div className="active-game-pass">
      <div className="active-game-pass-inner">
        <ActiveGamePassTickets
          imageUrls={imageUrls}
          title={title}
          onClick={handleGamePassClick}
        />
        <div className="active-game-pass-timer">
          <div className="active-game-pass-timer-title">{title} Timer</div>
          <div className="active-game-pass-timer-duration">{countdownVal}</div>
          <div className="active-game-pass-timer-expiry-date">
            Expires on <b>{fmtDateTime}</b>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveGamePass;