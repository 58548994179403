import { takeLatest, call, put, all } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { 
  requestLaunchpadCollection, 
  requestLaunchpadCollectionItem, 
  requestMintNft,
  requestPrizeSlug, 
  requestQRMintNft, 
  requestUnliMintNft,
  requestUnliMintSlug
} from '../api/launchpad';
import { 
  GET_DYNAMIC_NFT_SLUG,
  GET_LAUNCHPAD_COLLECTION, 
  GET_LAUNCHPAD_COLLECTION_ITEM, 
  GET_PRIZE_SLUG, 
  MINT_NFT_LAUNCHPAD_ITEM 
} from '../actions/constants';
import {
  setLoading,
  setLaunchpadCollection,
  redeemNFT,
  setLaunchpadCollectionItem,
  getNftData,
  setPrizeSlugData,
  setDynamicSlug,
  failedRequests
} from '../actions';
import { 
  errorCodes, 
  firebaseEvents, 
  mgcCurrency,
  sectionType,
  storageKeys
} from '../constants';
import { logFirebaseEvent } from '../utils/logFirebaseEvent';

const getLaunchpadCollectionSaga = function* () {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestLaunchpadCollection, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setLaunchpadCollection(response.data));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
  }
}

const getLaunchpadCollectionItemSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestLaunchpadCollectionItem, payload.id, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setLaunchpadCollectionItem(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
  }
}

const mintNFTLaunchpadItemSaga = function* (payload) {
  const { voucherCode, slug, mintType, itemDetails } = payload.payload;
  const originPath = window.location.origin;
  const code = { 
    code: voucherCode
  };
  const qrSlug = Cookies.get(storageKeys.slug);
  const dynamicSlug = Cookies.get(storageKeys.dynamicSlug);
  const source = Cookies.get(storageKeys.source);
  yield put(redeemNFT(true));
  const handleLogEvent = (nftSlug, url, is_success_redemption) => {
    logFirebaseEvent(
      firebaseEvents.qrRedeem, {
        slug: nftSlug, 
        qr_code_url: url,
        is_success_redemption
      });
  }

  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    let mintNftRequest = requestMintNft;
    if (mintType === sectionType.qrMint) {
      mintNftRequest = requestQRMintNft;
    } else if (mintType === sectionType.unliMint) {
      mintNftRequest = requestUnliMintNft;
    }
    const response = yield call(mintNftRequest, code, slug, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(getNftData(response.data.d));
      yield put(redeemNFT(false))
      yield put(setLoading(false));
      if (dynamicSlug && (source === slug)) {
        yield put(setDynamicSlug(false));
        const url = `${originPath}/qr-redeem/${dynamicSlug}?hasErrors=false&source=${slug}`;
        handleLogEvent(dynamicSlug, url, true);
        Cookies.remove(storageKeys.dynamicSlug);
        Cookies.remove(storageKeys.source);
      } else if (slug.includes('astro')) {
        const url = `${originPath}/qr-redeem/${qrSlug}`;
        handleLogEvent(qrSlug, url, true);
        Cookies.remove(storageKeys.slug);
      } else {
        logFirebaseEvent(
          firebaseEvents.spendVirtualCurrency, {
            nft_id: itemDetails.id, 
            item_name: itemDetails.name, 
            value: voucherCode, 
            item_type: "NFT", 
            virtual_currency_name: mgcCurrency
          });
      }
    }
  } catch (e) {
    yield put(redeemNFT(false))
    yield put(setLoading(false));
    yield put(getNftData({}));
    if (dynamicSlug && (source === slug)) {
      yield put(setDynamicSlug(false));
      const url = `${originPath}/qr-redeem/${dynamicSlug}?hasErrors=false&source=${slug}`;
      logFirebaseEvent(
        firebaseEvents.qrRedeem, {
          slug: dynamicSlug, 
          qr_code_url: url,
          redemption_failed_message: e.response.data.message
        }
      );
    } else if (slug.includes('astro')) {
      const url = `${originPath}/qr-redeem/${qrSlug}`;
      Cookies.remove(storageKeys.slug);
      logFirebaseEvent(
        firebaseEvents.qrRedeem, {
          slug: qrSlug, 
          qr_code_url: url,
          redemption_failed_message: e.response.data.message
        });
    }
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getPrizeSlugSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestPrizeSlug, payload.payload, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setPrizeSlugData(response.data.d));
      if (!response.data.d.canMint) Cookies.remove(storageKeys.slug);
    }
    yield put(setLoading(false));
  } catch (e) {
    if (e.response && e.response.data) {
      yield put(setLoading(false));
      if (e.response.data.code !== errorCodes.unauthorized) {
        const failedData = {
          status: e.response.status,
          message: e.response.data.description || e.response.data.message,
          code: e.response.data.code,
          url: e.response.config.url,
          showErrorToast: true
        }
        yield put(failedRequests(failedData));
        Cookies.remove(storageKeys.slug)
      }
    }
  }
}

const getDynamicSlugSaga = function* (payload) {
  yield put(setLoading(true));
  const { slug, source } = payload.payload;
  const sourceParams = `?source=${source}`;
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUnliMintSlug, slug, sourceParams, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      yield put(setDynamicSlug(response.data.result));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      if (e.response.data.code !== errorCodes.unauthorized) {
        Cookies.remove(storageKeys.dynamicSlug);
        Cookies.remove(storageKeys.source);
        const failedData = {
          status: e.response.status,
          message: e.response.data.description || e.response.data.message,
          code: e.response.data.code,
          url: e.response.config.url,
          showErrorToast: true
        }
        yield put(failedRequests(failedData));
      }
    }
  }
}

export default function* launchpadSaga() {
  yield all([
    takeLatest(GET_LAUNCHPAD_COLLECTION, getLaunchpadCollectionSaga),
    takeLatest(MINT_NFT_LAUNCHPAD_ITEM, mintNFTLaunchpadItemSaga),
    takeLatest(GET_LAUNCHPAD_COLLECTION_ITEM, getLaunchpadCollectionItemSaga),
    takeLatest(GET_PRIZE_SLUG, getPrizeSlugSaga),
    takeLatest(GET_DYNAMIC_NFT_SLUG, getDynamicSlugSaga)
  ]);
}