import axios from 'axios';
import { baseUrl } from './url';

export function requestGamePassProducts(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('products')
  });
}

export function requestGamePassProductDetails(productId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`products/${productId}`)
  });
}

export function requestGamePassSkuDetails(productId, skuId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`products/${productId}/${skuId}`)
  });
}

export function requestBuyGamePass(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('products/buy')
  });
}

export function requestGamePassInventory(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/entitlements/inventory')
  });
}

export function requestActivateGamePass(body, kind, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl(`me/entitlements/${kind}/activate`)
  });
}

export function requestGamePassTournaments(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('tournaments')
  });
}

export function requestGamePassTournamentsDetails(tournamentId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`tournaments/${tournamentId}`)
  });
}

export function requestGamePassTournamentToken(tournamentId, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`tournaments/${tournamentId}/join`)
  });
}

export function requestRetryGamePassTournamentToken(token, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: { sessionToken },
    url: baseUrl(`tournaments/${token}/retry`)
  });
}

export function requestGamePassFlag(flagName, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/flags/${flagName}`)
  });
}

export function requestSetGamePassFlag(flagName, body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl(`me/flags/${flagName}`)
  });
}

export function requestGamePassActiveEntitlements(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/entitlements/active')
  });
}

export function requestJoinBeta(marketingId, body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl(`beta/${marketingId}/join`)
  });
}

export function requestBetaApplicationForm(formType, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/application?form=${formType}`)
  });
}

export function requestBetaApplicationFormSubmit(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/application')
  });
}