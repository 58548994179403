import React, { useEffect } from 'react';
import { 
  useDispatch, 
  useSelector 
} from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import ReactCountryFlag from 'react-country-flag';
import { getWalletCredits } from '../../../utils/getWalletCredits';
import { 
  collectiblesTexts,
  firebaseEvents,
  mgcCurrency,
  minWidths,
  phpCurrency,
  regexConstants,
  topUpChannels,
  topUpDirection
} from '../../../constants';
import { getCurrencyConversion } from '../../../actions';
import { walletCurrencyConversionSelector } from '../../../selectors';
import { logFirebaseEventWithTimestamp } from '../../../utils/logFirebaseEvent';
import './WalletCredits.scss';

const WalletCredits = ({ crypto, handleShowTopUp }) => {
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const dispatch = useDispatch();
  const currency = useSelector(state => walletCurrencyConversionSelector(state));

  const creditsObject = getWalletCredits(crypto);
  let credits = '';
  if (creditsObject) {
    const { name, val } = creditsObject;
    credits = `${val} ${name}`;
  }

  useEffect(() => {
    const direction = topUpDirection.to;
    const fromCurrency = phpCurrency;
    const toCurrency = mgcCurrency;
    const value = creditsObject?.val?.replace(regexConstants.comma, '');
    const channel = topUpChannels.shopeePay;
    if (!value) return;
    dispatch(getCurrencyConversion({
      fromCurrency, 
      toCurrency, 
      direction, 
      value, 
      channel,
      isWallet: true
    }));
  }, [dispatch]);

  let phValue = 0;
  if (currency.converted) {
    phValue = currency.converted.value.toString().replace(regexConstants.numberComma, ',');
  }

  const handleWithdrawClick = () => {
    logFirebaseEventWithTimestamp(firebaseEvents.walletWithdraw);
  }

  const handleValue = (value) => {
    return `≈ ${value} ${phpCurrency}`;
  }

  return (
    <>
      {!isEmpty(creditsObject) &&
        <div>
          <h1 className="wallet-credits-title">Your Wallet</h1>
          <div className="wallet-credits-wrapper">
            <div className="wallet-credits-inner">
              <div className="wallet-credits-credits">
                <div className="wallet-credits-subtitle">MetaverseGo Credits</div>
                <div className="wallet-credits-text">
                  <div className="wallet-credits-icon" />
                  <div className="wallet-credits-amount">{credits}</div>
                </div>
                <div className="wallet-credits-text-value">
                  <ReactCountryFlag countryCode="PH" className="wallet-credits-country" svg />
                  <div className="wallet-credits-amount-value">{handleValue(phValue)}</div>
                </div>
              </div>
              <div className="wallet-credits-topup">
                <Link
                  to={isDesktop ? '/wallet' : '/topup'}
                  onClick={() => handleShowTopUp(true)}
                  className="wallet-credits-topup-button"
                >
                  Top up
                </Link>
                <Link
                  to="/withdrawal"
                  className="wallet-credits-topup-button"
                  onClick={handleWithdrawClick}
                >
                  <div className="wallet-credits-topup-button-coming-soon">Soon</div>
                  {collectiblesTexts.withdraw}
                </Link>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
}

export default WalletCredits;