

export const gudiData = [
  {
    title: "Eligibility",
    content: [
      "To be eligible to redeem your Gudi NFT, you must be a Globe or TM prepaid and postpaid subscriber with at least 50 reward points to redeem your voucher code."
    ]
  },
  {
    title: "Mechanics",
    claimTitle: "How to claim your Gudi NFT",
    content: [
      `<ol><li>Go to <b><a href="http://glbe.co/NEWGlobeONE" target="_blank" rel="noreferrer">Globe One App rewards catalog</a></b> and claim your Gudi NFT through these simple steps:<ul><li>Download the <b><a href="http://glbe.co/NEWGlobeONE" target="_blank" rel="noreferrer">New Globe One App</a></b> and click on the "Redeem Rewards" tab</li><li>Search for “MetaverseGo! Gudi NFT Voucher</li><li>Click “Redeem”</li><li>Wait for the confirmation notification, along with a message from 4438 containing the unique voucher code</li></ul></li>
      <li>After receiving your unique voucher code, please proceed to register for a MetaverseGo account by going to app.metaversego.gg:<ul><li>Register using your mobile and email</li><li>Navigate to the MetaverseGo Launchpad tab</li><li>Proceed to claim your Gudi NFT by copy-pasting the unique voucher code and tapping the "Redeem FREE NFT"</li><li>Congratulations on owning your first NFT! Post and share this moment with your friends.</li></ul></ol>` 
    ]
  },
]

export const gudiSquadData = [
  {
    title: "Eligibility",
    content: [
      "To be eligible to redeem your Gudi Squad NFT, you must be<ol></br><li>A Globe or TM prepaid and postpaid subscriber with at least 110 reward points to redeem your voucher code, or</li><li>An event attendee of DigiCon Valley 2022, code to be received via email</li></ol>"
    ]
  },
  {
    title: "Mechanics",
    claimTitle: "How to claim your Gudi Squad NFT",
    content: [
      `<ol><li><b>[For Globe Subscribers]</b> Go to <b><a href="http://glbe.co/NEWGlobeONE" target="_blank" rel="noreferrer">Globe One App rewards catalog</a></b> and claim your Gudi Squad NFT through these simple steps:<ul><li>Download the <b><a href="http://glbe.co/NEWGlobeONE" target="_blank" rel="noreferrer">New Globe One App</a></b> and click on the "Redeem Rewards" tab</li><li>Search for “MetaverseGo! Gudi Squad NFT Voucher</li><li>Click “Redeem”</li><li>Wait for the confirmation notification, along with a message from 4438 containing the unique voucher code</li></ul></br><b>[For DigiCon 2022 Attendees]</b> You will receive a unique voucher code from MetaverseGo. If you do not see it in your email inbox, please check your junk or spam folder.</li></br>
      <li>After receiving your unique voucher code, please proceed to register for a MetaverseGo account by going to app.metaversego.gg:<ul><li>Register using your mobile and email</li><li>Navigate to the MetaverseGo Launchpad tab</li><li>Proceed to claim your Gudi Squad NFT by copy-pasting the unique voucher code and tapping the "Redeem FREE NFT"</li><li>Congratulations on owning your first NFT! Post and share this moment with your friends.</li></ul></ol>` 
    ]
  },
]

export const astroData = [
  {
    title: "Eligibility",
    content: [
      "SM Megamall customers are entitled to claim one (1) free Astro NFT per mobile number."
    ]
  },
  {
    title: "Mechanics",
    claimTitle: "How to claim your Astro NFT",
    content: [
      `<ol><li>Go to any of the participating SM Megamall stores below:<ul><li>Slimmers World</li><li>Crocs</li><li>The Moment Group: 8 Cuts, Manam, Din Tai Fung, Ooma, and Mo’ Cookies</li><li>Plains & Prints</li><li>Uniqlo</li></br></ul>
      <li>Claim your free Astro NFT through the MetaverseGo app with these simple steps:<ul><li>Scan the QR code on the tent card or other applicable marketing materials.</li><li>Log in or create a MetaverseGo account using your mobile number and email address.</li><li>You will be redirected to the Astro NFT page. Tap CLAIM NFT to redeem your free Astro NFT.</ul></ol>Congratulations on owning your first NFT! Post and share this moment with your friends.` 
    ]
  },
]

export const spinData = [
  {
    title: "Eligibility",
    content: [
      `Spins are awarded only to Globe Subscribers who redeemed their Gudi Squad NFT during<ul><li>Globe GDay Everyday campaign starting on September 18, 2022-December 31, 2022</li><li>DigiCon Valley 2022 from Oct 11 - 21, 2022.</li></ul>To be eligible:</br>You must have redeemed your Gudi Squad NFT voucher via the GlobeOne app or via the email sent to DigiCon delegates.`
    ],
    button: "Redeem NFT now",
    description: `Each redemption allows you one (1) chance to spin the wheel. You may redeem a Gudi Squad NFT up to six (6) times.`
  },
  {
    title: "Mechanics",
    content: [
      `Participants of the MetaverseGo Spin-the-Wheel will have a chance to win exclusive prizes from MetaverseGo and Globe. Each participant must have a Gudi Squad NFT voucher code to claim one (1) Gudi Squad NFT to participate. <ol><li>Go to MetaverseGo Launchpad and redeem your Gudi Squad NFT using your Gudi Squad NFT voucher code.</li><li>Tap the 'Play Button' to spin the wheel.</li><li>Claim your prize.</li></ol>`
    ]
  },
  {
    title: "How to Play",
    content: [
      `Ensure that:<ol><li>You have redeemed your Gudi Squad NFT voucher via GlobeOne app upon conversion of your rewards points or via the email sent to DigiCon delegates.</li><li>Claim your Gudi Squad NFT using the voucher.</li><li>You will have your Gudi Squad NFT revealed to you and an option to spin the wheel.</li><li>Click on the spin the wheel button, which will direct you to the Spin-the-Wheel Page.</li><li>Click on the spin button located in the middle of the wheel.</li><li>You will be informed of your prize immediately.</li></ol>`
    ]
  },
]

export const spinDataAstro = [
  {
    title: "Eligibility",
    content: [
      `Spins are awarded to SM Megamall customers who have redeemed the free Astro Valentine's Day and Women's Month special NFT from February 6, 2023 to March 31, 2023`
    ]
  },
  {
    title: "Mechanics",
    content: [
      `Participants of the MetaverseGo Spin-the-Wheel will have a chance to win exclusive prizes from select SM Megamall stores.</br></br>Here’s how:</br><ol><li>Scan the QR code at select SM Megamall stores to claim one (1) Astro NFT to be able to collect a free spin. Participants are entitled to claim one (1) Astro NFT per mobile number.</li><li>Participants will be redirected to the MetaverseGo Astro NFT page to redeem their Astro NFT.</li><li>Once done, tap the ‘Play Button’ to spin the wheel.</li><li>Claim your prize.</li></ol>Collect up to 11 spins on our Spin the Wheel when you scan the unique QR codes at select stores and the MetaverseGo booth at SM Megamall. However, you can only collect one (1) free spin from a QR code once.`
    ]
  },
  {
    title: "How to Play",
    content: [
      `Ensure that:<ol><li>You have redeemed your Astro NFT by scanning the QR code at select SM Megamall stores.</li><li>You will have your Astro NFT revealed to you and an option to spin the wheel.</li><li>Click on the spin-the-wheel button, which will direct you to the Spin-the-Wheel page.</li><li>Click on the spin button located in the middle of the wheel.</li><li>You will be informed of your prize immediately.</li></ol>`
    ]
  },
]