import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import { Row, Col } from 'react-bootstrap';
import Cookies from 'js-cookie';
import {
  ActiveGamePasses,
  FloatingIconManager,
  GamePassPlayPopup,
  GoldCoinsHeader,
  LayoutContent,
  PageTitle,
  RedirectToMobileAppToast,
  Section
} from '../Components';
import { Toast } from '../Commons';
import { 
  getGamePassTournaments,
  getGamePassActiveEntitlements,
  getUserProfile,
  setDisplayToast,
  setGamePassPlayGame,
  setAuthStep
} from '../../actions';
import {
  gamePassTournamentsSelector,
  referralToastSelector,
  entitlementsSelector,
  userEntitlementsSelector,
  walletCryptoSelector,
  guestSelector
} from '../../selectors';
import {
  authSteps,
  bannerDimensions,
  defaultTimeoutMS,
  gamePassGamesCTA,
  gamePassText,
  goldBoosterText,
  maxWidths,
  wenLamboSpinTheWheelCTA,
  storageKeys,
  mobileAppPromptText
} from '../../constants';
import { getGoldCoinsValue } from '../../utils/getWalletCredits';
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import bannerDesktop from '../../assets/gamepass/banners/games/desktop.png';
import bannerMobile from '../../assets/gamepass/banners/games/mobile.png';
import './GamePassGames.scss';

export const GamePassGames = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [games, setGames] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState({
    id: '',
    slug: ''
  });
  const [showRedirectoMobileAppToast, setShowRedirectoMobileAppToast] = useState(false);
  
  const isGuest = useSelector(state => guestSelector(state));
  const crypto = useSelector(state => walletCryptoSelector(state));
  const tournaments = useSelector(state => gamePassTournamentsSelector(state));
  const toast = useSelector(state => referralToastSelector(state));
  const entitlements = useSelector(state => entitlementsSelector(state));
  const userEntitlements = useSelector(state => userEntitlementsSelector(state));
  const isMobile = useMediaQuery({ maxWidth: maxWidths.mobileSM });

  const isSwitchToAppWenLamboShown = Cookies.get(storageKeys.switchToApp.wenLamboGames);
  const goldCoinsValue = getGoldCoinsValue(crypto);

  let banner = bannerDesktop;
  let dimensions = bannerDimensions.gamePassGamesBanner.desktop;
  if (isMobile) {
    banner = bannerMobile;
    dimensions = bannerDimensions.gamePassGamesBanner.mobile;
  }

  useEffect(() => {
    dispatch(getGamePassTournaments());
    if (!isGuest) {
      dispatch(getGamePassActiveEntitlements());
      dispatch(getUserProfile());
    }
  }, [dispatch, isGuest])

  useEffect(() => {
    if (!isSwitchToAppWenLamboShown && !isGuest) {
      const deviceInfo = getDeviceInfo();
      const android = /android/i.test(deviceInfo.os);

      if (android) {
        setShowRedirectoMobileAppToast(android);
        Cookies.set(storageKeys.switchToApp.wenLamboGames, true);
      }
    }
  }, [isSwitchToAppWenLamboShown, isGuest])

  useEffect(() => {
    setGames(tournaments);
  }, [tournaments])

  const handlePlayGame = (gameDetails) => {
    const { slug, id } = gameDetails;
    if (isEmpty(userEntitlements?.gamePass) && !isGuest) {
      dispatch(setGamePassPlayGame(true));
      setSelectedGame(gameDetails);
    } else {
      navigate(`/play/${slug}/${id}`);
    }
  }

  useEffect(() => {
    dispatch(setGamePassPlayGame(false));
    setToastOpen(true);
  }, [dispatch, toast]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  const handleAdBannerClick = () => {
    if (!isGuest) {
      navigate(wenLamboSpinTheWheelCTA);
    } else {
      dispatch(setAuthStep(authSteps.login));
    }
  }

  const activeGamePasses = !isEmpty(entitlements) && entitlements.find(e => e.name === gamePassText);
  const activeGoldBoosters = !isEmpty(entitlements) && entitlements.find(e => e.name === goldBoosterText);
  let activeGamePassItems = [];
  let activeGoldBoosterItems = [];
  if (activeGamePasses) activeGamePassItems = activeGamePasses.items;
  if (activeGoldBoosters) activeGoldBoosterItems = activeGoldBoosters.items;

  return (
    <LayoutContent outerClassName="gamepass-games">
      <PageTitle title="Games" />
      <Section 
        sectionClassName="gamepass-games-section"
        backButtonRoute
        backRoute={() => navigate(-1)}
        title="Play and Earn Gold"
        titleCentered
        text="Back"
      >
        <GoldCoinsHeader
          value={goldCoinsValue}
          className="gamepass-games-coin-container"
        />
        <img
          src={banner}
          alt="banner"
          className="gamepass-games-banner"
          width={dimensions.width}
          height={dimensions.height}
          onClick={handleAdBannerClick}
        />
        {!isGuest &&
          <ActiveGamePasses
            activeGamePass={activeGamePassItems}
            activeGoldBooster={activeGoldBoosterItems}
            containerClassName="gamepass-games-passes"
            hasHeader={!isMobile}
            inventoryRedirect
            compact
          />}
        <Row className="gamepass-games-container">
          {games &&
            !isEmpty(games) &&
            games.map((game, gIdx) => {
              const gameDetailsUrl = `${gamePassGamesCTA}/${game.id}`;

              let activePlayers = null;
              if (game.participants) {
                activePlayers = `${game.participants} Players`;
              }

              return (
                <Col
                  xs="6"
                  sm="4"
                  md="3"
                  xl="2"
                  key={`GamePassGame_${gIdx}_${game.id}`}
                  className="gamepass-games-game"
                >
                  <div className="gamepass-games-game-thumbnail">
                    <div className="gamepass-games-game-wrapper">
                      <Link
                        to={gameDetailsUrl}
                        className="gamepass-games-game-image"
                        style={{ backgroundImage: `url(${game.imageUrl})` }}
                      />
                      <div className="gamepass-games-game-details">
                        <Link
                          to={gameDetailsUrl}
                          className="gamepass-games-game-info"
                        >
                          <div className="gamepass-games-game-genre">{game.genre}</div>
                          <div className="gamepass-games-game-name">{game.name}</div>
                          {activePlayers &&
                            <div className="gamepass-games-game-stats">
                              <div className="gamepass-games-game-stats-icon" />
                              <div className="gamepass-games-game-stats-value">{activePlayers}</div>
                            </div>}
                        </Link>
                        <div 
                          onClick={() => handlePlayGame({
                            id: game.id,
                            slug: game.slug
                          })} 
                          className="gamepass-games-game-button"
                        >
                          Play Now
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
      </Section>
      <GamePassPlayPopup {...selectedGame} />
      <FloatingIconManager />
      {!isGuest &&
        <RedirectToMobileAppToast 
          open={showRedirectoMobileAppToast}
          hide={() => setShowRedirectoMobileAppToast(false)}
          title={mobileAppPromptText.wenLamboGames}
          description={mobileAppPromptText.wenLamboGamesDescription}
        />}
      {toast && 
        <Toast toast={toast} open={toastOpen} hide={closeToast} />}
    </LayoutContent>
  );
}