export const rewardDetailsTexts = {
  warning: 'Do not click MARK AS REDEEMED until you have received the reward. Once marked as redeemed, you may no longer be eligible to claim.',
  emptyFormError: 'Please answer all required fields.'
};
export const modalNotificationsTags = {
  rewards: 'rewards'
};
export const modalNotificationsBodyKind = {
  header: 'header',
  image: 'image',
  text: 'text',
  subText: 'subtext',
  cta: 'cta',
  animation: 'animation'
};
export const claimedRewardText = {
  giftRevealGold: 'giftRevealGold',
  giftRevealGoldBooster: 'giftRevealGoldBooster'
};
export const firstTimeCompletionRewardsTexts = {
  title: 'First Time Completion Rewards',
  description: 'Earn BONUS GOLD by completing the tasks to get closer to your first Wen Lambo spin!',
  claimAll: 'Claim All'
};
export const firstTimeCompletionRewardsStatuses = {
  inProgress: 'in-progress',
  claimed: 'claimed',
  completed: 'completed'
};
export const firstTimeCompletionRewardsGold = {
  gold50: '50-gold',
  gold100: '100-gold',
  gold200: '200-gold',
  gold300: '300-gold'
};
export const firstTimeCompletionRewardsKinds = {
  playWithGamePass: 'play_with_gp-1'
};
export const freeGoldText = {
  title: "You've got the Gold, now it's time to spin!",
  description: 'See what incredible prizes awaits you.',
  button: 'Spin Now'
};
export const rewardPartnersSections = {
  brand: 'Brand Partners',
  rush: 'Rush',
  game: 'Game Partners'
};
export const carouselText = 'carousel';
export const rewardsCenterTexts = {
  expired: 'Expired',
  expiresAt: 'Expires at',
  viewMechanicsAndTC: 'View Mechanics and T&C'
};
export const rewardStatuses = {
  active: 'active',
  used: 'used',
  expired: 'expired',
  redeemed: 'redeemed'
};
export const rewardConfirmModalTexts = {
  markAsRedeemed: {
    title: 'Confirm',
    description: 'Do you want to mark this as redeemed?',
    button: "Yes, I'm Sure"
  },
  mobileNumberValidation: {
    title: 'Notice',
    description: "All succeeding prizes can only be redeemed through this mobile number. This cannot be used by any other account beyond your current credentials.",
    button: 'Confirm'
  }
};