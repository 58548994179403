export const SET_LOADING = 'metaverseGo/SET_LOADING';
export const SET_SHOW_LOADING = 'metaverseGo/SET_SHOW_LOADING';
export const RENDER_PAGE_CONTENTS = 'metaverseGo/RENDER_PAGE_CONTENTS';
export const SHOW_ORDER_SUMMARY = 'metaverseGo/SHOW_ORDER_SUMMARY';
export const SET_REMOTE_CONFIG = 'metaverseGo/SET_REMOTE_CONFIG'
export const SET_FAILED_REQUESTS = 'metaverseGo/SET_FAILED_REQUESTS';
export const NAVIGATE_TO = 'metaverseGo/NAVIGATE_TO';
export const RESET_STATE = 'metaverseGo/RESET_STATE';
export const SET_UNAUTHORIZED_REDIRECT = 'metaverseGo/SET_UNAUTHORIZED_REDIRECT';
export const SHOW_ACTIVE_MODAL = 'metaverseGo/SHOW_ACTIVE_MODAL';

export const TRY_LOGIN = 'metaverseGo/TRY_LOGIN';
export const DISPLAY_REGISTRATION_FORM = 'metaverseGo/DISPLAY_REGISTRATION_FORM';

export const SUBMIT_LOGIN = 'metaverseGo/SUBMIT_LOGIN';
export const SUBMIT_LOGIN_OTP = 'metaverseGo/SUBMIT_LOGIN_OTP';
export const START_LOGIN_OTP_TIMER = 'metaverseGo/START_LOGIN_OTP_TIMER';
export const START_EMAIL_LOGIN_OTP_TIMER = 'metaverseGo/START_EMAIL_LOGIN_OTP_TIMER';
export const SUBMIT_LOGIN_PIN = 'metaverseGo/SUBMIT_LOGIN_PIN';
export const LOGIN_SUCCESS = 'metaverseGo/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'metaverseGo/LOGIN_FAILURE';
export const LOGIN_OTP_SUCCESS = 'metaverseGo/LOGIN_OTP_SUCCESS';
export const LOGIN_OTP_FAILURE = 'metaverseGo/LOGIN_OTP_FAILURE';
export const LOGIN_DATA = 'metaverseGo/LOGIN_DATA';
export const SET_AUTH_STEP = 'metaverseGo/SET_AUTH_STEP';
export const SET_SESSION_EXPIRED = 'metaverseGo/SET_SESSION_EXPIRED';
export const VALIDATE_PIN = 'metaverseGo/VALIDATE_PIN';
export const DISPLAY_SET_PIN_FORM = 'metaverseGo/DISPLAY_SET_PIN_FORM';
export const RESEND_EMAIL_OTP = 'metaverseGo/RESEND_EMAIL_OTP';
export const SET_ERROR_MESSAGE = 'metaverseGo/SET_ERROR_MESSAGE';
export const ENABLE_EMAIL_OTP = 'metaverseGo/ENABLE_EMAIL_OTP';
export const SET_EMAIL_OTP_TO_ENABLED = 'metaverseGo/SET_EMAIL_OTP_TO_ENABLED';

export const SUBMIT_PIN = 'metaverseGo/SUBMIT_PIN';
export const RECOVER_PIN = 'metaverseGo/RECOVER_PIN';
export const START_PIN_OTP_TIMER = 'metaverseGo/START_PIN_OTP_TIMER';
export const SUBMIT_PIN_OTP = 'metaverseGo/SUBMIT_PIN_OTP';
export const SUBMIT_NEW_PIN = 'metaverseGo/SUBMIT_NEW_PIN';
export const SET_SUBMIT_NEW_PIN = 'metaverseGo/SET_SUBMIT_NEW_PIN';
export const SET_PIN_AUTH_STEP = 'metaverseGo/SET_PIN_AUTH_STEP';
export const SET_PIN_VALIDATION_ERROR = 'metaverseGo/SET_PIN_VALIDATION_ERROR';

export const SET_DISPLAY_TOAST = 'metaverseGo/SET_DISPLAY_TOAST';

export const SET_HIDDEN_HEADER = 'metaverseGo/SET_HIDDEN_HEADER';
export const SET_HIDDEN_MENU_ICON = 'metaverseGo/SET_HIDDEN_MENU_ICON';
export const SET_HIDDEN_FOOTER ='metaverseGo/SET_HIDDEN_FOOTER';
export const SET_WALKTHROUGH_HEIGHT_LIMIT_FLAG = 'metaverseGo/SET_WALKTHROUGH_HEIGHT_LIMIT_FLAG';
export const DISABLE_FOOTER_POINTER_EVENT = 'metaverseGo/DISABLE_FOOTER_POINTER_EVENT';

export const GET_USER_PROFILE = 'metaverseGo/GET_USER_PROFILE';
export const SET_USER_PROFILE = 'metaverseGo/SET_USER_PROFILE';
export const USER_PROFILE_REQUESTED = 'metaverseGo/USER_PROFILE_REQUESTED';
export const GET_USER_PROFILE_FIELDS = 'metaverseGo/GET_USER_PROFILE_FIELDS';
export const SET_USER_PROFILE_FIELDS = 'metaverseGo/SET_USER_PROFILE_FIELDS';
export const SET_USER_PROFILE_COUNTRY_CODE = 'metaverseGo/SET_USER_PROFILE_COUNTRY_CODE';
export const EDIT_USER_PROFILE = 'metaverseGo/EDIT_USER_PROFILE';
export const UPDATE_USER_PROFILE_PHOTO = 'metaverseGo/UPDATE_USER_PROFILE_PHOTO';
export const REMOVE_USER_PROFILE_PHOTO = 'metaverseGo/REMOVE_USER_PROFILE_PHOTO';
export const SET_USER_PROFILE_ERROR_STATUS = 'metaverseGo/SET_USER_PROFILE_ERROR_STATUS';
export const SET_GUEST = 'metaverseGo/SET_GUEST';
export const SET_URL_PARAMS = 'metaverseGo/SET_URL_PARAMS';

export const GET_HOME_COLLECTION = 'metaverseGo/GET_HOME_COLLECTION';
export const SET_HOME_COLLECTION = 'metaverseGo/SET_HOME_COLLECTION';
export const SET_HOME_TUTORIAL = 'metaverseGo/SET_HOME_TUTORIAL';

export const GET_GAME_DETAILS = 'metaverseGo/GET_GAME_DETAILS';
export const SET_GAME_DETAILS = 'metaverseGo/SET_GAME_DETAILS';

export const GET_NFT_COLLECTION_DETAILS = 'metaverseGo/GET_NFT_COLLECTION_DETAILS';
export const SET_NFT_COLLECTION_DETAILS = 'metaverseGo/SET_NFT_COLLECTION_DETAILS';
export const GET_NFT_ITEM_DETAILS = 'metaverseGo/GET_NFT_ITEM_DETAILS';
export const SET_NFT_ITEM_DETAILS = 'metaverseGo/SET_NFT_ITEM_DETAILS';
export const BUY_NFT_ITEM = 'metaverseGo/BUY_NFT_ITEM';

export const GET_LAUNCHPAD_COLLECTION = 'metaverseGo/GET_LAUNCHPAD_COLLECTION';
export const SET_LAUNCHPAD_COLLECTION = 'metaverseGo/SET_LAUNCHPAD_COLLECTION';
export const GET_LAUNCHPAD_COLLECTION_ITEM = 'metaverseGo/GET_LAUNCHPAD_COLLECTION_ITEM';
export const SET_LAUNCHPAD_COLLECTION_ITEM = 'metaverseGo/SET_LAUNCHPAD_COLLECTION_ITEM';
export const MINT_NFT_LAUNCHPAD_ITEM = 'metaverseGo/MINT_NFT_LAUNCHPAD_ITEM';
export const MINTING_IS_SUCCESS = 'metaverseGo/MINTING_IS_SUCCESS';
export const MINTING_IS_REDEEM = 'metaverseGo/MINTING_IS_REDEEM';
export const GET_NFT_DATA = 'metaverseGo/GET_NFT_DATA';
export const GET_PRIZE_SLUG = 'metaverseGo/GET_PRIZE_SLUG';
export const SET_PRIZE_SLUG ='metaverseGo/SET_PRIZE_SLUG';
export const SET_PRIZE_SLUG_DATA ='metaverseGo/SET_PRIZE_SLUG_DATA';
export const GET_DYNAMIC_NFT_SLUG = 'metaverseGo/GET_DYNAMIC_NFT_SLUG';
export const SET_DYNAMIC_NFT_SLUG = 'metaverseGo/SET_DYNAMIC_NFT_SLUG';

export const GET_USER_COLLECTIONS = 'metaverseGo/GET_USER_COLLECTIONS';
export const SET_USER_COLLECTIONS = 'metaverseGo/SET_USER_COLLECTIONS';
export const GET_USER_COLLECTION = 'metaverseGo/GET_USER_COLLECTION';
export const SET_USER_COLLECTION = 'metaverseGo/SET_USER_COLLECTION';

export const GET_USER_TRANSACTIONS = 'metaverseGo/GET_USER_TRANSACTIONS';
export const SET_USER_TRANSACTIONS = 'metaverseGo/SET_USER_TRANSACTIONS';
export const SET_USER_TRANSACTIONS_SIZE = 'metaverseGo/SET_USER_TRANSACTIONS_SIZE';
export const SET_CLEAR_USER_TRANSACTIONS = 'metaverseGo/SET_CLEAR_USER_TRANSACTIONS';
export const GET_USER_TRANSACTIONS_DETAILS = 'metaverseGo/GET_USER_TRANSACTIONS_DETAILS';
export const SET_USER_TRANSACTIONS_DETAILS = 'metaverseGo/SET_USER_TRANSACTIONS_DETAILS';
export const GET_USER_TRANSACTION_DATA = 'metaverseGo/GET_USER_TRANSACTION_DATA';
export const SET_USER_TRANSACTION_DATA = 'metaverseGo/SET_USER_TRANSACTION_DATA';
export const SET_TRANSACTION_ACTIVE_TAB = 'metaverseGo/SET_TRANSACTION_ACTIVE_TAB';

export const GET_USER_COMMUNITIES = 'metaverseGo/GET_USER_COMMUNITIES';
export const SET_USER_COMMUNITIES = 'metaverseGo/SET_USER_COMMUNITIES';
export const GET_USER_COMMUNITY = 'metaverseGo/GET_USER_COMMUNITY';
export const SET_USER_COMMUNITY = 'metaverseGo/SET_USER_COMMUNITY';
export const GET_GUILD_APPLICATION_FORM = 'metaverseGo/GET_GUILD_APPLICATION_FORM';
export const SET_GUILD_APPLICATION_FORM = 'metaverseGo/SET_GUILD_APPLICATION_FORM';
export const SUBMIT_GUILD_APPLICATION_FORM = 'metaverseGo/SUBMIT_GUILD_APPLICATION_FORM';
export const CLAIM_SCHOLARSHIP = 'metaverseGo/CLAIM_SCHOLARSHIP';

export const GET_SPEND_MOBILE_LOAD_PRODUCTS = 'metaverseGo/GET_SPEND_MOBILE_LOAD_PRODUCTS';
export const SET_SPEND_MOBILE_LOAD_PRODUCTS = 'metaverseGo/SET_SPEND_MOBILE_LOAD_PRODUCTS';
export const CONFIRM_SPEND_MOBILE_LOAD_PRODUCT = 'metaverseGo/CONFIRM_SPEND_MOBILE_LOAD_PRODUCT';
export const SET_SPEND_MOBILE_LOAD_PRODUCT_CONFIRMATION = 'metaverseGo/SET_SPEND_MOBILE_LOAD_PRODUCT_CONFIRMATION';
export const SET_SPEND_COUNTRY = 'metaverseGo/SET_SPEND_COUNTRY';
export const GET_SPEND_COUNTRY = 'metaverseGo/GET_SPEND_COUNTRY';
export const PAY_MOBILE_LOAD_PRODUCT = 'metaverseGo/PAY_MOBILE_LOAD_PRODUCT';
export const SET_SPEND_PAYMENT_STEP = 'metaverseGo/SET_SPEND_PAYMENT_STEP';
export const SET_SPEND_LOADING = 'metaverseGo/SET_SPEND_LOADING';
export const SET_SPEND_ERROR = 'metaverseGo/SET_SPEND_ERROR';
export const SUBMIT_SPEND_OTP = 'metaverseGo/SUBMIT_SPEND_OTP';
export const SEND_SPEND_OTP = 'metaverseGo/SEND_SPEND_OTP';
export const SET_SPEND_OTP_SUCCESS = 'metaverseGo/SET_SPEND_OTP_SUCCESS';
export const SET_SPEND_REFERENCE_ID = 'metaverseGo/SET_SPEND_REFERENCE_ID';
export const SET_SPEND_LOADING_TEXT = 'metaverseGo/SET_SPEND_LOADING_TEXT';

export const SET_UNREAD_MESSAGES = 'metaverseGo/SET_UNREAD_MESSAGES';
export const GET_CHAT_MESSAGES = 'metaverseGo/GET_CHAT_MESSAGES';
export const SET_CHAT_MESSAGES = 'metaverseGo/SET_CHAT_MESSAGES';
export const GET_CHAT_MESSAGE_THREAD = 'metaverseGo/GET_CHAT_MESSAGE_THREAD';
export const SET_CHAT_MESSAGE_THREAD = 'metaverseGo/SET_CHAT_MESSAGE_THREAD';
export const SUBMIT_CHAT_MESSAGE = 'metaverseGo/SUBMIT_CHAT_MESSAGE';

export const GET_TUTORIAL_SCREENS = 'metaverseGo/GET_TUTORIAL_SCREENS';
export const SET_TUTORIAL_SCREENS = 'metaverseGo/SET_TUTORIAL_SCREENS';
export const GET_PROFILE_TUTORIAL = 'metaverseGo/GET_PROFILE_TUTORIAL';
export const SET_PROFILE_TUTORIAL = 'metaverseGo/SET_PROFILE_TUTORIAL';

export const GET_TRANSACTIONS = 'metaverseGo/GET_TRANSACTIONS';
export const SET_TRANSACTIONS = 'metaverseGo/SET_TRANSACTIONS';
export const GET_MORE_TRANSACTIONS = 'metaverseGo/GET_MORE_TRANSACTIONS';

export const GET_QUESTS = 'metaverseGo/GET_QUESTS';
export const SET_QUESTS = 'metaverseGo/SET_QUESTS';
export const SET_QUEST_LEADERBOARDS = 'metaverseGo/SET_QUEST_LEADERBOARDS';
export const SET_QUEST_FAQS = 'metaverseGo/SET_QUEST_FAQS';

export const GET_FEATURED_PROMOTION = 'metaverseGo/GET_FEATURED_PROMOTION';
export const SET_FEATURED_PROMOTION = 'metaverseGo/SET_FEATURED_PROMOTION';
export const GET_FEATURED_PROMOTION_SETTINGS = 'metaverseGo/GET_FEATURED_PROMOTION_SETTINGS';
export const SET_REDEEM_DATA = 'metaverseGo/SET_REDEEM_DATA';
export const GET_TICKET = 'metaverseGo/GET_TICKET';
export const SET_CLAIM_DATA = 'metaverseGo/SET_CLAIM_DATA';
export const GET_REDEEM = 'metaverseGo/GET_REDEEM';
export const SET_REDEEM = 'metaverseGo/SET_REDEEM';

export const GET_SPIN_PRIZE = 'metaverseGo/GET_SPIN_PRIZE';
export const SET_SPIN_PRIZE = 'metaverseGo/SET_SPIN_PRIZE';
export const GET_SPIN_PRIZES = 'metaverseGo/GET_SPIN_PRIZES';
export const SET_SPIN_PRIZES = 'metaverseGo/SET_SPIN_PRIZES';
export const GET_SPIN_AVAILABLE = 'metaverseGo/GET_SPIN_AVAILABLE';
export const SET_SPIN_AVAILABLE = 'metaverseGo/SET_SPIN_AVAILABLE';
export const GET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS = 'metaverseGo/GET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS';
export const SET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS = 'metaverseGo/SET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS';

export const GET_ONBOARDING_TUTORIALS = 'metaverseGo/GET_ONBOARDING_TUTORIALS';
export const SET_ONBOARDING_TUTORIALS = 'metaverseGo/SET_ONBOARDING_TUTORIALS';

export const GET_PAYMENT_METHODS = 'metaverseGo/GET_PAYMENT_METHODS';
export const SET_PAYMENT_METHODS = 'metaverseGo/SET_PAYMENT_METHODS';
export const GET_PAYMENT_CHANNELS = 'metaverseGo/GET_PAYMENT_CHANNELS';
export const SET_PAYMENT_CHANNELS = 'metaverseGo/SET_PAYMENT_CHANNELS';
export const CREATE_PAYMENT = 'metaverseGo/CREATE_PAYMENT';
export const SET_CREATE_PAYMENT = 'metaverseGo/SET_CREATE_PAYMENT';
export const GET_CURRENCY_CONVERSION = 'metaverseGo/GET_CURRENCY_CONVERSION';
export const SET_CURRENCY_CONVERSION = 'metaverseGo/SET_CURRENCY_CONVERSION';
export const GET_CURRENCY_CONVERSION_BATCH = 'metaverseGo/GET_CURRENCY_CONVERSION_BATCH';
export const SET_CURRENCY_CONVERSION_BATCH = 'metaverseGo/SET_CURRENCY_CONVERSION_BATCH';
export const SET_CURRENCY_LOADING = 'metaverseGo/SET_CURRENCY_LOADING';
export const SET_WALLET_CURRENCY_CONVERSION = 'metaverseGO/SET_WALLET_CURRENCY_CONVERSION';
export const CONNECT_FROM_TOPUP = 'metaverseGo/CONNECT_FROM_TOPUP';

export const SET_TOPUP_VISIBLE = 'metaverseGo/SET_TOPUP_VISIBLE';
export const SET_INSTRUCTIONS_VISIBLE = 'metaverseGo/SET_INSTRUCTIONS_VISIBLE';
export const SET_BARCODE_VISIBLE = 'metaverseGo/SET_BARCODE_VISIBLE';
export const SET_TRANSACTION_VISIBLE = 'metaverseGo/SET_TRANSACTION_VISIBLE';
export const SET_SELECTED_METHOD = 'metaverseGo/SET_SELECTED_METHOD';

export const GET_MARKETPLACE_COLLECTIONS = 'metaverseGo/GET_MARKETPLACE_COLLECTIONS';
export const SET_MARKETPLACE_COLLECTIONS = 'metaverseGo/SET_MARKETPLACE_COLLECTIONS';
export const SET_MARKETPLACE_COLLECTION = 'metaverseGo/SET_MARKETPLACE_COLLECTION';
export const GET_MARKETPLACE_NFT_DETAILS = 'metaverseGo/GET_MARKETPLACE_NFT_DETAILS';
export const SET_MARKETPLACE_NFT_DETAILS = 'metaverseGo/SET_MARKETPLACE_NFT_DETAILS';
export const GET_COLLECTION_DETAILS = 'metaverseGo/GET_COLLECTION_DETAILS';
export const SET_COLLECTION_DETAILS = 'metaverseGo/SET_COLLECTION_DETAILS';
export const SET_COLLECTION_PAGINATION = 'metaverseGo/SET_COLLECTION_PAGINATION';
export const SET_COLLECTION_NFT_PAGINATION = 'metaverseGo/SET_COLLECTION_NFT_PAGINATION';
export const SET_COLLECTION_PAGINATION_LOADING = 'metaverseGo/SET_COLLECTION_PAGINATION_LOADING';
export const GET_ALL_LISTED_NFT = 'metaverseGo/GET_ALL_LISTED_NFT';
export const SET_ALL_LISTED_NFT = 'metaverseGo/SET_ALL_LISTED_NFT';
export const SET_ALL_LISTED_NFT_PAGINATION = 'metaverseGo/SET_ALL_LISTED_NFT_PAGINATION';
export const SET_LISTED_NFT_PAGINATION = 'metaverseGo/SET_LISTED_NFT_PAGINATION';
export const CLEAR_LISTED_NFT = 'metaverseGo/CLEAR_LISTED_NFT';
export const LIST_NFT = 'metaverseGo/LIST_NFT';
export const SET_LIST_NFT = 'metaverseGo/SET_LIST_NFT';
export const GET_LISTING_FEE = 'metaverseGo/GET_LISTING_FEE';
export const SET_LISTING_FEE = 'metaverseGo/SET_LISTING_FEE';
export const SET_LISTING_FEE_LOADING = 'metaverseGo/SET_LISTING_FEE_LOADING';
export const BUY_NFT = 'metaverseGo/BUY_NFT';
export const SET_BUY_NFT = 'metaverseGo/SET_BUY_NFT';
export const SET_BUY_NFT_ID = 'metaverseGo/SET_BUY_NFT_ID';
export const CANCEL_LIST_NFT = 'metaverseGo/CANCEL_LIST_NFT';
export const SET_CANCEL_LIST_NFT = 'metaverseGo/SET_CANCEL_LIST_NFT';
export const SET_CANCEL_LIST_BACK = 'metaverseGo/SET_CANCEL_LIST_BACK';
export const SET_OPEN_MODAL = 'metaverseGo/SET_OPEN_MODAL';
export const SET_BUY_NAVIGATE = 'metaverseGo/SET_BUY_NAVIGATE';
export const SET_SELECTED_SLUG = 'metaverseGo/SET_SELECTED_SLUG';

export const GET_TOURNAMENTS = 'metaverseGo/GET_TOURNAMENTS';
export const SET_TOURNAMENTS = 'metaverseGo/SET_TOURNAMENTS';

export const GET_REWARDS = 'metaverseGo/GET_REWARDS';
export const SET_REWARDS = 'metaverseGo/SET_REWARDS';
export const GET_FEATURED_REWARDS = 'metaverseGo/GET_FEATURED_REWARDS';
export const SET_FEATURED_REWARDS = 'metaverseGo/SET_FEATURED_REWARDS';
export const GET_REWARD_DETAILS = 'metaverseGo/GET_REWARD_DETAILS';
export const SET_REWARD_DETAILS = 'metaverseGo/SET_REWARD_DETAILS';
export const REDEEM_REWARD = 'metaverseGo/REDEEM_REWARD';
export const SET_REWARD_IS_CLAIMED = 'metaverseGo/SET_REWARD_IS_CLAIMED';
export const GET_MODAL_NOTIFICATIONS = 'metaverseGo/GET_MODAL_NOTIFICATIONS'
export const SET_MODAL_NOTIFICATIONS = 'metaverseGo/SET_MODAL_NOTIFICATIONS'
export const SET_MODAL_NOTIFICATIONS_IS_LOADING = 'metaverseGo/SET_MODAL_NOTIFICATIONS_IS_LOADING'
export const GET_DAILY_REWARDS = 'metaverseGo/GET_DAILY_REWARDS';
export const SET_DAILY_REWARDS = 'metaverseGo/SET_DAILY_REWARDS';
export const CLAIM_DAILY_REWARDS = 'metaverseGo/CLAIM_DAILY_REWARDS';
export const SET_CLAIMED_DAILY_REWARDS = 'metaverseGo/SET_CLAIMED_DAILY_REWARDS';
export const CLAIM_DAILY_REWARDS_IS_LOADING = 'metaverseGo/SET_CLAIMED_DAILY_REWARDS_IS_LOADING';
export const CONFIRM_REWARD_REDEMPTION = 'metaverseGo/CONFIRM_REWARD_REDEMPTION';
export const SHOW_REWARD_OTP_FORM = 'metaverseGo/SHOW_REWARD_OTP_FORM';

export const GET_WITHDRAWAL_NFT = 'metaverseGo/GET_WITHDRAWAL_NFT';
export const SET_WITHDRAWAL_NFT = 'metaverseGo/SET_WITHDRAWAL_NFT';
export const GET_WITHDRAWAL_NFT_VALIDATE = 'metaverseGo/GET_WITHDRAWAL_NFT_VALIDATE';
export const SET_WITHDRAWAL_NFT_VALIDATE = 'metaverseGo/SET_WITHDRAWAL_NFT_VALIDATE';
export const GET_WITHDRAW_NFT = 'metaverseGo/GET_WITHDRAW_NFT';
export const SET_WITHDRAW_NFT = 'metaverseGo/SET_WITHDRAW_NFT';

export const GET_GAMEPASS_PRODUCTS = 'metaverseGo/GET_GAMEPASS_PRODUCTS';
export const SET_GAMEPASS_PRODUCTS = 'metaverseGo/SET_GAMEPASS_PRODUCTS';
export const GET_GAMEPASS_PRODUCT_DETAILS = 'metaverseGo/GET_GAMEPASS_PRODUCT_DETAILS';
export const SET_GAMEPASS_PRODUCT_DETAILS = 'metaverseGo/SET_GAMEPASS_PRODUCT_DETAILS';
export const GET_GAMEPASS_SKU_DETAILS = 'metaverseGo/GET_GAMEPASS_SKU_DETAILS';
export const SET_GAMEPASS_SKU_DETAILS = 'metaverseGo/SET_GAMEPASS_SKU_DETAILS';
export const GET_GAMEPASS_SCREEN = 'metaverseGo/GET_GAMEPASS_SCREEN';
export const SET_GAMEPASS_SCREEN = 'metaverseGo/SET_GAMEPASS_SCREEN';
export const SET_GAMEPASS_SELECTED_PRODUCT = 'metaverseGo/SET_GAMEPASS_SELECTED_PRODUCT';
export const BUY_GAMEPASS = 'metaverseGo/BUY_GAMEPASS';
export const SET_BUY_GAMEPASS = 'metaverseGo/SET_BUY_GAMEPASS';
export const GET_GAMEPASS_TOURNAMENTS = 'metaverseGo/GET_GAMEPASS_TOURNAMENTS';
export const SET_GAMEPASS_TOURNAMENTS = 'metaverseGo/SET_GAMEPASS_TOURNAMENTS';
export const GET_GAMEPASS_TOURNAMENTS_DETAILS = 'metaverseGo/GET_GAMEPASS_TOURNAMENTS_DETAILS';
export const SET_GAMEPASS_TOURNAMENTS_DETAILS = 'metaverseGo/SET_GAMEPASS_TOURNAMENTS_DETAILS';
export const GET_GAMEPASS_TOURNAMENT_TOKEN = 'metaverseGo/GET_TOURNAMENT_TOKEN';
export const SET_GAMEPASS_TOURNAMENT_TOKEN = 'metaverseGo/SET_TOURNAMENT_TOKEN';
export const RETRY_GAMEPASS_TOURNAMENT_TOKEN = 'metaverseGo/RETRY_GAMEPASS_TOURNAMENT_TOKEN';
export const SET_GAMEPASS_TRANSACTION_IS_FOUND = 'metaverseGo/SET_GAMEPASS_TRANSACTION_IS_FOUND';
export const SET_GAMEPASS_TRANSACTION_IS_IN_TIMELIMIT = 'metaverseGo/SET_GAMEPASS_TRANSACTION_IS_IN_TIMELIMIT';
export const GAMEPASS_ADD_TO_CART = 'metaversego/GAMEPASS_ADD_TO_CART';
export const SET_GAMEPASS_IS_ADDED_TO_CART ='metaverseGo/SET_GAMEPASS_IS_ADDED_TO_CART';
export const GET_GAMEPASS_CART_ITEMS = 'metaverseGo/GET_GAMEPASS_CART_ITEMS';
export const SET_GAMEPASS_CART_ITEMS = 'metaverseGo/SET_GAMEPASS_CART_ITEMS';
export const GET_GAMEPASS_CART_COUNT = 'metaverseGo/GET_GAMEPASS_CART_COUNT';
export const SET_GAMEPASS_CART_COUNT = 'metaverseGo/SET_GAMEPASS_CART_COUNT';
export const UPDATE_GAMEPASS_CART_ITEMS = 'metaverseGo/UPDATE_GAMEPASS_CART_ITEMS';
export const SET_UPDATE_GAMEPASS_CART_ITEMS = 'metaverseGo/SET_UPDATE_GAMEPASS_CART_ITEMS';
export const ADD_GAMEPASS_TO_CART = 'metaverseGo/ADD_GAMEPASS_TO_CART';
export const GET_GAMEPASS_CHECKOUT = 'metaverseGo/GET_GAMEPASS_CHECKOUT';
export const SET_GAMEPASS_CHECKOUT = 'metaverseGo/SET_GAMEPASS_CHECKOUT';
export const SHOW_GAMEPASS_CHECKOUT = 'metaverseGo/SHOW_GAMEPASS_CHECKOUT';
export const UPDATE_GAMEPASS_CHECKOUT = 'metaverseGo/UPDATE_GAMEPASS_CHECKOUT';
export const PAY_GAMEPASS_ON_CHECKOUT = 'metaverseGo/PAY_GAMEPASS_ON_CHECKOUT';
export const PAY_GAMEPASS_ON_CHECKOUT_OTP = 'metaverseGo/PAY_GAMEPASS_ON_CHECKOUT_OTP';
export const PAY_GAMEPASS_ON_CHECKOUT_RESEND_OTP = 'metaverseGo/PAY_GAMEPASS_ON_CHECKOUT_RESEND_OTP';
export const SET_GAMEPASS_CHECKOUT_PAYMENT = 'metaverseGo/SET_GAMEPASS_CHECKOUT_PAYMENT';
export const GAMEPASS_CHECKOUT_RETURN_TO_PREVIOUS_PAGE = 'metaverseGo/GAMEPASS_CHECKOUT_RETURN_TO_PREVIOUS_PAGE';
export const GET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS = 'metaverseGo/GET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS';
export const SET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS = 'metaverseGo/SET_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS';
export const ADD_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS = 'metaverseGo/ADD_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS';
export const SET_ADDED_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS = 'metaverseGo/SET_ADDED_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS';
export const DELETE_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS = 'metaverseGo/DELETE_GAMEPASS_CHECKOUT_TOKENIZED_PAYMENTS';
export const SET_SHOW_PAYMENT_METHODS = 'metaverseGo/SET_SHOW_PAYMENT_METHODS';
export const SET_GAMEPASS_CHECKOUT_FORM = 'metaverseGo/SET_GAMEPASS_CHECKOUT_FORM';
export const SET_CLAIM_FREE_GAMEPASS = 'metaverseGo/SET_CLAIM_FREE_GAMEPASS';
export const SELECT_GAMEPASS_CHECKOUT_CURRENCY = 'metaverseGo/SELECT_GAMEPASS_CHECKOUT_CURRENCY';
export const GET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY = 'metaverseGo/GET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY';
export const SET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY = 'metaverseGo/SET_GAMEPASS_CHECKOUT_SELECTED_CURRENCY';
export const SHOW_CURRENCY_TOAST = 'metaverseGo/SHOW_CURRENCY_TOAST';
export const DISABLE_CHECKOUT_BUTTON = 'metaverseGo/DISABLE_CHECKOUT_BUTTON';
export const SET_GAMEPASS_CHECKOUT_SELECTED_PAYMENT_METHOD = 'metaverseGo/SET_GAMEPASS_CHECKOUT_SELECTED_PAYMENT_METHOD';
export const REDIRECT_TO_CHECKOUT_PAGE = 'metaverseGo/REDIRECT_TO_CHECKOUT_PAGE';

export const GET_GAMEPASS_INVENTORY = 'metaverseGo/GET_GAMEPASS_INVENTORY';
export const SET_GAMEPASS_INVENTORY = 'metaverseGo/SET_GAMEPASS_INVENTORY';
export const ACTIVATE_GAMEPASS = 'metaverseGo/ACTIVATE_GAMEPASS';
export const SET_ACTIVATE_GAMEPASS = 'metaverseGo/SET_ACTIVATE_GAMEPASS';
export const SET_GAMEPASS_PLAY_GAME = 'metaverseGo/SET_GAMEPASS_PLAY_GAME';
export const GET_GAMEPASS_ACTIVE_ENTITLEMENTS = 'metaverseGo/GET_GAMEPASS_ACTIVE_ENTITLEMENTS';
export const SET_GAMEPASS_ACTIVE_ENTITLEMENTS = 'metaverseGo/SET_GAMEPASS_ACTIVE_ENTITLEMENTS';
export const SET_GAMEPASS_ACTIVATING = 'metaverseGo/SET_GAMEPASS_ACTIVATING';
export const SET_OVERRIDE_ACTIVE_BOOSTER = 'metaverseGo/SET_OVERRIDE_ACTIVE_BOOSTER';
export const ACTIVATE_GAMEPASS_AND_BOOSTER = 'metaverseGo/ACTIVATE_GAMEPASS_AND_BOOSTER';
export const ACTIVATE_MULTIPLE_SKUS = 'metaverseGo/ACTIVATE_MULTIPLE_SKUS';

export const GET_GAMEPASS_FLAG = 'metaverseGo/GET_GAMEPASS_FLAG';
export const SET_GAMEPASS_FLAG = 'metaverseGo/SET_GAMEPASS_FLAG';
export const SHOW_GAMEPASS_POPUP = 'metaverseGo/SHOW_GAMEPASS_POPUP';
export const SHOW_GAMEPASS_ACTIVATED = 'metaverseGo/SHOW_GAMEPASS_ACTIVATED';

export const GET_REFERRAL_LINK = 'metaverseGo/GET_REFERRAL_LINK';
export const SET_REFERRAL_LINK = 'metaverseGo/SET_REFERRAL_LINK';
export const SET_REFERRAL_CODE = 'metaverseGo/SET_REFERRAL_CODE';
export const SET_REFERRAL_SHARE_MESSAGE = 'metaverseGo/SET_REFERRAL_SHARE_MESSAGE';
export const GET_REFERRAL_HISTORY = 'metaverseGo/GET_REFERRAL_HISTORY';
export const SET_REFERRAL_HISTORY = 'metaverseGo/SET_REFERRAL_HISTORY';
export const INVITE_FRIEND = 'metaverseGo/INVITE_FRIEND';
export const SET_REFERRAL_INVITE = 'metaverseGo/SET_REFERRAL_INVITE';
export const GET_REFERRER_DETAILS = 'metaverseGo/GET_REFERRER_DETAILS';
export const SET_REFERRER_DETAILS = 'metaverseGo/SET_REFERRER_DETAILS';
export const SHOW_REFERRAL_AD_BANNER = 'metaverseGo/SHOW_REFERRAL_AD_BANNER';

export const GET_FLAGS = 'metaverseGo/GET_FLAGS'; 
export const SET_FLAGS = 'metaverseGo/SET_FLAGS';
export const UPDATE_FLAG = 'metaverseGo/UPDATE_FLAG';
export const SET_UPDATE_FLAG = 'metaverseGo/SET_UPDATE_FLAG';

export const GET_SCHOLARSHIP_PROMOS = 'metaverseGo/GET_SCHOLARSHIP_PROMOS';
export const SET_SCHOLARSHIP_PROMOS = 'metaverseGo/SET_SCHOLARSHIP_PROMOS';

export const SET_GAMEPASS_BETA_USER ='metaverseGo/SET_GAMEPASS_BETA_USER';
export const JOIN_BETA = 'metaverseGo/JOIN_BETA';
export const SET_JOIN_BETA_RESPONSE_SUCCESS = 'metaverseGo/SET_JOIN_BETA_RESPONSE_SUCCESS';
export const SET_JOIN_BETA_RESPONSE_ERROR = 'metaverseGo/SET_JOIN_BETA_RESPONSE_ERROR';
export const GET_BETA_APPLICATION_FORM = 'metaverseGo/GET_BETA_APPLICATION_FORM';
export const SET_BETA_APPLICATION_FORM = 'metaverseGo/SET_BETA_APPLICATION_FORM';
export const SUBMIT_BETA_APPLICATION_FORM = 'metaverseGo/SUBMIT_BETA_APPLICATION_FORM';
export const SET_BETA_APPLICATION_RESULT = 'metaverseGo/SET_BETA_APPLICATION_RESULT';

export const GET_HOME_PAGE_BANNERS = 'metaverseGo/GET_HOME_PAGE_BANNERS';
export const SET_HOME_PAGE_DESKTOP_BANNER = 'metaverseGo/SET_HOME_PAGE_DESKTOP_BANNER';
export const SET_HOME_PAGE_MOBILE_BANNER = 'metaverseGo/SET_HOME_PAGE_MOBILE_BANNER';
export const GET_WEN_LAMBO_PAGE_BANNERS = 'metaverseGo/GET_WEN_LAMBO_PAGE_BANNERS';
export const SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_LOGO = 'metaverseGo/SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_LOGO';
export const SET_WEN_LAMBO_PAGE_MOBILE_BANNER_LOGO = 'metaverseGo/SET_WEN_LAMBO_PAGE_MOBILE_BANNER_LOGO';
export const SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_BG = 'metaverseGo/SET_WEN_LAMBO_PAGE_DESKTOP_BANNER_BG';
export const SET_WEN_LAMBO_PAGE_MOBILE_BANNER_BG = 'metaverseGo/SET_WEN_LAMBO_PAGE_MOBILE_BANNER_BG';
export const SET_WEN_LAMBO_STW_DESKTOP_BANNER = 'metaverseGo/SET_WEN_LAMBO_STW_DESKTOP_BANNER';
export const SET_WEN_LAMBO_STW_MOBILE_BANNER = 'metaverseGo/SET_WEN_LAMBO_STW_MOBILE_BANNER';

export const GET_FIRST_TIME_COMPLETION_REWARDS = 'metaverseGo/GET_FIRST_TIME_COMPLETION_REWARDS';
export const SET_FIRST_TIME_COMPLETION_REWARDS = 'metaverseGo/SET_FIRST_TIME_COMPLETION_REWARDS';
export const CLAIM_ACHIEVEMENT_REWARD = 'metaverseGo/CLAIM_ACHIEVEMENT_REWARD';
export const CLAIMED_ACHIEVEMENT_REWARD = 'metaverseGo/CLAIMED_ACHIEVEMENT_REWARD';

export const GET_REWARD_PARTNERS = 'metaverseGo/GET_REWARD_PARTNERS';
export const SET_REWARD_PARTNERS = 'metaverseGo/SET_REWARD_PARTNERS';

export const CLAIM_ITEM_VIA_VOUCHER = 'metaverseGo/CLAIM_ITEM_VIA_VOUCHER';
export const SET_ITEM_REDEMPTION_ERROR = 'metaverseGo/SET_ITEM_REDEMPTION_ERROR';
export const UTILIZE_CLAIMED_REWARD = 'metaverseGo/UTILIZE_CLAIMED_REWARD';
export const GET_REWARD_OTP = 'metaverseGo/GET_REWARD_OTP';

export const GET_WALLET_SIGN_MESSAGE = 'metaverseGo/GET_SIGN_MESSAGE';
export const SET_WALLET_SIGN_MESSAGE = 'metaverseGo/SET_SIGN_MESSAGE';
export const VERIFY_WALLET_SIGN_MESSAGE = 'metaverseGo/VERIFY_WALLET_SIGN_MESSAGE';
export const VERIFYING_WALLET_SIGN_MESSAGE = 'metaverseGo/VERIFYING_WALLET_SIGN_MESSAGE';
export const SET_USER_WALLET_ADDRESS = 'metaverseGo/SET_USER_WALLET_ADDRESS';
export const USER_WALLET_CONNECTED = 'metaverseGo/USER_WALLET_CONNECTED';
export const GET_CRYPTO_CURRENCIES = 'metaverseGo/GET_CRYPTO_CURRENCIES';
export const SET_CRYPTO_CURRENCIES = 'metaverseGo/SET_CRYPTO_CURRENCIES';
