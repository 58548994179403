import { put, takeLatest, call, all, select, delay } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import Cookies from 'js-cookie';
import {
  requestRewards,
  requestRewardDetails,
  requestRedeemReward,
  requestFeaturedRewards,
  requestModalNotification,
  requestDailyRewards,
  requestClaimDailyRewards,
  requestFirstTimeCompletionRewards,
  requestClaimAchievementReward,
  requestRewardPartners
} from '../api/rewards';
import {
  setLoading,
  setRewards,
  setRewardDetails,
  setFeaturedRewards,
  setRewardIsClaimed,
  setModalNotifications,
  setDailyRewards,
  setClaimedDailyRewards,
  claimDailyRewardsIsLoading,
  setModalNotificationsIsLoading,
  setFirstTimeCompletionRewards,
  claimedAchievementReward,
  getFirstTimeCompletionRewards,
  getUserProfile,
  setRewardPartners,
  failedRequests,
  getRewardOTP,
  showRewardOTPForm,
  setDisplayToast
} from '../actions';
import {
  CLAIM_DAILY_REWARDS,
  GET_DAILY_REWARDS,
  GET_FEATURED_REWARDS,
  GET_MODAL_NOTIFICATIONS,
  GET_REWARDS,
  GET_REWARD_DETAILS,
  REDEEM_REWARD,
  GET_FIRST_TIME_COMPLETION_REWARDS,
  CLAIM_ACHIEVEMENT_REWARD,
  GET_REWARD_PARTNERS,
  CONFIRM_REWARD_REDEMPTION
} from '../actions/constants';
import { 
  rewardDetailsSelector, 
  rewardsListSelector 
} from '../selectors';
import {
  answerFormats,
  duration,
  firebaseEvents,
  modalNotificationsBodyKind,
  responseStatus,
  storageKeys,
  wenLamboIds
} from '../constants';
import { logFirebaseEventWithTimestamp } from '../utils/logFirebaseEvent';

export const getRewardsSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestRewards, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setRewards(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getFeaturedRewardsSaga = function* () {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestFeaturedRewards, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setFeaturedRewards(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getRewardDetailsSaga = function* (payload) {
  try {
    const rewardsList = yield select(rewardsListSelector);
    if (rewardsList && !isEmpty(rewardsList)) {
      yield call(getRewardSaga, payload.rewardId);
    } else {
      const sessionToken = localStorage.getItem(storageKeys.sessionToken);
      const response = yield call(requestRewards, sessionToken);
      if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
        yield put(setRewards(response.data.d));
        yield call(getRewardSaga, payload.rewardId);
      }
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getRewardSaga = function* (rewardId) {
  try {
    const rewardsList = yield select(rewardsListSelector);
    const selectedReward = rewardsList.find(r => r.id === rewardId);
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestRewardDetails, sessionToken, selectedReward.id);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      let rewardDetails = response.data.d;
      if (response.data.d.claimForm) {
        const claimForm = response.data.d.claimForm.map(f => {
          const field = pick(f, Object.keys(f).filter(k => (
            k !== 'title' && k !== 'isRequired'
          )));
          return {
            ...field,
            caption: f.title,
            required: f.isRequired,
            answerFormat: answerFormats.textField
          };
        });
        rewardDetails = {
          ...response.data.d,
          claimForm
        };
      }
      yield put(setRewardDetails(rewardDetails));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const redeemRewardSaga = function* (payload) {
  try {
    const rewardDetails = yield select(rewardDetailsSelector);
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestRedeemReward, sessionToken, payload.payload);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const updatedRewardDetails = {
        ...rewardDetails,
        ...response.data.d
      };
      yield put(setRewardDetails(updatedRewardDetails));
      yield put(setRewardIsClaimed(true));
      yield put(showRewardOTPForm(false));
      const toast = {
        result: true,
        title: 'Success',
        message: response.data.message
      };
      yield delay(duration.pointOneSecond);
      yield put(setDisplayToast(toast));
      Cookies.remove(storageKeys.isNewlyRegistered);
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setRewardIsClaimed(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
    yield put(setLoading(false));
  }
}

const getModalNotificationsSaga = function* (payload) {
  yield put(setModalNotificationsIsLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestModalNotification, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setModalNotifications(response.data.d));
    }
    yield put(setLoading(false));
    yield put(setModalNotificationsIsLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setModalNotificationsIsLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getDailyRewardsSaga = function* () {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestDailyRewards, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setDailyRewards(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const claimDailyRewardsSaga = function* () {
  try {
    yield put(claimDailyRewardsIsLoading(true));
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestClaimDailyRewards, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setClaimedDailyRewards(response.data.d));
      yield put(claimDailyRewardsIsLoading(false));
      Cookies.remove(storageKeys.isDailyRewardsShown);
      for (const item of response.data.d) {
        if (item.kind === modalNotificationsBodyKind.text) {
          logFirebaseEventWithTimestamp(
            firebaseEvents.wenLamboDailyRewardsClaimed, {
              reward: item.value
          });
        }
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(claimDailyRewardsIsLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getFirstTimeCompletionRewardsSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestFirstTimeCompletionRewards, sessionToken, payload.rewardKey);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setFirstTimeCompletionRewards(response.data.d.items));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const claimAchievementRewardSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestClaimAchievementReward, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(getFirstTimeCompletionRewards(wenLamboIds.onBoarding));
      yield put(claimedAchievementReward(true));
      yield put(getUserProfile());
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(claimedAchievementReward(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getRewardPartnersSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestRewardPartners, payload?.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setRewardPartners(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const confirmRewardRedemptionSaga = function* (payload) {
  try {
    const { mobileNumber, id } = payload?.payload || {};
    const otpObj = { mobnum: mobileNumber, id };
    yield put(setLoading(true));
    yield put(getRewardOTP(otpObj));
  } catch (e) {}
}

export default function* rewardsSaga() {
  yield all([
    takeLatest(GET_REWARDS, getRewardsSaga),
    takeLatest(GET_FEATURED_REWARDS, getFeaturedRewardsSaga),
    takeLatest(GET_REWARD_DETAILS, getRewardDetailsSaga),
    takeLatest(REDEEM_REWARD, redeemRewardSaga),
    takeLatest(GET_MODAL_NOTIFICATIONS, getModalNotificationsSaga),
    takeLatest(GET_DAILY_REWARDS, getDailyRewardsSaga),
    takeLatest(CLAIM_DAILY_REWARDS, claimDailyRewardsSaga),
    takeLatest(GET_FIRST_TIME_COMPLETION_REWARDS, getFirstTimeCompletionRewardsSaga),
    takeLatest(CLAIM_ACHIEVEMENT_REWARD, claimAchievementRewardSaga),
    takeLatest(GET_REWARD_PARTNERS, getRewardPartnersSaga),
    takeLatest(CONFIRM_REWARD_REDEMPTION, confirmRewardRedemptionSaga)
  ]);
}