import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import Cookies from 'js-cookie';
import { 
  LayoutContent, 
  PageTitle, 
  Section 
} from '../Components';
import { GamePassPlayWebGL } from './GamePassPlayWebGL';
import { 
  getGamePassTournamentToken,
  retryGamePassTournamentToken,
  getGamePassTournamentsDetails, 
  getOnboardingTutorials, 
  setGamePassTournamentsDetails, 
  setHideFooter, 
  setHideHeader, 
  setHideMenuIcon, 
  showGamePassActivated,
  setOnboardingTutorials,
  getGamePassInventory
} from '../../actions';
import { 
  defaultGameUrl,
  firebaseEvents, 
  gameOverAdditionalParams, 
  gameOverParams, 
  minWidths, 
  onBoardingData, 
  storageKeys 
} from '../../constants';
import { 
  gamePassTournamentTokenSelector,
  gamePassTournamentsDetailsSelector, 
  guestSelector, 
  onboardingTutorialSelector, 
  remoteConfigKeysSelector,
  showGamePassActivatedSelector,
  userProfileSelector
} from '../../selectors';
import { useQuery } from '../../hooks/useQuery';
import { useWebGLDetector } from '../../hooks/useWebGLDetector';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import { Snackbars } from '../Commons';
import './GamePassPlay.scss';

export const GamePassPlay = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [gameUrl, setGameUrl] = useState('');
  const [gameOverUrl, setGameOverUrl] = useState('');
  const [message, setMessage] = useState(null);

  const gameDetails = useSelector(state => gamePassTournamentsDetailsSelector(state));
  const onboardingData = useSelector(state => onboardingTutorialSelector(state));
  const testKeys = useSelector(state => remoteConfigKeysSelector(state));
  const userProfile = useSelector(state => userProfileSelector(state));
  const tournament = useSelector(state => gamePassTournamentTokenSelector(state));
  const showActivated = useSelector(state => showGamePassActivatedSelector(state));
  const isGuest = useSelector(state => guestSelector(state));
  
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });
  const isBrowserSupportsWebGL = useWebGLDetector();

  const gameName = window.location.pathname.split('/').slice(1)[1];
  const gameId = window.location.pathname.split('/').slice(1)[2];
  const sessionToken = localStorage.getItem(storageKeys.sessionToken);
  const isTutorial = localStorage.getItem(storageKeys.isTutorial);

  const testGoldBonanzaCurrentUTC = query.get('testGoldBonanzaCurrentUTC');

  const backToPreviousPage = (e) => {
    e.preventDefault();
    if (isTutorial) {
      navigate('/gamepass');
    } else {
      navigate(-1);
    }
    logFirebaseEventWithTimestamp(
      firebaseEvents.wenLamboQuit, {
        game_name: gameDetails.gameName
      });
  }

  const hideSnackbar = () => {
    dispatch(showGamePassActivated({ result: false, shouldNotShow: true }));
  }
  
  useEffect(() => {
    if (!isDesktop) {
      dispatch(setHideFooter(true));
      dispatch(setHideHeader(true));
      dispatch(setHideMenuIcon(true));
    } else {
      dispatch(setHideFooter(true));
    }
    
    return () => {
      dispatch(setHideFooter(false));
      dispatch(setHideHeader(false));
      dispatch(setHideMenuIcon(false));
    }
  }, [dispatch, isDesktop])

  useEffect(() => {
    Cookies.remove(storageKeys.isNewUser);
  }, [])

  useEffect(() => {
    const initialPayload = { id: gameId };
    let payload = initialPayload;
    if (testGoldBonanzaCurrentUTC && testGoldBonanzaCurrentUTC !== '') {
      payload = {
        ...initialPayload,
        testGoldBonanzaCurrentUTC
      };
    }
    localStorage.removeItem(storageKeys.previousRoute);
    dispatch(getGamePassTournamentToken(payload));
  }, [dispatch, gameId, testGoldBonanzaCurrentUTC])
  
  useEffect(() => {
    if (isTutorial && gameName === onBoardingData.selectedGame) dispatch(getOnboardingTutorials());
    dispatch(getGamePassTournamentsDetails(gameId));
  }, [dispatch, gameId, isTutorial, gameName])

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem(storageKeys.isTutorial);
      Cookies.remove(storageKeys.isLoggedInTutorial);
      Cookies.remove(storageKeys.isNewUser);
      if (isTutorial) {
        Cookies.set(storageKeys.isFreeGamePassModalShown, true);
      }
    };


    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(gameDetails)) {
      let event = firebaseEvents.wenLamboMainPlayFailed;
      if (isBrowserSupportsWebGL) {
        event = firebaseEvents.wenLamboPlaying;
      }
      logFirebaseEventWithTimestamp(
        event, {
          game_name: gameDetails.gameName
        });
    }
  }, [gameDetails, isBrowserSupportsWebGL])

  useEffect(() => {
    return () => {
      dispatch(setGamePassTournamentsDetails({}));
      dispatch(setOnboardingTutorials());
      if (isTutorial) {
        Cookies.set(storageKeys.isFreeGamePassModalShown, true);
        dispatch(getGamePassInventory());
      }
    };
  }, [dispatch])

  useEffect(() => {
    if (searchParams.has(storageKeys.showSpinModal)) {
      Cookies.set(storageKeys.showSpinModal, true);
      searchParams.delete(storageKeys.showSpinModal);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    let url = '';
    let onboardingQuery = '';
  
    if (!tournament?.result) return;
    
    // main game iframe
    url = gameDetails?.launchUrl;

    // onboarding iframe
    if (!isEmpty(onboardingData)) {
      onboardingQuery = `&isTutorial=yes`;
      url = onboardingData[0]?.data?.gameUrl;
    }

    // test game iframe
    if (testKeys?.strippedStwEnabled &&
      testKeys?.strippedStwUsers.includes(userProfile?.uid) &&
      location?.state?.data?.url) {
      url = location?.state?.data?.url;
    }

    const sessionTokenVal = !sessionToken ? '' : sessionToken;
    const newUrl = `${url}&sessionToken=${sessionTokenVal}&tournamentToken=${tournament?.token}&tournamentSlug=${gameDetails?.slug}${onboardingQuery}`;
    setGameUrl(newUrl);

    return () => {
      setGameUrl(''); 
    }
  }, [dispatch, gameDetails, tournament, sessionToken, testKeys, userProfile, onboardingData, location])

  useEffect(() => {
    // Game Over screen iframe
    if (!isEmpty(message) && isArray(message)) {
      let queryParams = {};

      message.forEach((value, index) => {
        queryParams[gameOverParams[index]] = value;
      });
      
      const isUser = isGuest ? 0 : 1;
      queryParams[gameOverAdditionalParams.isUser] = isUser;

      queryParams[gameOverAdditionalParams.tournamentSlug] = gameDetails?.slug
      
      const searchParams = new URLSearchParams(queryParams);

      let gameOverUrl = '';
      // from Firebase remote config
      if (location?.state?.data?.gameOverUrl) {
        gameOverUrl = new URL(location?.state?.data?.gameOverUrl);
      }
      // from API
      if (!location?.state?.data?.gameOverUrl &&
        gameDetails?.gameOverUrl) {
        gameOverUrl = new URL(gameDetails?.gameOverUrl);
      }
      // set gameOverUrl if not empty
      if (gameOverUrl !== '') {
        gameOverUrl.search = searchParams.toString();
        setGameOverUrl(gameOverUrl);
      }
    }

    // return to game
    if (message === 1) {
      dispatch(retryGamePassTournamentToken(tournament?.token));
      setGameOverUrl('');
      setGameUrl('');
      setMessage(null);
    }
  }, [dispatch, message, gameDetails, tournament, isGuest])

  const handlePostMessage = (e) => {
    if (e.origin === defaultGameUrl) {
      setMessage(e.data);
    }
  }

  useEffect(() => {
    window.addEventListener('message', (e) => {
      handlePostMessage(e)
    });
    return () => window.removeEventListener('message', handlePostMessage);
  }, [])

  const iframeKey = (!isEmpty(message) && isArray(message)) ? message?.join('') : gameDetails?.id;
  
  return (  
    <LayoutContent innerClassName="gamepass-play-inner">
      {(!isEmpty(gameDetails) ||
        !isEmpty(onboardingData)) &&
        <>
          <PageTitle title={gameDetails.gameName} />
          <Section
            backButtonRoute
            backRoute={backToPreviousPage}
            sectionClassName="gamepass-play-section"
            sectionHeaderClassName="gamepass-play-section-header"
            title={gameDetails.gameName}
            titleCentered
            hidden
            text="Back"
          >
            {isBrowserSupportsWebGL ? 
              <div className="gamepass-play-container">
                {gameOverUrl ?
                  <iframe
                    key={`GameOver_${iframeKey}`}
                    src={gameOverUrl} 
                    allow="autoplay"
                    className="gamepass-play-iframe" 
                    title={gameName}
                  /> :
                  <iframe
                    key={`Game_${iframeKey}`}
                    src={gameUrl} 
                    allow="autoplay"
                    className="gamepass-play-iframe" 
                    title={gameName}
                  />}
              </div> : 
              <GamePassPlayWebGL />}
          </Section>
        </>}
        <Snackbars
          flag={showActivated?.result}
          title={showActivated?.title}
          hide={hideSnackbar}
          isGamePass
        />
    </LayoutContent>
  );
}
