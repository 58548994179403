export const musicFestData = [
  {
    title: "<h1>About G Music Fest</h1>",
    contents: ["Say G to your favorite local artists at GMusicFest! Enjoy jamming and get a chance to win prizes in partnership with MetaverseGo.\n\n"]
  },
  {
    title: "<h5>Date and Time</h5>",
    contents: [
      "September 17, 2022\n 1:00 PM\n\n"
    ]
  },
  {
    title: "<h5>Venue</h5>",
    contents: [
      "Globe Circuit Events Grounds\n Riverside Drive\nMakati, Metro Manila\n\n",
    ]
  },
  {
    contents: [
      "Remember to present your QR code to the on-ground marshall at the Gudi Express Lane once you enter the Concert Grounds.\n\n"
    ]
  },
  {
    contents: [
      "You won’t want to miss an evening of great music with our exciting line up of performers including: Phum Viphurit, Unique Salonga,The Juans, KAIA, Matthaios, Nik Makino, Lo Ki, I Belong To The Zoo, Lola Amour, Kaia, Ace Banzuelo, Arthur Miguel, Paul Pablo, and Jo3m. Be on the lookout for creative installations from Leeroy New & TRNZ.\n\n"
    ]
  },
  {
    contents: [
      "Please note that event artists and set times are subject to change without notice.\n\n"
    ]
  },
  {
    title: "<h1>How do I enter G Music Fest?</h1>",
    contents: [
      "You can get a FREE G Music Fest ticket by availing a Gudi NFT with Globe Rewards. Here’s how you can claim it: <ol><li>Go to Globe One App rewards catalog and claim your Gudi NFT through these simple steps: <ul><li>Download the New Globe One App and click on the “Rewards” tab</li><li>Select an offer</li><li>Click “Redeem”</li><li>Wait for the confirmation notification, along with a message from 4438 containing the unique voucher code</li></ul><li>After receiving your unique voucher code, please proceed to register for a MetaverseGo account by going to app.metaversego.gg:<ul><li>Register using your mobile and email</li><li>Navigate to the MetaverseGo Launchpad tab</li><li>Proceed to claim your Gudi NFT by copy-pasting the unique voucher code and tapping the ‘Redeem FREE NFT’</li><li>Congratulations on owning your first NFT! Post and share this moment with your friends.</li></ul><li>Once you’ve claimed your Gudi NFT, you will now have access to GMusic Fest by claiming your ticket via the QR code. Here’s how you may access the QR Code:</li><ul><li>Click the GMusic Fest banner in the Gudi NFT collection page to see the G Music Fest event page</li><li>You will be directed to see the G Music Fest e-ticket, click ‘Proceed’</li><li>The QR code will appear, which you will show to the on-ground marshall at designated Gudi Express lane upon entry to G Music Fest</li></ul></li></ol>",
    ]
  },

]