import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { activateGamePass } from '../../../actions';
import {
  buyGamePassCTA,
  defaultQuantity, 
  firebaseEvents, 
  gamePassActivateNowText, 
  gamePassBuyNowText, 
  onBoardingData,
  storageKeys
} from '../../../constants';
import { entitlementsSelector, gamePassInventorySelector } from '../../../selectors';
import { useQuery } from '../../../hooks';
import { logFirebaseEventWithTimestamp } from '../../../utils/logFirebaseEvent';
import './EmptyGamePass.scss';

const EmptyGamePass = ({ 
  imgUrl, 
  title, 
  items, 
  kind, 
  compact, 
  isTutorial, 
  shouldRedirectToGamePassGamesPage 
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [selectedItem, setSelectedItem] = useState({});
  
  const entitlements = useSelector(state => entitlementsSelector(state));
  const inventory = useSelector(state => gamePassInventorySelector(state));

  const tutorialQuery = localStorage.getItem(storageKeys.isTutorial);
  const onboardQuery = query.get(onBoardingData.onboard);

  const handleBuyGamePass = () => {
    if (isEmpty(entitlements?.gamePass) && !isEmpty(selectedItem)) {
      const activateData = {
        sku: selectedItem.subKind,
        quantity: defaultQuantity,
        kind,
        shouldRedirectToGamePassGamesPage,
        navigate,
      }
      dispatch(activateGamePass(activateData));
      if (searchParams.has(onBoardingData.onboard)) {
        searchParams.delete(onBoardingData.onboard);
        searchParams.set(onBoardingData.onboard, onBoardingData.flags.modal);
        setSearchParams(searchParams);
        logFirebaseEventWithTimestamp(firebaseEvents.wenLamboOnboardingSignup);
      }
    } else {
      window.location.href = buyGamePassCTA;
    }
    logFirebaseEventWithTimestamp(
      firebaseEvents.inventoryGamePassBuy, {
        page_url: window.location.pathname
      });
  }

  useEffect(() => {
    if (isEmpty(items)) return;
    const highestDuration = items.reduce((prev, current) => {
      return prev.durationInMs > current.durationInMs ? prev : current;
    });
    setSelectedItem(highestDuration);
  }, [items])

  let newImage = imgUrl;
  let buttonText = gamePassBuyNowText;
  if (isEmpty(entitlements?.gamePass) && 
    !isEmpty(selectedItem)) {
    newImage = selectedItem?.imageUrls?.imageUrl;
    buttonText = gamePassActivateNowText;
  }

  const tutorialId = isTutorial && 'gamepass-tutorial';
  
  return (
    <div id={tutorialId} className="empty-game-pass">
      <div 
        className={classNames(
          "empty-game-pass-inner", {
            "empty-game-pass-inner-compact": compact, 
            "empty-game-pass-inner-compact-tutorial": isTutorial && 
              tutorialQuery && 
              onboardQuery === onBoardingData.flags.activate
          }
        )}
      >
        <div 
          className={classNames(
            "empty-game-pass-ticket", {
              "empty-game-pass-ticket-compact": compact,
              "empty-game-pass-ticket-grayscale": !isEmpty(selectedItem)
            }
          )}
        >
          <img
            src={newImage}
            className={classNames(
              "empty-game-pass-ticket-image", {
                "empty-game-pass-ticket-image-compact": compact,
                "empty-game-pass-ticket-image-grayscale": !isEmpty(selectedItem)
              }
            )}
            alt="empty-game-pass-active"
          />
        </div>
        <div className={classNames(
          "empty-game-pass-empty",
          {"empty-game-pass-empty-compact": compact}
        )}>
          <div className={classNames(
            "empty-game-pass-empty-text",
            {"empty-game-pass-empty-text-compact": compact}
          )}>
            You have no Active {title}
          </div>
          <button
            className={classNames(
              "empty-game-pass-buy-button", {
                "empty-game-pass-buy-button-compact": compact,
                "empty-game-pass-buy-button-compact-tutorial": isTutorial && 
                  tutorialQuery && 
                  onboardQuery === onBoardingData.flags.activate &&
                  !isEmpty(inventory)
              }
            )}
            onClick={handleBuyGamePass}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmptyGamePass;