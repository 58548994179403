import { all, call, put, takeLatest } from 'redux-saga/effects';
import { requestCryptoCurrencies, requestVerifySignMessage, requestWalletSignMessage } from '../api/crypto';
import { GET_CRYPTO_CURRENCIES, GET_WALLET_SIGN_MESSAGE, VERIFY_WALLET_SIGN_MESSAGE } from '../actions/constants';
import { 
  failedRequests, 
  redirectToCheckoutPage, 
  setCryptoCurrencies, 
  setLoading, 
  setWalletSignMessage, 
  verifyingWalletSignMessage
} from '../actions';
import { 
  responseStatus, 
  storageKeys 
} from '../constants';

const getWalletSignMessageSaga = function* (payload) {
  const { disconnect, signMessage } = payload?.payload;
  yield put(verifyingWalletSignMessage(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestWalletSignMessage, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setWalletSignMessage(response.data.d?.signMessage));
      signMessage(response.data.d?.signMessage);
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      new Promise(resolve => {
        disconnect();
        resolve();
      });
      yield put(setWalletSignMessage(''));
      yield put(verifyingWalletSignMessage(false));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const verifyWalletSignMessageSaga = function* (payload) {
  const { disconnect, signature, address, chainId, type } = payload?.payload;
  const verifyObj = { 
    signature, 
    address, 
    chainId, 
    type 
  };
  yield put(verifyingWalletSignMessage(true));
  yield put(setWalletSignMessage(''));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestVerifySignMessage, verifyObj, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(verifyingWalletSignMessage(false));
      yield put(redirectToCheckoutPage(true));
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      new Promise(resolve => {
        disconnect();
        resolve();
      });
      yield put(verifyingWalletSignMessage(false));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getCryptoCurrenciesSaga = function* (payload) {
  const { intent, chainId } = payload.payload || '';
  const chain = { chainId };
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestCryptoCurrencies, intent, chain, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setCryptoCurrencies(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setCryptoCurrencies([]));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* cryptoSaga() {
  yield all([
    takeLatest(GET_WALLET_SIGN_MESSAGE, getWalletSignMessageSaga),
    takeLatest(VERIFY_WALLET_SIGN_MESSAGE, verifyWalletSignMessageSaga),
    takeLatest(GET_CRYPTO_CURRENCIES, getCryptoCurrenciesSaga)
  ]);
}