import {
  SET_REWARDS,
  SET_REWARD_DETAILS,
  SET_DISPLAY_TOAST,
  SET_FEATURED_REWARDS,
  SET_REWARD_IS_CLAIMED,
  SET_MODAL_NOTIFICATIONS,
  SET_DAILY_REWARDS,
  SET_CLAIMED_DAILY_REWARDS,
  CLAIM_DAILY_REWARDS_IS_LOADING,
  SET_MODAL_NOTIFICATIONS_IS_LOADING,
  SET_FIRST_TIME_COMPLETION_REWARDS,
  CLAIMED_ACHIEVEMENT_REWARD,
  SET_REWARD_PARTNERS,
  RESET_STATE,
  SHOW_REWARD_OTP_FORM
} from '../actions/constants';

const rewardsState = {
  list: [],
  featured: [],
  details: {},
  isClaimed: false,
  toast: undefined,
  notifications: [],
  dailyRewards: [],
  claimedRewards: [],
  isLoading: false,
  notificationsIsLoading: false,
  firstTimeCompletionRewards: [],
  isClaimedAchievementReward: false,
  rewardPartners: {},
  isOTPFormVisible: false
};

const rewardsReducer = (state = rewardsState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return rewardsState;
    case SET_REWARDS:
      return {
        ...state,
        list: action.rewardsList
      };
    case SET_FEATURED_REWARDS:
      return {
        ...state,
        featured: action.featuredRewards
      };
    case SET_REWARD_DETAILS:
      return {
        ...state,
        details: action.rewardDetails
      };
    case SET_REWARD_IS_CLAIMED:
      return {
        ...state,
        isClaimed: action.isClaimed
      };
    case SET_DISPLAY_TOAST:
      return {
        ...state,
        toast: action.toast
      };
    case SET_MODAL_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications
      };
    case SET_MODAL_NOTIFICATIONS_IS_LOADING:
      return {
        ...state,
        notificationsIsLoading: action.notificationsIsLoading
      };
    case SET_DAILY_REWARDS:
      return {
        ...state,
        dailyRewards: action.dailyRewards
      };
    case SET_CLAIMED_DAILY_REWARDS:
      return {
        ...state,
        claimedRewards: action.claimedRewards
      };
    case CLAIM_DAILY_REWARDS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case SET_FIRST_TIME_COMPLETION_REWARDS:
      return {
        ...state,
        firstTimeCompletionRewards: action.firstTimeCompletionRewards
      };
    case CLAIMED_ACHIEVEMENT_REWARD:
      return {
        ...state,
        isClaimedAchievementReward: action.isClaimedAchievementReward
      };
    case SET_REWARD_PARTNERS:
      return {
        ...state,
        rewardPartners: action.rewardPartners
      };
    case SHOW_REWARD_OTP_FORM:
      return {
        ...state,
        isOTPFormVisible: action.isOTPFormVisible
      };
    default:
      return state;
  }
}

export default rewardsReducer;