import React from 'react';
import { DateTime } from 'luxon';
import './CompactActiveGamePass.scss';

const CompactActiveGamePass = ({ imageUrls, title, countdownVal }) => {
  return (
    <div id='gamepass-tutorial' className="compact-active-game-pass">
      <div className="compact-active-game-pass-inner">
        <div className="compact-active-game-pass-tickets">
          <img
            src={imageUrls[imageUrls.length - 1]}
            className="compact-active-game-pass-ticket"
            alt="compact-active-game-pass-active"
          />
        </div>
        <div className="compact-active-game-pass-timer">
          <div className="compact-active-game-pass-timer-title">{title} Timer</div>
          <div className="compact-active-game-pass-timer-duration">{countdownVal}</div>
        </div>
      </div>
    </div>
  );
}

export default CompactActiveGamePass;