import { createSelector } from 'reselect';
import filterByDataset from '../utils/filterByDataset';

export const layoutSelector = (state) => state.layout;
export const pinSelector = (state) => state.pin;
export const loginSelector = (state) => state.login;
export const homeCollectionSelector = (state) => state.home;
export const gamesSelector = (state) => state.games;
export const nftSelector = (state) => state.nft;
export const launchpadSelector = (state) => state.launchpad;
export const userCollectionsSelector = (state) => state.collections;
export const userCommunitiesSelector = (state) => state.communities;
export const spendSelector = (state) => state.spend;
export const chatSelector = (state) => state.chat;
export const tutorialSelector = (state) => state.tutorial;
export const homeTutorialSelector = (state) => state.homeTutorial;
export const profileSelector = (state) => state.profile;
export const questsSelector = (state) => state.quests;
export const redeemSelector = (state) => state.redeem;
export const spinTheWheelSelector = (state) => state.spinTheWheel;
export const topUpSelector = (state) => state.topup;
export const transactionSelector = (state) => state.transactions;
export const tournamentsSelector = (state) => state.tournaments;
export const withdrawalSelector = (state) => state.withdrawal;
export const rewardsSelector = (state) => state.rewards;
export const gamePassSelector = (state) => state.gamepass;
export const referralSelector = (state) => state.referral;
export const cartSelector = (state) => state.cart;
export const checkoutSelector = (state) => state.checkout;
export const scholarshipSelector = (state) => state.scholarship;
export const marketplaceSelector = (state) => state.marketplace;
export const flagsSelector = (state) => state.flags;
export const bannersSelector = (state) => state.banners;
export const vouchersSelector = (state) => state.vouchers;
export const cryptoSelector = (state) => state.crypto;

export const displayLoginToastSelector = createSelector(
  loginSelector,
  c => c.toast
);

export const hideHeaderSelector = createSelector(
  layoutSelector,
  c => c.hideHeader
);

export const hideMenuIconSelector = createSelector(
  layoutSelector,
  c => c.hideMenuIcon
);

export const hideFooterSelector = createSelector(
  layoutSelector,
  c => c.hideFooter
);

export const walkthroughHeightLimitFlagSelector = createSelector(
  layoutSelector,
  c => c.hasNoWalkthroughHeightLimit
);

export const remoteConfigKeysSelector = createSelector(
  layoutSelector,
  c => c.remoteConfigKeys
);

export const disableFooterPointerEventSelector = createSelector(
  layoutSelector,
  c => c.shouldDisable
);

export const authStepSelector = createSelector(
  loginSelector,
  c => c.authStep
);

export const sessionTokenSelector = createSelector(
  loginSelector,
  c => c.token
);

export const loginOTPTimerActiveSelector = createSelector(
  loginSelector,
  c => c.loginOTPTimerActive
);

export const emailLoginOTPTimerActiveSelector = createSelector(
  loginSelector,
  c => c.emailLoginOTPTimerActive
);

export const registrationFormVisibleSelector = createSelector(
  loginSelector,
  c => c.registrationFormVisible
);

export const loginErrorMessageSelector = createSelector(
  loginSelector,
  c => c.errorMessage
);

export const loginDataSelector = createSelector(
  loginSelector,
  c => c.loginData
);

export const isEmailAvailableSelector = createSelector(
  loginSelector,
  c => c.isEmailAvailable
);

export const isEmailOTPEnabledSelector = createSelector(
  loginSelector,
  c => c.isEmailOTPEnabled
);

export const pinAuthStepSelector = createSelector(
  pinSelector,
  p => p.authStep
);

export const pinOTPTimerActiveSelector = createSelector(
  pinSelector,
  p => p.pinOTPTimerActive
);

export const pinValidationErrorSelector = createSelector(
  pinSelector,
  p => p.hasValidationError
);

export const displaySetPINFormSelector = createSelector(
  pinSelector,
  p => p.setPINFormDisplayed
);

export const setSubmitNewPinSelector = createSelector(
  pinSelector,
  p => p.isSubmit
);

export const loadingSelector = createSelector(
  layoutSelector,
  l => l.loading
);

export const showLoadingSelector = createSelector(
  layoutSelector,
  l => l.showLoading
);

export const navigateDataSelector = createSelector(
  layoutSelector,
  l => l.navigateData
);

export const guestSelector = createSelector(
  layoutSelector,
  l => l.isGuest
);

export const pageContentsRenderedSelector = createSelector(
  layoutSelector,
  l => l.pageContentsRendered
);

export const orderSummaryVisibleSelector = createSelector(
  layoutSelector,
  l => l.orderSummaryVisible
);

export const menuItemsSelector = createSelector(
  layoutSelector,
  l => l.menuItems
);

export const sideMenuItemsSelector = createSelector(
  layoutSelector,
  l => l.sideMenuItems
);

export const sideMenuOtherItemsSelector = createSelector(
  layoutSelector,
  l => l.sideMenuOtherItems
);

export const userProfileSelector = createSelector(
  layoutSelector,
  l => l.userProfile
);

export const usernameSelector = createSelector(
  userProfileSelector,
  p => p && p.username
);

export const mobileNumberSelector = createSelector(
  userProfileSelector,
  p => p && p.mobnum
);

export const userProfileErrorStatusSelector = createSelector (
  layoutSelector,
  l => l.userProfileErrorStatus
);

export const unreadMessagesSelector = createSelector(
  layoutSelector,
  l => l.unreadMessages
);

export const activeModalSelector = createSelector(
  layoutSelector,
  l => l.activeModal
);

export const walletSelector = createSelector(
  userProfileSelector,
  u => u && u.wallet
);

export const walletCryptoSelector = createSelector(
  walletSelector,
  w => w && w.crypto
);

export const userEntitlementsSelector = createSelector(
  userProfileSelector,
  u => u && u.entitlements
);

export const profileReferralSelector = createSelector(
  userProfileSelector,
  u => u && u.referral
);

export const referralGoldEarningSelector = createSelector(
  profileReferralSelector,
  r => r && r.goldEarning
);

export const profileHeaderSelector = createSelector(
  userProfileSelector,
  u => u && u.profileHeader
);

export const profileHeaderUsernameSelector = createSelector(
  profileHeaderSelector,
  h => h && h.username
);

export const sessionExpiredSelector = createSelector(
  layoutSelector,
  l => l.sessionExpired
);

export const trendingDropsSelector = createSelector(
  homeCollectionSelector,
  h => filterByDataset(h, 'Trending Drops') || filterByDataset(h, 'NFTs')
);

export const topCollectionsSelector = createSelector(
  homeCollectionSelector,
  h => filterByDataset(h, 'Top Collections')
);

export const gameDetailsSelector = createSelector(
  gamesSelector,
  g => g.selected
);

export const nftCollectionDetailsSelector = createSelector(
  nftSelector,
  n => n.selected
);

export const nftItemDetailsSelector = createSelector(
  nftSelector,
  n => n.selectedItem
);

export const launchpadCollectionSelector = createSelector(
  launchpadSelector,
  l => l.collection
);

export const launchpadCollectionSelectorItem = createSelector(
  launchpadSelector,
  l => l.collectionItem
);

export const nftDataSelector = createSelector(
  launchpadSelector,
  l => l.nftData
);

export const mintingNFTSuccess = createSelector(
  launchpadSelector,
  l => l.isRedeem
);

export const prizeSlugSelector = createSelector(
  launchpadSelector,
  l => l.prizeSlug
);

export const prizeSlugDataSelector = createSelector(
  launchpadSelector,
  l => l.prizeSlugData
);

export const slugEnableButtonSelector = createSelector(
  launchpadSelector,
  l => l.slugEnableButton
);

export const canMintSelector = createSelector(
  prizeSlugDataSelector,
  p => p.canMint
);

export const claimedRewardSelector = createSelector(
  prizeSlugDataSelector,
  p => p.claimedReward
);

export const userCollectionListSelector = createSelector(
  userCollectionsSelector,
  c => c.list
);

export const userCollectionSelector = createSelector(
  userCollectionsSelector,
  c => c.selected
);

export const userCommunityListSelector = createSelector(
  userCommunitiesSelector,
  c => c.sections
);

export const guildSelector = createSelector(
  userCommunitiesSelector,
  c => c.selected
)

export const guildApplicationFormSelector = createSelector(
  userCommunitiesSelector,
  c => c.form
);

export const guildApplicationFormWeightSelector = createSelector(
  userCommunitiesSelector,
  c => c.totalWeight
);

export const guildSubmitApplicationFormSelector = createSelector(
  userCommunitiesSelector
);

export const displayGuildApplicationFormToastSelector = createSelector(
  userCommunitiesSelector,
  c => c.toast
);

export const spendMobileLoadProductsSelector = createSelector(
  spendSelector,
  s => s.mobileLoadProducts
);

export const spendMobileLoadProductConfirmationSelector = createSelector(
  spendSelector,
  s => s.confirmation
);

export const spendCountrySelector = createSelector(
  spendSelector,
  s => s.country
);

export const spendPaymentStepSelector = createSelector(
  spendSelector,
  s => s.paymentStep
);

export const spendLoadingSelector = createSelector(
  spendSelector,
  s => s.isLoading
);

export const spendErrorSelector = createSelector(
  spendSelector,
  s => s.isError
);

export const spendSuccessSelector = createSelector(
  spendSelector,
  s => s.isSuccess
);

export const spendLoadingTextSelector = createSelector(
  spendSelector,
  s => s.loadingText
);

export const chatMessagesSelector = createSelector(
  chatSelector,
  c => c.messages
);

export const chatMessageThreadSelector = createSelector(
  chatSelector,
  c => c.thread
);

export const tutorialScreensSelector = createSelector(
  tutorialSelector,
  t => t.screens
);

export const onboardingTutorialSelector = createSelector(
  tutorialSelector,
  t => t.onboarding
);

export const homeTutorialStepSelector = createSelector(
  homeTutorialSelector,
  t => t.step
);

export const userProfileFieldsSelector = createSelector(
  profileSelector,
  p => p.fields
);

export const userProfileToastSelector = createSelector(
  profileSelector,
  p => p.toast
);

export const transactionsSelector = createSelector(
  profileSelector,
  p => p.transactions
);

export const currentPINSelector = createSelector(
  profileSelector,
  p => p.currentPIN
);

export const hasProfileTutorialSelector = createSelector(
  profileSelector,
  p => p.hasTutorial
);

export const userProfileRequestedSelector = createSelector(
  profileSelector,
  u => u && u.isRequested
);

export const questListSelector = createSelector(
  questsSelector,
  q => q.quests
);

export const questLeaderboardsSelector = createSelector(
  questsSelector,
  q => q.leaderboards
);

export const questFAQsSelector = createSelector(
  questsSelector,
  q => q.faqs
);

export const redeemFeaturedSelector = createSelector(
  redeemSelector,
  r => r.featuredPromotion
);

export const spinAvailableSelector = createSelector(
  spinTheWheelSelector,
  s => s.spinAvailable
);

export const spinPrizeSelector = createSelector(
  spinTheWheelSelector,
  s => s.prize
);

export const spinPrizesSelector = createSelector(
  spinTheWheelSelector,
  s => s.prizes
);

export const wenLamboSpinTheWheelDetailsSelector = createSelector(
  spinTheWheelSelector,
  s => s.wenLamboSpinTheWheelDetails
);

export const topUpPaymentSelector = createSelector(
  topUpSelector,
  s => s.payment
);

export const topUpBuyDataSelector = createSelector(
  topUpSelector,
  s => s.buyData
);

export const topUpCurrencySelector = createSelector(
  topUpSelector,
  s => s.currency
);

export const walletCurrencyConversionSelector = createSelector(
  topUpSelector,
  s => s.walletCurrency
);

export const connectFromTopUpSelector = createSelector(
  topUpSelector,
  s => s.isFromTopUp
);

export const topUpCurrencyBatchSelector = createSelector(
  topUpSelector,
  s => s.currencyBatch
);

export const topUpIsLoadingSelector = createSelector(
  topUpSelector,
  s => s.isLoading
);

export const topUpChannelsSelector = createSelector(
  topUpSelector,
  s => s.channels
);

export const userTransactionsSelector = createSelector(
  transactionSelector,
  c => c.transactions
);

export const transactionOpenTopUpSelector = createSelector(
  transactionSelector,
  t => t.isTopUpVisible
);

export const transactionBarcodeSelector = createSelector(
  transactionSelector,
  t => t.isBarcodeVisible
);

export const transactionDataSelector= createSelector(
  transactionSelector,
  t => t.data
);

export const transactionSelectedMethodSelector = createSelector(
  transactionSelector,
  t => t.selectedMethod
);

export const transactionActiveTabSelector = createSelector(
  transactionSelector,
  t => t.activeTab
);

export const transactionVisibleSelector = createSelector(
  transactionSelector,
  t => t.isTransactionVisible
);

export const transactionSizeSelector = createSelector(
  transactionSelector,
  t => t.size
);

export const tournamentsListSelector = createSelector(
  tournamentsSelector,
  t => t.tournaments
);

export const selectedNFTSelector = createSelector(
  withdrawalSelector,
  w => w.selectedNft
);

export const validatedNFTSelector = createSelector(
  withdrawalSelector,
  w => w.validatedNft
);

export const rewardsListSelector = createSelector(
  rewardsSelector,
  r => r.list
);

export const rewardDetailsSelector = createSelector(
  rewardsSelector,
  r => r.details
);

export const rewardsFeaturedSelector = createSelector(
  rewardsSelector,
  r => r.featured
);

export const rewardIsClaimedSelector = createSelector(
  rewardsSelector,
  r => r.isClaimed
);

export const rewardDetailsToastSelector = createSelector(
  rewardsSelector,
  r => r.toast
);

export const modalNotificationsSelector = createSelector(
  rewardsSelector,
  r => r.notifications
);

export const modalNotificationsIsLoadingSelector = createSelector(
  rewardsSelector,
  r => r.notificationsIsLoading
);

export const dailyRewardsSelector = createSelector(
  rewardsSelector,
  r => r.dailyRewards
);

export const claimedRewardsSelector = createSelector(
  rewardsSelector,
  r => r.claimedRewards
);

export const claimDailyRewardsIsLoadingSelector = createSelector(
  rewardsSelector,
  r => r.isLoading
);

export const firstTimeCompletionRewardsSelector = createSelector(
  rewardsSelector,
  r => r.firstTimeCompletionRewards
);

export const claimedAchievementRewardSelector = createSelector(
  rewardsSelector,
  r => r.isClaimedAchievementReward
);

export const rewardPartnersSelector = createSelector(
  rewardsSelector,
  r => r.rewardPartners
);

export const rewardOTPFormVisibleSelector = createSelector(
  rewardsSelector,
  r => r.isOTPFormVisible
);

export const gamePassScreenSelector = createSelector(
  gamePassSelector,
  g => g.sections
);

export const gamePassInventorySelector = createSelector(
  gamePassSelector,
  g => g.inventory
);

export const gamePassInventoryActivateSelector = createSelector(
  gamePassSelector,
  g => g.activateDetails
);

export const gamePassSkuDetailsSelector = createSelector(
  gamePassSelector,
  g => g.skuDetails
);

export const isGamePassTermsAndConditionsShownSelector = createSelector(
  gamePassSelector,
  g => g.isGamePassTermsAndConditionsShown
);

export const gamePassTournamentsSelector = createSelector(
  gamePassSelector,
  g => g.tournaments
);

export const gamePassTournamentsDetailsSelector = createSelector(
  gamePassSelector,
  g => g.tournamentsDetails
);

export const gamePassTournamentTokenSelector = createSelector(
  gamePassSelector,
  g => g.tournament
);

export const gamePassTransactionIsFoundSelector = createSelector(
  gamePassSelector,
  g => g.isFound
);

export const gamePassTransactionIsInTimeLimitSelector= createSelector(
  gamePassSelector,
  g => g.isInTimeLimit
);

export const gamePassPlayGameSelector = createSelector(
  gamePassSelector,
  g => g.openPopup
);

export const gamePassProductsSelector = createSelector(
  gamePassSelector,
  g => g.products
);

export const entitlementsSelector = createSelector(
  gamePassSelector,
  g => g.entitlements
);

export const betaUserSelector = createSelector(
  gamePassSelector,
  g => g.betaUser
);

export const showGamePassActivatedSelector = createSelector(
  gamePassSelector,
  g => g.showActivated
);

export const gamePassToastSelector = createSelector(
  gamePassSelector,
  g => g.toast
);

export const betaResponseSelector = createSelector(
  gamePassSelector,
  g => g.betaResponse
);

export const betaResponseSuccessSelector = createSelector(
  betaResponseSelector,
  b => b && b.success
);

export const betaResponseErrorSelector = createSelector(
  betaResponseSelector,
  b => b && b.error
);

export const betaApplicationFormSelector = createSelector(
  gamePassSelector,
  g => g.betaApplicationForm
);

export const betaApplicationResultSelector = createSelector(
  gamePassSelector,
  g => g.betaApplicationResult
);

export const gamePassActivatingSelector = createSelector(
  gamePassSelector,
  g => g.isGamePassActivating
);

export const overrideActiveBoosterSelector = createSelector(
  gamePassSelector,
  g => g.overrideActiveBooster
);

export const gamePassAndBoosterIsActivatedSelector = createSelector(
  gamePassSelector,
  g => g.isGamePassAndBoosterActivated
);

export const showClaimFreeGamePassSelector = createSelector(
  gamePassSelector,
  g => g.showClaimFreeGamePass
);

export const referralLinkSelector = createSelector(
  referralSelector,
  r => r.link
);

export const referralCodeSelector = createSelector(
  referralSelector,
  r => r.code
);

export const referralShareMessageSelector = createSelector(
  referralSelector,
  r => r.shareMessage
);

export const referralHistorySelector = createSelector(
  referralSelector,
  r => r.history
);

export const referralToastSelector = createSelector(
  referralSelector,
  r => r.toast
);

export const referrerDetailsSelector = createSelector(
  referralSelector,
  r => r.referrer
);

export const referralAdBannerSelector = createSelector(
  referralSelector,
  r => r.openBanner
);

export const addedToCartSelector = createSelector(
  cartSelector,
  c => c.isAdded
);

export const cartItemsSelector = createSelector(
  cartSelector,
  c => c.items
);

export const updatedCartItemsSelector = createSelector(
  cartSelector,
  c => c.updatedItems
);

export const cartCountSelector = createSelector(
  cartSelector,
  c => c.count
);

export const checkoutContentsSelector = createSelector(
  checkoutSelector,
  c => c.contents
);

export const showGamePassCheckoutSelector = createSelector(
  checkoutSelector,
  c => c.showCheckout
);

export const gamePassCheckoutFormSelector = createSelector(
  checkoutSelector,
  c => c.checkoutForm
);

export const selectedPaymentMethodSelector = createSelector(
  checkoutContentsSelector,
  c => c.selectedPaymentMethod
);

export const checkoutPaySelector = createSelector(
  checkoutSelector,
  c => c.payDetails
);

export const checkoutRedirectBackSelector = createSelector(
  checkoutSelector,
  c => c.mustRedirectBack
);

export const checkoutAttemptOtp = createSelector(
  checkoutSelector,
  c => c.isAttemptOtp
);

export const checkoutTokenizedPaymentsSelector = createSelector(
  checkoutSelector,
  c => c.tokenizedPayments
);

export const checkoutTokenizedIsAddedSelector = createSelector(
  checkoutSelector,
  c => c.tokenizedIsAdded
);

export const showPaymentMethodsSelector = createSelector(
  checkoutSelector,
  c => c.showPaymentMethods
);

export const checkoutSelectCurrencySelector = createSelector(
  checkoutSelector,
  c => c.currency
);

export const disableCheckoutButtonSelector = createSelector(
  checkoutSelector,
  c => c.isDisabled
);

export const showCurrencyToastSelector = createSelector(
  checkoutSelector,
  c => c.showCurrency
);

export const checkoutSelectedPaymentMethodSelector = createSelector(
  checkoutSelector,
  c => c.selectedPaymentMethod
);

export const redirectToCheckoutPageSelector = createSelector(
  checkoutSelector,
  c => c.redirectToCheckout
);

export const scholarshipPromosSelector = createSelector(
  scholarshipSelector,
  s => s.promos
);

export const marketplaceCollectionSelector = createSelector(
  marketplaceSelector,
  m => m.collection
);

export const marketplaceCollectionsSelector = createSelector(
  marketplaceSelector,
  m => m.collections
);

export const marketplaceRenderingSelector = createSelector(
  marketplaceSelector,
  m => m.isRendering
);

export const nftCanListSelector = createSelector(
  marketplaceSelector,
  m => m.isList
);

export const nftCanBuySelector = createSelector(
  marketplaceSelector,
  m => m.isBuy
);

export const marketplaceOpenModalSelector = createSelector(
  marketplaceSelector,
  m => m.openModal
);

export const nftBuyRedirectToTopUpSelector = createSelector(
  marketplaceSelector,
  m => m.buyNavigate
);

export const cancelListRedirectBackSelector = createSelector(
  marketplaceSelector,
  m => m.cancelListBack
);

export const nftCollectionSelector = createSelector(
  marketplaceSelector,
  m => m.nftCollection
);

export const marketplaceDetailsSelector = createSelector(
  marketplaceSelector,
  m => m.details
);

export const listedNftsSelector = createSelector(
  marketplaceSelector,
  m => m.listedNfts
);

export const listedNftsPaginationSelector = createSelector(
  marketplaceSelector,
  m => m.listedNftsPagination
);

export const listedNftPaginationSelector = createSelector(
  marketplaceSelector,
  m => m.listedNftPagination
);

export const marketplacePaginationDataSelector = createSelector(
  marketplaceSelector,
  m => m.paginationData
);

export const marketplacePaginationDataHasNextPageSelector = createSelector(
  marketplacePaginationDataSelector,
  p => p && p.hasNextPage
);

export const marketplacePaginationDataPageSelector = createSelector(
  marketplacePaginationDataSelector,
  p => p && p.page
);

export const marketplacePaginationNftDataSelector = createSelector(
  marketplaceSelector,
  m => m.paginationNftData
);

export const marketplacePaginationNftDataHasNextPageSelector = createSelector(
  marketplacePaginationNftDataSelector,
  p => p && p.hasNextPage
);

export const marketplacePaginationNftDataPageSelector = createSelector(
  marketplacePaginationNftDataSelector,
  p => p && p.page
);

export const userProfileFlagsSelector = createSelector(
  userProfileSelector,
  u => u.flags
);

export const betaUserFlagSelector = createSelector(
  userProfileFlagsSelector,
  f => f?.isWenLamboBetaUser
);

export const dailyLoginRewardsClaimedSelector = createSelector(
  userProfileFlagsSelector,
  f => f?.isDailyLoginRewardsClaimed
);

export const freeGamePassSelector = createSelector(
  userProfileFlagsSelector,
  f => f?.isFreeGamePassModalShown
);

export const flagsDataSelector = createSelector(
  flagsSelector,
  f => f.flagsData
);

export const homePageBannersSelector = createSelector(
  bannersSelector,
  b => b.homePage
);

export const homePageFeaturedBannerSelector = createSelector(
  homePageBannersSelector,
  h => h && h.featured
);

export const wenLamboPageBannersSelector = createSelector(
  bannersSelector,
  b => b.wenLamboPage
);

export const wenLamboPageBannerLogoSelector = createSelector(
  wenLamboPageBannersSelector,
  w => w && w.logo
);

export const wenLamboPageBannerBGSelector = createSelector(
  wenLamboPageBannersSelector,
  w => w && w.bg
);

export const wenLamboSTWBannerSelector = createSelector(
  wenLamboPageBannersSelector,
  w => w && w.stw
);

export const itemRedemptionErrorSelector = createSelector(
  vouchersSelector,
  v => v.itemRedemptionError
);

export const vouchersToastSelector = createSelector(
  vouchersSelector,
  v => v.toast
);

export const walletSignMessageSelector = createSelector(
  cryptoSelector,
  c => c.signMessage
);

export const verifyingWalletSignMessageSelector = createSelector(
  cryptoSelector,
  c => c.isVerifying
);

export const userWalletAddressSelector = createSelector(
  cryptoSelector,
  c => c.walletAddress
);

export const userWalletConnectedSelector = createSelector(
  cryptoSelector,
  c => c.isConnected
);

export const cryptoCurrenciesSelector = createSelector(
  cryptoSelector,
  c => c.currencies
);