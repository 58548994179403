import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { activateGamePass, updateFlag } from '../../../actions';
import Cookies from 'js-cookie';
import { 
  activateKind, 
  defaultQuantity, 
  firebaseEvents, 
  flagKeys, 
  onBoardingData, 
  storageKeys 
} from '../../../constants';
import { logFirebaseEventWithTimestamp } from '../../../utils/logFirebaseEvent';
import game from '../../../assets/onboarding/game.png';

export const PlayNowModal = ({ games, isNewUser, inventory }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [gameId, setGameId] = useState('');
  
  const hasGamePass = inventory?.find(i => i?.subKind.includes(onBoardingData.selectedSku));
  const newUser = Cookies.get(storageKeys.isNewUser);

  const handleExploreGames = () => {
    if (searchParams.has(onBoardingData.onboard)) {
      searchParams.delete(onBoardingData.onboard);
      searchParams.set(onBoardingData.onboard, onBoardingData.flags.games);
      setSearchParams(searchParams);
      logFirebaseEventWithTimestamp(firebaseEvents.wenLamboOnboardingSelectGame);
    }
  }

  useEffect(() => {
    if (isEmpty(games)) return;
    const selectedGame = games.filter(g => g.slug === onBoardingData.selectedGame);
    setGameId(selectedGame[0].id);
  }, [games])

  const handleFlagUpdate = () => {
    const flagsData = {
      key: flagKeys.isFreeGamePassModalShown,
      body: {
        value: true
      }
    };
    dispatch(updateFlag(flagsData));
  }

  const handlePlayNow = () => {
    if ((isNewUser || newUser) && 
      !isEmpty(hasGamePass)) {
      const activateData = {
        sku: onBoardingData.selectedSku,
        quantity: defaultQuantity,
        gameUrl: `/play/${onBoardingData.selectedGame}/${gameId}`,
        kind: activateKind.gamePass,
        isNewUser,
        navigate
      }
      Cookies.set(storageKeys.isNewUser, true);
      handleFlagUpdate();
      dispatch(activateGamePass(activateData));
    } else {
      navigate(`/play/${onBoardingData.selectedGame}/${gameId}`, { state: { isNewUser: true }});
      logFirebaseEventWithTimestamp(firebaseEvents.wenLamboOnboardingPlayNow);
    }
    localStorage.removeItem(storageKeys.previousRoute);
  }
  
  return (
    <div className="onboarding-tutorial-popup-container">
      <img 
        src={game} 
        alt="game" 
        className="onboarding-tutorial-popup-image mb-2" 
      />
      <div className="onboarding-tutorial-popup-title">{onBoardingData.titles.playNowAndEarnGold}</div>
      <div className="onboarding-tutorial-popup-subtitle mb-3">{onBoardingData.titles.gameAndEarn}</div>
      <button 
        onClick={handlePlayNow} 
        className="onboarding-tutorial-popup-button-play"
      >
        {onBoardingData.titles.playNow}
      </button>
      <button 
        onClick={handleExploreGames} 
        className="onboarding-tutorial-popup-button-explore"
      >
        {onBoardingData.titles.exploreOtherGames}
      </button>
    </div>
  );
}
