import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { GamePassInventoryList } from './GamePassInventoryList';
import {
  ActivateGamePassToast,
  ActiveGamePasses,
  ActiveGamePassPopup,
  LayoutContent,
  OverrideActiveBoosterToast,
  PageTitle,
  Section
} from '../Components';
import { Toast } from '../Commons';
import {
  activateGamePass,
  getGamePassActiveEntitlements,
  setDisplayToast,
  setOverrideActiveBooster
} from '../../actions';
import {
  activateKind,
  cardsKind,
  defaultTimeoutMS,
  firebaseEvents,
  gamePassEmptyTexts,
  gamePassInventorySections,
  pages,
  gamePassText,
  goldBoosterText
} from '../../constants';
import {
  gamePassInventorySelector,
  entitlementsSelector,
  overrideActiveBoosterSelector
} from '../../selectors';
import { gamePass, goldBoost } from './images';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './GamePassInventory.scss';

export const GamePassInventory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showActivateGamePass, setShowActivateGamePass] = useState();
  const [toast, setToast] = useState({});
  const [gamePasses, setGamePasses] = useState([]);
  const [goldBoosters, setGoldBoosters] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [activeGamePassOpen, openActiveGamePass] = useState(false);

  const inventory = useSelector(state => gamePassInventorySelector(state));
  const entitlements = useSelector(state => entitlementsSelector(state));
  const errorToast = useSelector(state => state.login.toast);
  const overrideActiveBooster = useSelector(state => overrideActiveBoosterSelector(state));

  const handleActivate = (activateData) => {
    const { activateKind } = activateData;
    const { name, quantity, image, subKind } = activateData.pass;
    setToast({ name, quantity, image, subKind, activateKind });
    setShowActivateGamePass(true);
  }

  const handleCloseToast = () => {
    setShowActivateGamePass(false);
  }

  const handleActivateGamePass = (sku, quantity, kind) => {
    const itemData = { 
      sku, 
      quantity, 
      kind, 
      navigate, 
      shouldRedirectToGamePassGamesPage: true 
    };
    logFirebaseEventWithTimestamp(
      firebaseEvents.inventoryGamePassActivate, {
        sku_name: toast.name,
        page_url: window.location.pathname
      });
    setShowActivateGamePass(false);
    dispatch(activateGamePass(itemData));
  }

  useEffect(() => {
    dispatch(getGamePassActiveEntitlements());
  }, [dispatch]);

  useEffect(() => {
    setGamePasses(inventory.filter(pass => pass.quantity > 0 && pass.kind.includes(cardsKind.gamePass))
      .map(pass => ({
        ...pass,
        image: gamePass,
      })
    ));
    setGoldBoosters(inventory.filter(pass => pass.quantity > 0 && pass.kind.includes(cardsKind.goldBoost))
      .map(pass => ({
        ...pass,
        image: goldBoost
      })
    ));
  }, [inventory]);

  useEffect(() => {
    setToastOpen(true);
  }, [errorToast]);

  const closeToast = () => {
    setToastOpen(false);
    setTimeout(() => {
      dispatch(setDisplayToast());
    }, defaultTimeoutMS);
  }

  const activeGamePasses = !isEmpty(entitlements) && entitlements?.find(e => e.name === gamePassText);
  const activeGoldBoosters = !isEmpty(entitlements) &&  entitlements?.find(e => e.name === goldBoosterText);
  let activeGamePassItems = [];
  let activeGoldBoosterItems = [];
  if (activeGamePasses) activeGamePassItems = activeGamePasses.items;
  if (activeGoldBoosters) activeGoldBoosterItems = activeGoldBoosters.items;

  const handleGamePassClick = () => {
    if ((activeGamePasses &&
      activeGamePassItems.length > 1) ||
      (activeGoldBoosters &&
      activeGoldBoosterItems.length > 1)) {
      openActiveGamePass(true);
    }
  }

  const handleOverrideHide = () => {
    dispatch(setOverrideActiveBooster({ result: false }));
  }

  return (
    <LayoutContent>
      <PageTitle title={pages.gamePassInventory} />
      <Section
        title={pages.gamePassInventory}
        backButtonRoute
        backRoute={() => navigate(-1)}
        sectionClassName="gamepass-inventory-section" 
        titleCentered
        text="Back"
      >
        <ActiveGamePasses
          activeGamePass={activeGamePassItems}
          activeGoldBooster={activeGoldBoosterItems}
          handleGamePassClick={handleGamePassClick}
          noSnackbar
          shouldRedirectToGamePassGamesPage
        />
        <GamePassInventoryList
          title={gamePassInventorySections.gamePass}
          kind={activateKind.gamePass}
          list={gamePasses}
          emptyText={gamePassEmptyTexts.gamePass}
          handleActivate={handleActivate}
        />
        <GamePassInventoryList
          title={gamePassInventorySections.goldBoost}
          kind={activateKind.goldBoost}
          list={goldBoosters}
          emptyText={gamePassEmptyTexts.goldBoost}
          handleActivate={handleActivate}
        />
      </Section>
      <ActivateGamePassToast
        toast={toast}
        open={showActivateGamePass}
        hide={handleCloseToast}
        onClick={handleActivateGamePass}
      />
      <ActiveGamePassPopup
        open={activeGamePassOpen}
        hide={() => openActiveGamePass(false)}
        entitlements={entitlements}
      />
      {errorToast && 
        <Toast toast={errorToast} open={toastOpen} hide={closeToast} />}
      <OverrideActiveBoosterToast 
        open={overrideActiveBooster?.result} 
        overrideDetails={overrideActiveBooster} 
        hide={handleOverrideHide}
      />
    </LayoutContent>
  );
}