import { takeLatest, call, put, all } from 'redux-saga/effects';
import { requestUserProfile } from '../api/menu';
import { requestUserProfilePage, requestUserProfileFields } from '../api/profile';
import { GET_USER_PROFILE } from '../actions/constants';
import {
  setLoading,
  setUserProfile,
  setSessionExpired,
  renderPageContents,
  setUserProfileErrorStatus,
  setGuest,
  failedRequests,
  userProfileRequested
} from '../actions';
import { siteRoutes, storageKeys } from '../constants';

var CryptoJS = require("crypto-js");

const getUserProfileSaga = function* (payload) {
  const version = payload.payload?.version;
  const sessionToken = localStorage.getItem(storageKeys.sessionToken);
  const hasSessionToken = sessionToken && sessionToken.length > 0;
  const { pathname } = window.location;
  const login = pathname.includes(siteRoutes.login) || pathname.includes(siteRoutes.pin) || pathname.includes('');
  try {
    const response = yield call(requestUserProfile, sessionToken, version);
    if (response.status >= 200 && response.status < 400) {
      yield call(getUserProfilePageSaga, response.data.d);
    } else {
      yield put(setLoading(false));
    }
    yield put(userProfileRequested(true));
  } catch (e) {
    if (e.response && e.response.data) {
      yield put(setUserProfileErrorStatus(e.response?.status));
      yield put(userProfileRequested(true));
      if (hasSessionToken) yield put(setSessionExpired(true));
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
      if (e.response?.status >= 500 || login) yield put(setLoading(false));
      if (login) yield put(renderPageContents(true));
    }
  }
}

const getUserProfilePageSaga = function* (userProfile) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserProfilePage, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const updatedUserProfile = { ...userProfile, ...response.data.d };
      yield call(getUserProfilePageWithTutorialSaga, updatedUserProfile);
    } else {
      yield put(setLoading(false));
    }
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getUserProfilePageWithTutorialSaga = function* (userProfile) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestUserProfileFields, sessionToken);
    if (response.status >= 200 && response.status < 400) {
      const { showTutorial, profile } = response.data.d;
      const personalInfo = profile.form.find(f => f.fields.map(ff => ff.field).includes('mobnum'));
      const mobileNumber = personalInfo.fields.find(f => f.field === 'mobnum').value;
      const encryptedMobileNumber = CryptoJS.AES.encrypt(JSON.stringify(mobileNumber), 'mobilenumber').toString();
      const updatedUserProfile = { ...userProfile, mobnum: mobileNumber, showTutorial };
      if (!localStorage.getItem(storageKeys.username)) localStorage.setItem(storageKeys.username, updatedUserProfile.username);
      if (!localStorage.getItem(storageKeys.mobileNumber)) localStorage.setItem(storageKeys.mobileNumber, encryptedMobileNumber);
      yield put(setGuest(false));
      yield put(setUserProfile(updatedUserProfile));
    }
    yield put(renderPageContents(true));
    yield put(setLoading(false));
  } catch (e) {
    yield put(renderPageContents(true));
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* menuSaga() {
  yield all([
    takeLatest(GET_USER_PROFILE, getUserProfileSaga)
  ]);
}