import React, { Component, Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { ref, get, child } from 'firebase/database';
import { getAnalytics, setUserId } from 'firebase/analytics';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import Header from '../Header';
import Footer from '../Footer';
import Menu from '../Menu';
import LoginModal from '../LoginModal';
import OTPModal from '../OTPModal';
import PINModal from '../PINModal';
import TermsAndConditionsModal from '../TermsAndConditionsModal';
import { 
  ClaimFreeGamePass, 
  GamePassCheckoutModal, 
  ModalManager
} from '../Components';
import { Loader } from '../Commons';
import { 
  authSteps,
  buyGamePassCTA, 
  firebaseEvents, 
  flagKeys, 
  gamePassCTA, 
  layoutQueryParams, 
  onBoardingData, 
  siteRoutes, 
  partnerRedirectUrl, 
  storageKeys,
  defaultTimeoutMS,
  status
} from '../../constants';
import { showReferralAdBanner } from '../../actions';
import { database } from '../../utils/firebase';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';
import './Layout.scss';

export class Layout extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isMenuOpen: false,
      userId: ''
    };
  }

  componentDidMount() {
    const { sessionToken, userProfile, getUserProfile } = this.props;
    const { pathname } = window.location;
    const login = pathname.includes(siteRoutes.login);
    const conversion = pathname.includes(siteRoutes.mgcConversion);
    const termsAndConditions = pathname.includes(siteRoutes.termsAndConditions);
    const privacyPolicy = pathname.includes(siteRoutes.privacyPolicy);
    const support = pathname.includes(siteRoutes.support);
    const sessionTokenLS = localStorage.getItem(storageKeys.sessionToken);
    const mobileNumber = localStorage.getItem(storageKeys.mobileNumber);
    const hasMobileNumber = mobileNumber && mobileNumber.length > 0;
    const redirectUri = localStorage.getItem(storageKeys.redirectUri);
    const clientId = localStorage.getItem(storageKeys.clientId);
    const isGamePassTermsAndConditionsShown = Cookies.get(storageKeys.isGamePassTermsAndConditionsShown);
    const isFreeGamePassModalShown = Cookies.get(storageKeys.isFreeGamePassModalShown);

    this.getRedirectUri();

    let redirectRoute = siteRoutes.home;
    if (sessionTokenLS && login) {
      if (redirectUri && clientId) {
        const tokenData = JSON.parse(atob(sessionTokenLS.split('.')[1]));
        redirectRoute = `${partnerRedirectUrl}?redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${clientId}&uid=${tokenData.uid}`;
      }
      window.location.href = redirectRoute;
      localStorage.removeItem(storageKeys.redirectUri);
      localStorage.removeItem(storageKeys.clientId);
    }
    if (!hasMobileNumber) this.props.setGuest(true);

    if (!sessionToken && sessionTokenLS && !pathname.includes('login')) {
      this.props.loginOTPSuccess(sessionTokenLS);
    }

    if (termsAndConditions || privacyPolicy || conversion || support) {
      this.props.setLoading(false);
      this.props.renderPageContents(true);
    }

    if (isEmpty(userProfile)) getUserProfile();

    if (hasMobileNumber) this.getUserId();

    logFirebaseEvent(firebaseEvents.appLaunch);

    window.addEventListener('pageshow', function(evt) {
      if (evt.persisted) {
        setTimeout(function(){
          window.location.reload();
        }, 10);
      }
    }, false);

    if (isGamePassTermsAndConditionsShown) {
      this.props.getFlags({ key: flagKeys.isGamePassTermsAndConditionsShown, isLogin: true });
    }

    if (isFreeGamePassModalShown) {
      this.props.getFlags({ key: flagKeys.isFreeGamePassModalShown });
      this.props.getGamePassInventory();
    }
  }

  getUserId = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) this.setState({ userId: user.uid });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { 
      userProfile, 
      betaUser, 
      setBetaUser
    } = this.props;
    const { userId } = this.state;

    if (prevState.userId !== userId && userId && userId !== '') {
      this.getUnreadMessages(userId);
    }

    if (prevProps.userProfile !== userProfile && userProfile) {
      const analytics = getAnalytics();
      if (!isEmpty(userProfile)) setUserId(analytics, userProfile.uid);
      // set if user is beta or non beta
      setBetaUser(betaUser);
    }
  }

  getUnreadMessages = (userId) => {
    if (!userId) return;
    get(child(ref(database), `chatReference/${userId}`)).then((snapshot) => {
      if (snapshot.exists()) {
        const messagesFromDB = snapshot.val();
        if (messagesFromDB) {
          const messageThreads = Object.keys(messagesFromDB).map(k => messagesFromDB[k]);
          const unreadLength = messageThreads.filter(t => !t.read).length;
          this.props.setUnreadMessages(unreadLength);
        }
      }
    }).catch((error) => { console.error(error); });
  }

  selectRoute = () => {
    this.props.setLoading(true);
  }

  openMenu = () => {
    const { isMenuOpen, userId } = this.state;
    if (!isMenuOpen) this.getUnreadMessages(userId);
    this.setState({ isMenuOpen: !isMenuOpen });
  }

  logout = () => {
    this.props.setLoading(true);
    const auth = getAuth();
    const analytics = getAnalytics();

    const localStorageKeys = [
      storageKeys.sessionToken,
      storageKeys.username,
      storageKeys.mobileNumber,
      storageKeys.previousRoute
    ];
    localStorageKeys.map(k => {
      if (localStorage.getItem(k)) localStorage.removeItem(k);
    });

    const cookieKeys = [
      storageKeys.dynamicSlug,
      storageKeys.slug
    ];
    cookieKeys.map(k => {
      if (Cookies.remove(k)) Cookies.remove(k);
    })

    if (Cookies.get(storageKeys.pmSource)) Cookies.remove(storageKeys.pmSource);
    if (sessionStorage.getItem(storageKeys.pmSource)) sessionStorage.removeItem(storageKeys.pmSource);
    
    this.props.setSessionExpired(false);
    setUserId(analytics, null);

    signOut(auth)
      .then(() => {
        this.setState({ isMenuOpen: false });
        this.props.resetState();
        this.props.getUserProfile();
        this.props.setGuest(true);
        this.props.setAuthStep(authSteps.login);
        this.props.navigateTo({ path: '/' });
        this.props.setLoading(false);
      })
      .catch((err) => {
        this.props.setLoading(false);
        logFirebaseEvent(
          firebaseEvents.failedEvent, {
            error_message: err
          });
      });
  }

  getRedirectUri = () => {
    const search = window.location.search;
    const queryParams = new URLSearchParams(search);
    const redirectUri = queryParams.get(layoutQueryParams.redirectUri);
    const clientId = queryParams.get(layoutQueryParams.clientId);

    if (redirectUri && clientId) {
      localStorage.setItem(storageKeys.redirectUri, redirectUri);
      localStorage.setItem(storageKeys.clientId, clientId);
    }       
  }

  backPIN = () => {
    const { onboardingTransactionData, setAuthStep, showReferralAdBanner } = this.props;
    setAuthStep();
    Cookies.remove(storageKeys.showSetPIN);
    Cookies.remove(storageKeys.isGamePassTermsAndConditionsShown);
    if (window.location.pathname.includes(siteRoutes.payment) &&
      onboardingTransactionData?.status === status.pending) {
      setTimeout(() => {
        window.location.href = `${gamePassCTA}?onboard=${onBoardingData.flags.modal}`;
      }, defaultTimeoutMS);
    }
  }
  
  hideCheckout = () => {
    this.props.showGamePassCheckout(false);
  }

  render() {
    const {
      loading,
      userProfile,
      menuItems,
      sideMenuItems,
      sideMenuOtherItems,
      unreadMessages,
      loggedIn,
      sessionExpired,
      pageContentsRendered,
      hideHeader,
      hideMenuIcon,
      hideFooter,
      hasNoWalkthroughHeightLimit,
      authStep,
      isGuest,
      showLoading,
      showClaimFreeGamePass,
      showCheckout
    } = this.props;
    const { isMenuOpen } = this.state;
    const { pathname } = window.location;
    const location = window.location.search;
    const queryParams = new URLSearchParams(location);
    const mobile = queryParams.get(layoutQueryParams.mobile);
    const onboard = queryParams.get(onBoardingData.onboard);
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const tokenData = sessionToken && jwtDecode(sessionToken);
    const showSetPIN = Cookies.get(storageKeys.showSetPIN);
    const showPINModal = authStep === authSteps.pin || showSetPIN;

    const messages = pathname.includes(siteRoutes.messages);
    const spend = pathname.includes(siteRoutes.spend);
    const loginPage = pathname.includes(siteRoutes.login);
    const tutorial = pathname.includes(siteRoutes.tutorial);
    const pin = pathname.includes(siteRoutes.pin);
    const conversion = pathname.includes(siteRoutes.mgcConversion);
    const support = pathname.includes(siteRoutes.support);
    const play = pathname.includes(siteRoutes.spinTheWheel);
    const mechanics = pathname.includes(siteRoutes.mechanics);
    const ticketRedeem = pathname === siteRoutes.redeem;
    const instructions = pathname.includes(siteRoutes.instructions);
    const game = pathname.includes(siteRoutes.play);

    const menuIconHidden = tutorial || pin || conversion || ticketRedeem || play || mechanics || instructions || hideMenuIcon;
    const headerLogoClickable = !tutorial && !pin;
    const bottomMenuHidden = tutorial || pin || conversion || support || play || ticketRedeem || mechanics || (instructions && mobile);
    const displayHeaderAndMenu = pageContentsRendered && !((!loggedIn && (messages || spend)) || loginPage || pin || ticketRedeem || (play && !mechanics));
    const displayFooter = displayHeaderAndMenu && !bottomMenuHidden;
    const showCart = pathname === siteRoutes.home || pathname === gamePassCTA || pathname.includes(buyGamePassCTA); // cart is only visible to home, /gamepass and /gamepass/buy
    
    return (
      <div className="layout">
        {displayHeaderAndMenu && 
          !hideHeader && //redux state to hide header (hideHeader)
          <Header
            openMenu={this.openMenu}
            menuIconHidden={menuIconHidden}
            headerLogoClickable={headerLogoClickable}
            userProfile={userProfile}
            showCart={showCart}
          />}
        {displayHeaderAndMenu && 
          !hideHeader && //redux state to hide header (hideHeader)
          <Menu
            userProfile={userProfile}
            sideMenuItems={sideMenuItems}
            sideMenuOtherItems={sideMenuOtherItems}
            isOpen={isMenuOpen}
            unreadMessages={unreadMessages}
            sessionExpired={sessionExpired}
            openMenu={this.openMenu}
            logout={this.logout}
          />}
        {pageContentsRendered && 
          <div className={classNames("layout-outlet", {
            "layout-outlet-no-footer": !displayFooter && !loginPage && !pin,
            "layout-outlet-full": !displayFooter && (loginPage || pin),
            "layout-outlet-bottom": hideFooter,
            "layout-outlet-top": hideHeader,
            "layout-outlet-hidden": hideHeader && hideFooter,
            "layout-outlet-auto": hasNoWalkthroughHeightLimit
          })}>
            <Outlet />
            {isGuest &&
              <Fragment>
                <LoginModal open={authStep === authSteps.login} />
                <OTPModal open={authStep === authSteps.otp} />
              </Fragment>}
            {!tokenData?.claims?.hasPIN &&
              !game &&
              !onboard &&
              !isGuest &&
              <PINModal open={showPINModal} handleBack={this.backPIN} />}
            <TermsAndConditionsModal open={authStep === authSteps.termsAndConditions} />
            {!game &&
              !onboard &&
              <ClaimFreeGamePass open={showClaimFreeGamePass} />}
            <GamePassCheckoutModal 
              open={showCheckout} 
              hide={this.hideCheckout}
            />
            {!isGuest && <ModalManager />}
          </div>}
        {(loading || showLoading) &&
          <Loader menusHidden />}
        {displayFooter && 
          !hideFooter && //redux state to hide footer (hideFooter)
          <Footer
            menuItems={menuItems}
            sessionExpired={sessionExpired}
          />}
      </div>
    );
  }
}