import ReactCountryFlag from 'react-country-flag';
import usdcIcon from '../assets/payment-methods/1644837029143-USDC.png';

export const withdrawalCurrency = [
  {
    name: 'PHP',
    value: 'PHP',
    icon: <ReactCountryFlag countryCode='PH' className="general-dropdown-title-icon" svg />
  },
  {
    name: 'USDC',
    value: 'USDC',
    icon: <img src={usdcIcon} alt="USDC" className="general-dropdown-title-icon" />
  },
];

export const withdrawalChannel = 'PH_SHOPEEPAY';