import React from 'react';
import classNames from 'classnames';
import './ActiveGamePass.scss';

export const ActiveGamePassTickets = ({ imageUrls, title, onClick }) => (
  <div
    className={classNames(
      "active-game-pass-tickets",
      {"active-game-pass-tickets-stacked": imageUrls.length > 1}
    )}
    onClick={onClick}
  >
    <div className="active-game-pass-ticket-images">
      {imageUrls.map((i, idx) => {
        let imageStyle = null;
        if (imageUrls.length > 1) {
          const topMultiplier = imageUrls.length === 3 ? 7.5 : 10;
          const topAddend = imageUrls.length === 3 ? 12.5 : 10;
          const position = topMultiplier * idx;
          let top = (position * -1) + topAddend;
          let left = position;
          if (position > 0) {
            top = `${(position * -1) + topAddend}px`;
            left = `${position}px`;
          }

          imageStyle = {
            zIndex: `${idx + 1}`,
            top,
            left
          };
        }
        return (
          <img
            key={`${title}_${idx}`}
            src={i}
            className={classNames(
              "active-game-pass-ticket-image", {
                "active-game-pass-ticket-image-multiple": imageUrls.length > 1,
                "active-game-pass-ticket-image-multiple-max": imageUrls.length === 3
              }
            )}
            alt="active-game-pass-active"
            style={imageStyle}
          />
        );
      })}
    </div>
  </div>
);