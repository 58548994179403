import axios from 'axios';
import { baseUrl } from './url';
import { types } from '../constants';

export function requestWalletSignMessage(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/wallet/sign-message?type=${types.connect}`)
  });
}

export function requestVerifySignMessage(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/wallet/sign-message')
  });
}

export function requestCryptoCurrencies(intent, params, sessionToken) {
  return axios.request({
    method: 'GET',
    params: { ...params },
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`${intent}/crypto-currencies`)
  });
}