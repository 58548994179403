import { all, call, put, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import {
  failedRequests,
  navigateTo,
  setCreatePayment,
  setCurrencyConversion,
  setCurrencyConversionBatch,
  setCurrencyLoading,
  setLoading,
  setPaymentChannels,
  setPaymentMethods,
  setTopUpVisible,
  setWalletCurrencyConversion
} from '../actions';
import {
  CREATE_PAYMENT,
  GET_CURRENCY_CONVERSION,
  GET_CURRENCY_CONVERSION_BATCH,
  GET_PAYMENT_CHANNELS,
  GET_PAYMENT_METHODS
} from '../actions/constants';
import {
  requestCreatePayment,
  requestCurrencyConversion,
  requestCurrencyConversionBatch,
  requestPaymentChannels,
  requestPaymentMethods
} from '../api/topup';
import { 
  firebaseEvents,
  responseStatus,
  siteRoutes,
  storageKeys,
  topUpDirection,
  wallets
} from '../constants';
import { logFirebaseEventWithTimestamp } from '../utils/logFirebaseEvent';

const getPaymentMethodsSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestPaymentMethods, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setPaymentMethods(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getPaymentChannelsSaga = function* (payload) {
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestPaymentChannels, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
       yield put(setPaymentChannels(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const createPaymentSaga = function* (payload) {
  const amount = payload.payload.fromAmount || payload.payload.toAmount;
  const currency = payload.payload.fromCurrency;
  if (payload.payload?.channelCode !== wallets.walletConnect.name) {
    yield put(setCreatePayment({ loading: true }));
  }
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestCreatePayment, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const { method, status, referenceId, channelCode } = response.data.d || '';
      if (!response.data?.d?.isRedirectRequired && 
        channelCode === wallets.walletConnect.name) {
        const path = `${siteRoutes.payment}/${method?.toLowerCase()}/${status}?referenceId=${referenceId}`;
        yield put(navigateTo({ path }));
        yield put(setTopUpVisible(false));
      } else {
        if (!isEmpty(response.data.d?.checkoutUrl)) window.location.href = response.data.d.checkoutUrl;
        yield put(setCreatePayment({
          loading: false,
          data: response.data.d
        }));
      }
    }
    yield put(setLoading(false));
  } catch (e) {
    logFirebaseEventWithTimestamp(
      firebaseEvents.walletTopupFailed, {
        amount,
        currency
      });
    yield put(setLoading(false));
    yield put(setTopUpVisible(false));
    yield put(setCreatePayment({ loading: false }));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getCurrencyConversionSaga = function* (payload) {
  const { 
    fromCurrency, 
    toCurrency, 
    direction, 
    value, 
    channel,
    chainId, 
    isWallet
  } = payload.payload;
  let conversionParams = {
    fromCurrency,
    toCurrency,
    channelCode: channel
  }
  if (chainId) {
    conversionParams.chainId = chainId;
  }
  if (direction === topUpDirection.to) {
    conversionParams.toAmount = value;
  } else if (direction === topUpDirection.from) {
    conversionParams.fromAmount = value;
  }
  yield put(setCurrencyLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestCurrencyConversion, conversionParams, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      if (isWallet) {
        yield put(setWalletCurrencyConversion(response.data.d));
      } else {
        yield put(setCurrencyConversion(response.data.d));
      }
    }
    yield put(setLoading(false));
    yield put(setCurrencyLoading(false));
  } catch (e) {
    yield put(setCurrencyLoading(false));
    yield put(setLoading(false));
    yield put(setCreatePayment({loading: false}));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getCurrencyConversionBatchSaga = function* (payload) {
  yield put(setLoading(true));
  const { fromCurrency, toCurrency, toAmounts, channel, direction } = payload.payload;
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestCurrencyConversionBatch, fromCurrency, direction, toAmounts, toCurrency, channel, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setCurrencyConversionBatch(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setCreatePayment({loading: false}));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* buySaga() {
  yield all([
    takeLatest(GET_PAYMENT_METHODS, getPaymentMethodsSaga),
    takeLatest(GET_PAYMENT_CHANNELS, getPaymentChannelsSaga),
    takeLatest(CREATE_PAYMENT, createPaymentSaga),
    takeLatest(GET_CURRENCY_CONVERSION, getCurrencyConversionSaga),
    takeLatest(GET_CURRENCY_CONVERSION_BATCH, getCurrencyConversionBatchSaga)
  ]);
}