import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Snackbars } from '../../Commons';
import GamePassInventoryTimer from '../GamePassInventoryTimer/GamePassInventoryTimer';
import EmptyGamePass from '../EmptyGamePass/EmptyGamePass';
import {
  activateKind,
  cardsKind,
  gamePassText,
  gamePassViewInventoryTextDetailed,
  goldBoosterText,
  myGamePassSectionTitle,
  gamePassInventoryCTA
} from '../../../constants';
import { getGamePassInventory, showGamePassActivated } from '../../../actions';
import { gamePassInventorySelector, showGamePassActivatedSelector } from '../../../selectors';
import emptyGamePass from '../../../assets/gamepass/cards/empty-game-pass.png';
import emptyGoldBooster from '../../../assets/gamepass/cards/empty-gold-booster.png';
import './ActiveGamePasses.scss';

const ActiveGamePasses = ({ 
  activeGamePass,
  activeGoldBooster,
  containerClassName,
  hasHeader,
  handleGamePassClick,
  inventoryRedirect,
  noSnackbar,
  compact,
  shouldRedirectToGamePassGamesPage,
  redirectIfUnauthorized = true,
}) => {
  const dispatch = useDispatch();

  const [gamePasses, setGamePasses] = useState([]);
  const [goldBoosters, setGoldBoosters] = useState([]);

  const inventory = useSelector(state => gamePassInventorySelector(state));
  const showActivated = useSelector(state => showGamePassActivatedSelector(state));
  
  const hideSnackbar = () => {
    dispatch(showGamePassActivated({ result: false }));
  }
  
  useEffect(() => {
    dispatch(getGamePassInventory(redirectIfUnauthorized));
  }, [dispatch, redirectIfUnauthorized])

  useEffect(() => {
    if (!isEmpty(inventory)) {
      let gamePasses = []
      let goldBoosters = []
      for (const item of inventory) {
        if (item.kind.includes(cardsKind.gamePass)) {
          gamePasses.push(item);
        } else if (item.kind.includes(cardsKind.goldBoost)) {
          goldBoosters.push(item);
        }
      }
      setGamePasses(gamePasses);
      setGoldBoosters(goldBoosters);
    }
  }, [inventory])

  return (
    <div
      id="active-gamepass" 
      className={classNames(
        "active-game-passes",
        containerClassName
      )}
    >
      {hasHeader &&
        <div className="active-game-passes-header">
          <h1 className="active-game-passes-title">{myGamePassSectionTitle}</h1>
        </div>}
      <div className="active-game-passes-content">
        <div className={classNames(
          "active-game-passes-cards",
          {"active-game-passes-cards-compact": compact}
        )}>
          {!isEmpty(activeGamePass) ?
            <GamePassInventoryTimer
              gamePasses={activeGamePass}
              emptyImageUrl={emptyGamePass}
              title={gamePassText}
              handleGamePassClick={handleGamePassClick}
              compact={compact}
            /> :
            <EmptyGamePass
              items={gamePasses}
              imgUrl={emptyGamePass}
              title={gamePassText}
              kind={activateKind.gamePass}
              compact={compact}
              isTutorial
              shouldRedirectToGamePassGamesPage={shouldRedirectToGamePassGamesPage}
            />}
          {!isEmpty(activeGoldBooster) ?
            <GamePassInventoryTimer
              gamePasses={activeGoldBooster}
              emptyImageUrl={emptyGoldBooster}
              title={goldBoosterText}
              handleGamePassClick={handleGamePassClick}
              compact={compact}
            /> :
            <EmptyGamePass
              items={goldBoosters}
              imgUrl={emptyGoldBooster}
              title={goldBoosterText}
              kind={activateKind.goldBoost}
              compact={compact}
              shouldRedirectToGamePassGamesPage={shouldRedirectToGamePassGamesPage}
            />}
        </div>
      </div>
      {inventoryRedirect &&
        <Link 
          to={gamePassInventoryCTA}
          className="active-game-passes-footer"
        >
          <div className="d-flex align-items-center">
            <div className="active-game-passes-footer-text">{gamePassViewInventoryTextDetailed}</div>
            <KeyboardArrowRightRoundedIcon className="active-game-passes-footer-arrow" />
          </div>
        </Link>}
      {!noSnackbar &&
        !showActivated?.shouldNotShow &&
        <Snackbars
          flag={showActivated?.result}
          title={showActivated?.title}
          hide={hideSnackbar}
          isGamePass
        />}
    </div>
  )
};

export default ActiveGamePasses;