import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import LinesEllipsis from 'react-lines-ellipsis';
import Section from './Section';
import { TournamentPrize } from '../TournamentPrize/TournamentPrize';
import GamePassProductAmount from '../GamePassProductAmount/GamePassProductAmount';
import { HorizontalScroll } from '../../Commons';
import { setGamePassPlayGame } from '../../../actions';
import { 
  firebaseEvents, 
  maxWidths,
  minWidths,
  storageKeys
} from '../../../constants';
import { guestSelector, userEntitlementsSelector } from '../../../selectors';
import { logFirebaseEventWithTimestamp } from '../../../utils/logFirebaseEvent';
import buyTicketIcon from '../../../assets/tournaments/buy-ticket-icon.png';
import './Section.scss';

const SectionList = ({
  title,
  list,
  link,
  linkText,
  openInNewTab,
  icon,
  detailed,
  marketplace,
  sectionClassName,
  thumbnailNameCentered,
  handleGameDetails
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const entitlements = useSelector(state => userEntitlementsSelector(state));
  const isGuest = useSelector(state => guestSelector(state));

  const sm = useMediaQuery({ minWidth: minWidths.mobileSM, maxWidth: maxWidths.mobileSM });
  const md = useMediaQuery({ minWidth: minWidths.tablet, maxWidth: maxWidths.tablet });
  const lg = useMediaQuery({ minWidth: minWidths.desktopSM, maxWidth: maxWidths.desktopLG });
  const xl = useMediaQuery({ minWidth: minWidths.desktopXL, maxWidth: maxWidths.desktopXXL });
  const xxxl = useMediaQuery({ minWidth: minWidths.desktopXXXL });

  let slidesToShow = detailed ? 1 : 2;
  if (sm) slidesToShow = detailed ? 2 : 3;
  else if (md) slidesToShow = detailed ? 2 : 4;
  else if (lg) slidesToShow = detailed ? 3 : 5;
  else if (xl) slidesToShow = detailed ? 3 : 6;
  else if (xxxl) slidesToShow = detailed ? 4 : 7;

  const isTutorial = localStorage.getItem(storageKeys.isTutorial);

  const handleRoute = (route) => {
    navigate(route);
  }

  const handlePlayGame = (gameDetails) => {
    const { slug, id, gameName } = gameDetails;
    let event = firebaseEvents.wenLamboMainPlay;
    if (window.location.pathname === '/') {
      event = firebaseEvents.wenLamboHomePlay;
    }
    logFirebaseEventWithTimestamp(
      event, {
        game_name: gameName
      });
    if (isEmpty(entitlements?.gamePass) && handleGameDetails && !isGuest) {
      dispatch(setGamePassPlayGame(true));
      handleGameDetails(gameDetails);
    } else {
      navigate(`/play/${slug}/${id}`);
    }
    if (isTutorial) {
      logFirebaseEventWithTimestamp(
        firebaseEvents.wenLamboOnboardingSelectGamePlay , {
          game_name: gameName
        });
    }
    localStorage.removeItem(storageKeys.previousRoute);
  }

  return (
    <Section
      title={title}
      link={link}
      linkText={linkText}
      icon={icon}
      sectionClassName={sectionClassName}
      handleRoute={handleRoute}
    >
      <div className="section-list">
        <HorizontalScroll slidesToShow={slidesToShow}>
          {list.map(l => {
            const experienceName = l.experience ? l.experience.toLowerCase() : link.split('/').slice(1)[0];
            const isTournament = experienceName === 'tournaments' || experienceName === 'premium';
            const isGamePass = experienceName === 'gamepass';
            const hasGridStats = isTournament || isGamePass;
            const experience = hasGridStats ? experienceName : `${experienceName}s`;

            let name = l.id;
            if (experience === 'games' || experience === 'gamepass') {
              name = (l.slug || camelCase(l.name).toLowerCase());
            } else if (isTournament) {
              name = camelCase(l.gameName).toLowerCase();
            }
            
            let linkUrl = `/${experience}/${name}`;
            if (openInNewTab) linkUrl = l.post_url;
            if (isTournament) linkUrl = '#';
            if (isGamePass) linkUrl = `/${experience}/games/${l.id}`;

            let imageUrl = '';
            if (isGamePass) {
              imageUrl = l.imageUrl;
            } else {
              imageUrl = l.images && l.images.length ? (detailed ? l.images[0].image_url : l.images[0].thumb_url) : l.image_url;
            }
            const marketplaceLinkUrl = `/marketplace/${l.slug}/${l.id}`;

            let activePlayers = null;
            if (l.participants) {
              activePlayers = `${l.participants} Players`;
            }
            
            if (detailed) {
              return (
                <div key={`${title}_${l.name}`} className={classNames("section-list-item", "section-list-item-detailed")}>
                  <Link to={linkUrl} className="section-list-item-link">
                    <div
                      className={classNames(
                        "thumbnail",
                        "thumbnail-detailed"
                      )}
                      style={{ backgroundImage: `url(${imageUrl})` }}
                    />
                  </Link>
                  <div className="thumbnail-details">
                    <div className="thumbnail-details-text">
                      <Link
                        to={linkUrl}
                        className={classNames(
                          "thumbnail-name",
                          "thumbnail-name-detailed"
                        )}
                      >
                        {l.name}
                      </Link>
                      {l.prizePool && <TournamentPrize {...l.prizePool} />}
                    </div>
                    <div className="thumbnail-details-join">
                      <Link to={linkUrl} className="thumbnail-details-join-button">
                        <img src={buyTicketIcon} className="thumbnail-details-join-icon" alt="Ticket" />
                        Join
                      </Link>
                    </div>
                  </div>
                </div>
              );
            } else {
              let thumbnailDetails = <Link
                to={linkUrl}
                className={classNames(
                  "thumbnail-name",
                  "thumbnail-name-height",
                  {"thumbnail-name-centered": thumbnailNameCentered}
                )}>
                {l.name}
              </Link>;
              if (marketplace) {
                thumbnailDetails = <div className="thumbnail-details-wrapper">
                  <Link
                    to={marketplaceLinkUrl}
                    className={classNames(
                      "thumbnail-details-wrapper-details",
                      "thumbnail-details-wrapper-details-marketplace"
                    )}
                  >
                    <div className="thumbnail-details-wrapper-title">{l.collectionName}</div>
                    <LinesEllipsis
                      text={l.name}
                      maxLine={2}
                      ellipsis="..."
                      trimRight
                      basedOn="words"
                      className="thumbnail-details-wrapper-name"
                    />
                    {l.nftListing &&
                      <div className="thumbnail-details-wrapper-stats">
                        <GamePassProductAmount 
                          {...l.nftListing} 
                          isMarketplace 
                        />
                      </div>}
                  </Link>
                </div>;
              }
              if (isGamePass) {
                thumbnailDetails = <div className="thumbnail-details-wrapper">
                  <Link to={linkUrl} className="thumbnail-details-wrapper-details">
                    <div className="thumbnail-details-wrapper-title">{l.genre}</div>
                    <div className="thumbnail-details-wrapper-name">{l.name}</div>
                    {activePlayers &&
                      <div className="thumbnail-details-wrapper-stats">
                        <div className="thumbnail-details-wrapper-stats-icon" />
                        <div className="thumbnail-details-wrapper-stats-value">{activePlayers}</div>
                      </div>}
                  </Link>
                  <div 
                    onClick={() => handlePlayGame({
                      id: l.id,
                      slug: l.slug,
                      gameName: l.gameName
                    })} 
                    className="thumbnail-details-wrapper-button"
                  >
                    Play Now
                  </div>
                </div>;
              }

              return (
                <div key={`${title}_${l.name}`} className="section-list-item">
                  {openInNewTab ?
                    <div className="section-list-item-link">
                      <a
                        href={linkUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="thumbnail"
                        style={{ backgroundImage: `url('${imageUrl}')` }}
                      />
                      <div className="thumbnail-name">{l.name}</div>
                    </div> :
                    <div className="section-list-item-link">
                      <Link
                        to={marketplace ? marketplaceLinkUrl : linkUrl}
                        className="thumbnail"
                        style={{ backgroundImage: `url('${imageUrl}')` }}
                      />
                      {thumbnailDetails}
                    </div>}
                </div>
              );
            }
          })}
        </HorizontalScroll>
      </div>
    </Section>
  );
}

export default SectionList;