import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import { 
  getUserTransactionDetails,
  setCreatePayment,
  setInstructionsVisible,
  setTopUpVisible
} from '../../actions';
import { instructionText, minWidths, siteRoutes } from '../../constants';

export const TopUpPaymentOrder = ({ paymentData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const details = useSelector((state) => state.transactions.transactionDetails);
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  const d = new Date(details.expiresAt);
  const is7Eleven = paymentData.data.channelCode.toLowerCase() === "7eleven";
  const id = paymentData.data?.transactionId;

  useEffect(() => {
    dispatch(getUserTransactionDetails({ id }));
  },[dispatch, paymentData, id])

  const getDateFormat = () => {
    const updatedDateFormat = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    return updatedDateFormat;
  }

  const getTimeFormat = () => {
    const updatedTimeFormat = new Date(details.expiresAt).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    return updatedTimeFormat;
  }

  const handleViewInstructions = () => {
    if (!isDesktop) {
      navigate(`/payment/instructions${window.location.search}`);
      dispatch(setInstructionsVisible(true));
      dispatch(setTopUpVisible(false));
      return;
    }
    if (isDesktop) dispatch(setInstructionsVisible(true));
    dispatch(setTopUpVisible(false));
  }

  const handleNavigate = () => {
    dispatch(setTopUpVisible(false));
    dispatch(setCreatePayment({}));
    navigate(siteRoutes.wallet, { replace: isDesktop });
  }

  useEffect(() => {
    return () => {
      if (!isDesktop) dispatch(setTopUpVisible(false));
    }
  }, [dispatch, isDesktop])

  return (
    <div className="buy-order-summary">
      <div className="buy-order-summary-container">
        <div className="buy-order-summary-header">
          <div className="buy-order-summary-header-pending"/>
          <div className="buy-order-summary-header-title">Top-up initiated</div>
          <div className="d-flex align-items-center gap-2">
            <div className="buy-order-summary-header-mgc"/>
            <div className="buy-order-summary-header-amount">{details.amount?.text}</div>
          </div>
        </div>
        <div className="buy-order-summary-body">
          {!isEmpty(details) && (
            <div className="buy-order-summary-body-container">
              {is7Eleven && <div>
                <div className="buy-order-summary-body-show mb-3">Ask the cashier to scan this barcode:</div>
                <div className="buy-order-summary-body-qrcode">
                  <img className="w-100" src={details.barCodeImageUrl} alt="barcode"/>
                </div>
              </div>}
              <div className={`buy-order-summary-body-wrapper ${is7Eleven ? "mt-4" : "mt-0"}`}>
                <div className="buy-order-summary-body-wrapper-content">
                  <div className="buy-order-summary-body-content">
                    <div className="buy-order-summary-body-text">Payment Channel:</div>
                    <div className="buy-order-summary-body-subtitle">{details.details.paymentChannel?.name}</div>
                  </div>
                  <div className="buy-order-summary-body-content">
                    <div className="buy-order-summary-body-text">Pay to Merchant:</div>
                    <div className="buy-order-summary-body-subtitle">Xendit</div>
                  </div>
                  <div className="buy-order-summary-body-content">
                    <div className="buy-order-summary-body-text">Pay Before:</div>
                    <div className="buy-order-summary-body-subtitle">{getDateFormat()} {getTimeFormat()}</div>
                  </div>
                </div>
                <div className="buy-order-summary-body-wrapper-content">
                  <div className="buy-order-summary-body-content">
                    <div className="buy-order-summary-body-text">Payment Code:</div>
                    <div className="buy-order-summary-body-subtitle">{details.barCode}</div>
                  </div>
                  {details.details.fromAmount && <div className="buy-order-summary-body-content">
                    <div className="buy-order-summary-body-text">Amount to Pay:</div>
                    <div className="buy-order-summary-body-subtitle">{details.details.fromAmount.text}</div>
                  </div>}
                  <div className="buy-order-summary-body-content">
                    <div className="buy-order-summary-body-text">Transaction ID:</div>
                    <div className="buy-order-summary-body-subtitle">{details.id}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="buy-order-summary-instruction">{instructionText}</div>
        <div className="buy-order-summary-footer">
          <button onClick={handleViewInstructions} className="buy-order-summary-footer-button">View instructions</button>
          <button onClick={handleNavigate} className="buy-order-summary-footer-button-grey">View balance</button>
        </div>
      </div>
    </div>
  )
}