import React, { Fragment, useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { OrderSkus } from './OrderSkus';
import { OverrideActiveBoosterToast } from '../Components';
import { 
  cardsKind,
  gamePassActivateAndPlayText,
  gamePassCTA, 
  gamePassContinueText, 
  gamePassGamesCTA, 
  gamePassInventoryTitle, 
  onBoardingData, 
  storageKeys
} from '../../constants';
import { activateMultipleSkus, setOverrideActiveBooster } from '../../actions';
import { overrideActiveBoosterSelector } from '../../selectors';

export const OrderActivate = ({ inventory, pendingItems, isMultipleActivate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);

  const overrideActiveBooster = useSelector(state => overrideActiveBoosterSelector(state));

  const isLoggedInTutorial = Cookies.get(storageKeys.isLoggedInTutorial);

  useEffect(() => {
    let skusArr = [];
    for (const item of pendingItems) {
      if (!isEmpty(item?.bundledItemSkus)) {
        for (const skus of item?.bundledItemSkus) {
          skusArr.push(skus);
        }
      } else {
        skusArr.push(item);
      }
    }
    const filterredSkus = skusArr?.filter((obj, index) => {
      return index === skusArr?.findIndex(o => obj.skuKind === o.skuKind);
    });
    setItems(filterredSkus);
  }, [pendingItems])

  useEffect(() => {
    if (isEmpty(items)) return;
    for (const item of items) {
      for (const skus of inventory) {
        if (skus.subKind === item.skuKind) {
          item.quantity = skus.quantity;
        }
      }
    }
  }, [items, inventory])

  // multiple activate sort game pass first then gold boost lowest to highest multiplier
  const gamePassArray = selectedSkus?.filter(item => item?.skuKind?.startsWith(cardsKind.gamePass));
  const goldBoostArray = selectedSkus?.filter(item => item?.skuKind?.startsWith(cardsKind.goldBoost));
  const sortedGoldBoostArray = goldBoostArray.sort((a, b) => a.multiplier - b.multiplier)
  const filteredSkus = gamePassArray.concat(sortedGoldBoostArray);

  const handleActivate = () => {
    if (isEmpty(selectedSkus)) {
      let url = gamePassGamesCTA;
      if (isLoggedInTutorial) {
        url = `${gamePassCTA}?onboard=${onBoardingData.flags.modal}`;
        Cookies.remove(storageKeys.isLoggedInTutorial);
      }
      navigate(url);
    } else {
      const activateData = {
        selectedSkus: filteredSkus,
        navigate
      }
      dispatch(activateMultipleSkus(activateData));
    }
    Cookies.remove(storageKeys.isNewUser);
  };

  const handleOverrideHide = () => {
    dispatch(setOverrideActiveBooster({ result: false }));
    if (isMultipleActivate) {
      navigate(gamePassGamesCTA);
    }
  }

  const extractMultiplier = (str) => {
    const regex = /gold-boost-(\d+(?:-\d+)?(?:\.\d+)?)x/;
    const match = str.match(regex);
  
    if (match) {
      const numberStr = match[1].replace('-', '.');
      const number = parseFloat(numberStr);
      return number;
    }
  
    return null;
  };

  const handleUpdateItems = useCallback((sku) => {
    const matchingItem = inventory?.find(item => item.subKind === sku.skuKind);
    
    if (matchingItem) {
      sku.durationInMs = matchingItem?.durationInMs;
    }
    
    let multiplier = matchingItem?.multiplier;
    // if multiplier does not exist in object
    if (!multiplier && sku?.skuKind?.includes(cardsKind.goldBoost)) {
      const number = extractMultiplier(sku?.skuKind);
      multiplier = number;
    }

    if (multiplier) {
      sku.multiplier = multiplier;
    }

    return sku;
  }, [inventory]);

  useEffect(() => {
    if (isEmpty(items)) {
      return;
    }

    const updatedItems = items.map(sku => {
      return handleUpdateItems(sku);
    });

    const gamePasses = updatedItems?.filter(i => i?.skuKind?.includes(cardsKind.gamePass));
    const gamePassesResult = gamePasses?.length > 0
        ? gamePasses.reduce((acc, current) => (current.durationInMs < acc.durationInMs ? current : acc))
        : null
  
    const goldBoosters = updatedItems?.filter(i => i?.skuKind?.includes(cardsKind.goldBoost));
    const goldBoostersResult = goldBoosters?.length > 0 
        ? goldBoosters.reduce((acc, current) => (current.multiplier < acc.multiplier ? current : acc))
        : null
      
    const joinedArr = [];
    if (!isEmpty(gamePassesResult)) {
      joinedArr.push(gamePassesResult);
    }
    if (!isEmpty(goldBoostersResult)) {
      joinedArr.push(goldBoostersResult);
    }
    
    setSelectedSkus(joinedArr);

    return () => setSelectedSkus([]);
  }, [items, handleUpdateItems]);

  const buttonTitle = isEmpty(selectedSkus) ? gamePassContinueText : gamePassActivateAndPlayText;

  return (
    <Fragment>
      <div className="payment-activate">
        <div className="payment-activate-container">
          <div className="w-100">
            <div className="payment-activate-title">
              {gamePassInventoryTitle}
            </div>
            {items?.map((i, iIdx) => {
              const filterredInventory = inventory?.filter(sku => i.skuKind === sku.subKind);
              const isChecked = selectedSkus.some(sku => sku.skuKind === i.skuKind);

              return (
                <OrderSkus 
                  key={`OrderSkus_${iIdx}`}
                  item={i} 
                  items={items}
                  selectedSkus={selectedSkus}
                  setSelectedSkus={setSelectedSkus}
                  inventory={inventory}
                  handleUpdateItems={handleUpdateItems}
                  filterredInventory={filterredInventory}
                  isChecked={isChecked}
                />
              )
            })}
          </div>
          <div 
            className="payment-activate-button"
            onClick={handleActivate}
          >
            {buttonTitle}
          </div>
        </div>
      </div>
      <OverrideActiveBoosterToast 
        open={overrideActiveBooster?.result} 
        overrideDetails={overrideActiveBooster} 
        hide={handleOverrideHide}
      />        
    </Fragment>
  )
}
