import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import classNames from 'classnames';
import './Section.scss';

const Section = ({ 
  title, 
  link, 
  linkText,
  titleCentered, 
  titleSmall, 
  backButtonRoute, 
  sectionClassName, 
  sectionHeaderClassName, 
  children, 
  filter,
  openFilter, 
  isFilter, 
  backRoute, 
  close, 
  text, 
  titleMedium,
  titleLarge,
  listed,
  sticky,
  showCart,
  cartCount,
  handleRedirect,
  hidden,
  handleRoute
}) => (
  <div className={classNames("section", sectionClassName)}>
    <div className={classNames(
      "section-header", 
      sectionHeaderClassName, 
      {"section-header-sticky": sticky}
    )}>
      {(title || listed) && 
        <h1 className={classNames(
          "section-title", {
          "section-title-center": titleCentered, 
          "section-title-small": titleSmall, 
          "section-title-medium": titleMedium,
          "section-title-large": titleLarge,
          "section-title-no-bottom": sticky,
          "section-title-hidden": hidden
        })}>
          {title || <span className="section-title-indicator">{listed}</span>}
        </h1>}
      {backButtonRoute && 
        backRoute &&
        !close && 
        <div 
          onClick={backRoute} 
          className={classNames(
            "section-back",
            {"section-back-sticky": sticky}
          )}>
          <div className="section-back-inner">
            <ArrowBackIosNewRoundedIcon className="section-back-icon" />
            <div className="section-back-text">{text}</div>
          </div>
        </div>}
      {backButtonRoute && 
        !backRoute &&
        !close && 
        <Link 
          to={backButtonRoute}
          className={classNames(
            "section-back",
            {"section-back-sticky": sticky}
          )}>
          <div className="section-back-inner">
            <ArrowBackIosNewRoundedIcon className="section-back-icon" />
            <div className="section-back-text">{text}</div>
          </div>
        </Link>}
      {close && 
        <Link 
          to={backButtonRoute} 
          onClick={backRoute} 
          className="section-back-close">
          <CloseRoundedIcon 
            className="section-back-icon-close" 
            fontSize='large' />
        </Link>}
      {link && 
        linkText && 
        handleRoute &&
        <div 
          onClick={() => handleRoute(link)} 
          className="section-link"
        >
          {linkText}
        </div>}
      {filter && 
        <div onClick={openFilter} className="section-filter">
          <div 
            className={classNames(
              "section-filter-icon", 
              {"section-filter-icon-select": isFilter}
            )} 
          />
          <span>Filter</span>
        </div>}
      {showCart && 
        <div 
          className={classNames(
            "section-cart", {
            "section-cart-items": cartCount > 0
          })} 
          onClick={handleRedirect}
        >
          <div className="section-cart-image" /> 
          {cartCount > 0 && 
            <div className="section-cart-count">
              <div className="section-cart-count-text">{cartCount}</div>
            </div>} 
        </div>}
    </div>
    {children}
  </div>
);

export default Section;