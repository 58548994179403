import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { getGamePassCartCount, unauthorizedRedirect } from '../../actions';
import { 
  cartCountSelector, 
  guestSelector, 
  unreadMessagesSelector 
} from '../../selectors';
import { 
  authSteps, 
  duration, 
  gamePassCartCTA, 
  minWidths, 
  storageKeys 
} from '../../constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.scss';

export const Header = ({ 
  openMenu, 
  menuIconHidden, 
  headerLogoClickable, 
  userProfile, 
  showCart 
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const unreadMessages = useSelector(state => unreadMessagesSelector(state));
  const cartCount = useSelector(state => cartCountSelector(state));
  const isGuest = useSelector(state => guestSelector(state));

  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  const sessionToken = localStorage.getItem(storageKeys.sessionToken);
  const hasSessionToken = sessionToken && sessionToken !== '';
  const unexpired = userProfile && !isEmpty(userProfile) && hasSessionToken;

  let profilePhotoStyle = null;
  if (unexpired && userProfile.profileHeader.imageUrl && userProfile.profileHeader.imageUrl !== '') {
    profilePhotoStyle = { backgroundImage: `url(${userProfile.profileHeader.imageUrl})` };
  }

  useEffect(() => {
    if (showCart && !isGuest) {
      dispatch(getGamePassCartCount());
    }
  }, [dispatch, showCart])

  const handleCartRedirect = () => {
    if (isGuest) {
      dispatch(
        unauthorizedRedirect({ 
          isGuest, 
          authSteps: authSteps.login
        })
      );
      return;
    }
    navigate(gamePassCartCTA);
  }

  const handleLogoClick = () => {
    const path = headerLogoClickable ? '/' : '#';
    if (location.pathname !== '/') {
      navigate(path);
    }
    setTimeout(() => {
      const scrollElement = document?.querySelector('.content');
      if (scrollElement && isDesktop) {
        scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0
        });
      } else {
        window.scrollTo(0, 0);
      }
    }, duration.pointOneSecond);
  }
  
  return (
    <div className="header">
      <Container fluid>
        <Row>
          <Col xs="2" sm="3" lg="4">
            {!menuIconHidden && <div>
              <div className="header-profile-pic" style={profilePhotoStyle} onClick={openMenu}>
                {unreadMessages !== 0 && <div className="header-profile-pic-dot"/>}
              </div>
            </div>}
          </Col>
          <Col xs="8" sm="6" lg="4">
            <div 
              onClick={handleLogoClick} 
              className="header-logo" 
            />
          </Col>
          <Col xs="2" sm="3" lg="4">
            {showCart && 
              <div className="header-cart" onClick={handleCartRedirect}>
                <div className="header-cart-image">
                  {cartCount > 0 && 
                    <div className="header-cart-count">{cartCount}</div>} 
                </div>
              </div>}
          </Col>
        </Row>
      </Container>
    </div>
  );
}