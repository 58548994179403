import axios from 'axios';
import { baseUrl } from './url';

export function requestRewards(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/rewards')
  });
}

export function requestFeaturedRewards(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/rewards/featured')
  });
}

export function requestRewardDetails(sessionToken, id) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/rewards/${id}`)
  });
}

export function requestRedeemReward(sessionToken, body) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl(`me/rewards/claim`)
  });
}

export function requestModalNotification(params, sessionToken) {
  return axios.request({
    method: 'GET',
    params,
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/modal-notifications')
  });
}

export function requestDailyRewards(sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/rewards/daily/settings')
  });
}

export function requestClaimDailyRewards(sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    url: baseUrl('me/rewards/daily/claim')
  });
}

export function requestFirstTimeCompletionRewards(sessionToken, rewardKey) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`me/achievements/${rewardKey}`)
  });
}

export function requestClaimAchievementReward(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('me/claim/achievement-reward')
  });
}

export function requestRewardPartners(layout, sessionToken) {
  return axios.request({
    method: 'GET',
    params: { layout },
    headers: { 'Authorization': sessionToken },
    url: baseUrl('promotions/partners')
  });
}