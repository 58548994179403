import axios from 'axios';
import { baseUrl } from './url';

export function requestPaymentMethods(countryCode, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`payment/methods?countryCode=${countryCode}`)
  });
}

export function requestPaymentChannels(method, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`payment/methods/${method}/channels`)
  });
}

export function requestCreatePayment(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl('payment/create', 'v2')
  });
}

export function requestCurrencyConversion(params, sessionToken) {
  return axios.request({
    method: 'GET',
    params: { ...params },
    headers: { 'Authorization': sessionToken },
    url: baseUrl('payment/currency-conversion')
  });
}

export function requestCurrencyConversionBatch(fromCurrency, direction, toAmounts, toCurrency, channelCode, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`payment/currency-conversion-batch?fromCurrency=${fromCurrency}&${direction}Amounts=${toAmounts}&toCurrency=${toCurrency}&channelCode=${channelCode}`)
  });
}

