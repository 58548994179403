import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import MediaQuery from 'react-responsive';
import { BackArrow, Banner, ImagePreview } from '../Commons';
import { Featured, LayoutContent, PageTitle, Stats } from '../Components';
import Gallery from './Gallery';
import GameInfo from './GameInfo';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import {
  firebaseEvents,
  maxWidths,
  minWidths
} from '../../constants';

export class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameName: window.location.pathname.split('/').slice(1)[1],
      imagePreviewUrl: ''
    };
  }

  componentDidMount() {
    if (this.state.gameName !== 'games' && this.state.gameName !== 'game') {
      this.props.getGameDetails(this.state.gameName);
    }
  }

  componentDidUpdate(prevProps) {
    const { game } = this.props;
    if (prevProps.game !== game) {
      logFirebaseEventWithTimestamp(
        firebaseEvents.gamesPage, {
          game_name: game.name
        });
    }
  }

  openImage = (imagePreviewUrl) => {
    this.setState({ imagePreviewUrl });
  }

  render() {
    const { game } = this.props;
    const { imagePreviewUrl } = this.state;
    if (game && !isEmpty(game)) {
      return (
        <LayoutContent outerClassName="game" innerClassName="game-inner">
          <PageTitle title={game.name} />
          <MediaQuery minWidth={minWidths.tablet}>
            <BackArrow text="Back" isInline />
          </MediaQuery>
          <MediaQuery maxWidth={maxWidths.mobileSM}>
            <BackArrow isInline />
          </MediaQuery>
          <Featured fluid>
            <Banner
              id={game.id}
              title={game.name}
              text={game.short_desc}
              imageUrl={game.image_url}
              buttonText="Launch Game"
              buttonId="launchGame"
              buttonLink={game.launch_url}
              buttonAnchor
              openInNewTab
              imageClickable
            >
              <Stats stats={game.stats} lg={4} fluid />
            </Banner>
          </Featured>
          {game.screenshots.length > 0 && <Gallery title="Gallery" screenshots={game.screenshots} openImage={this.openImage} />}
          <GameInfo info={game.game_info} />
          <ImagePreview url={imagePreviewUrl} openImage={this.openImage} />
        </LayoutContent>
      );
    }

    return null;
  }
}