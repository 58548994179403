import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Skeleton } from '@mui/material';
import { TopUpChannel } from './TopUpChannel';
import {
  connectFromTopUp,
  getPaymentMethods,
  setLoading,
  setOpenModal,
  setTopUpVisible
} from '../../actions';
import { LayoutContent, PageTitle } from '../Components';
import {
  countryCode,
  firebaseEvents,
  minWidths,
  selectPaymentMethodText,
  siteRoutes,
  topUpChannelKinds,
  topUpChannels,
  walletPageRoutes,
  wallets
} from '../../constants';
import { topUpBuyDataSelector } from '../../selectors';
import { useWalletConnect } from '../../hooks/useWalletConnect';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import './TopUp.scss';

export const TopUp = ({ hide }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { open, isConnected } = useWalletConnect();

  const [openTabs, setOpenTabs] = useState([]);
  const [value, setValue] = useState('');
  const [method, setMethod] = useState('');
  const [kind, setKind] = useState('');

  const methods = useSelector(state => topUpBuyDataSelector(state));
  
  const handleMediaQueryChange = (matches) => {
    if (matches) {
      navigate(walletPageRoutes.wallet);
      dispatch(setTopUpVisible(true));
    } 
  }
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet }, undefined,  handleMediaQueryChange);

  useEffect(() => {
    logFirebaseEventWithTimestamp(firebaseEvents.walletTopupInitiated);
  }, [])
  
  useEffect(() => {
    dispatch(setLoading(false))
    dispatch(getPaymentMethods(countryCode));
    dispatch(setOpenModal(false));
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(methods)) {
      const eWalletMethod = methods?.find(m => m.kind === topUpChannelKinds.eWallet);
      const gcashChannel = eWalletMethod?.channels?.find(c => c.code === topUpChannels.gcash);
      if (eWalletMethod && gcashChannel) {
        setOpenTabs([eWalletMethod.kind]);
        setKind(eWalletMethod.kind);
        handleChannel(gcashChannel.code, gcashChannel.name);
      }
    }
  }, [methods])

  const handleOpen = async() =>  {
    if (isConnected) return;
    dispatch(setLoading(true));
    await open();
    dispatch(setTopUpVisible(false));
    dispatch(setLoading(false));
  }

  const handleChange = (e, i, kind) => {
    setKind(kind);
    handleChannel(i.code, e.target.value);
  }

  const handleChannel = (method, value) => {
    setMethod(method);
    setValue(value);
  }

  const handleKindTab = (kind) => {
    const set = new Set([...openTabs]);
    if (set.has(kind)) {
      set.delete(kind);
    } else {
      set.add(kind);
    }
    setOpenTabs([...set]);
  }

  const handleNavigate = () => {
    const params = `method=${kind}&mode=${value}&channel=${method}`;
    const baseRoute = !isDesktop ? walletPageRoutes.topupPayment : walletPageRoutes.wallet;
    const endpoint = `${baseRoute}?${params}`;
    if (method === wallets.walletConnect.name) {
      if (!isConnected) {
        handleOpen();
        dispatch(connectFromTopUp(endpoint));
        return;
      }
    }
    if (!isEmpty(openTabs) && method) {
      logFirebaseEventWithTimestamp(firebaseEvents.walletTopupSelectPayment);
    }
    navigate(endpoint);
  }

  const handleBack = () => {
    if (!isDesktop) dispatch(setTopUpVisible(false));
    navigate(walletPageRoutes.wallet);
  }
  
  useEffect(() => {
    return () => {
      if (!isDesktop) dispatch(setTopUpVisible(false));
    }
  }, [dispatch, isDesktop])

  return ( 
    <LayoutContent outerClassName="buy-layout" innerClassName="buy-inner">
      <PageTitle title="Buy MGC" />
      <div className="buy">
        <div className="buy-wrapper">
          <div className="buy-header">
            <ArrowBackIosNewRoundedIcon
              onClick={handleBack}
              className="buy-header-back"
            />
            <div>{selectPaymentMethodText}</div>
            <CloseRoundedIcon
              onClick={hide}
              className="buy-header-back-close"
              fontSize="large"
            />
          </div>
          <div className="buy-container">
            {isEmpty(methods) &&
              <>
                <Skeleton
                  sx={{
                    bgcolor: '#19082e',
                    borderRadius: '12px'
                  }}
                  animation="pulse"
                  variant="rounded"
                  width="100%"
                  height={100}
                  className="my-3"
                />
                <Skeleton
                  sx={{
                    bgcolor: '#19082e',
                    borderRadius: '12px'
                  }}
                  animation="pulse"
                  variant="rounded"
                  width="100%"
                  height={100}
                  className="my-3"
                />
                <Skeleton
                  sx={{
                    bgcolor: '#19082e',
                    borderRadius: '12px'
                  }}
                  animation="pulse"
                  variant="rounded"
                  width="100%"
                  height={100}
                  className="my-3"
                />
              </>}
            {!isEmpty(methods) && methods?.map((c, cIdx) => (
              <div key={`TopUpChannel_${cIdx}`} className="buy-container-channel">
                <TopUpChannel
                  {...c}
                  handleChange={handleChange}
                  handleKindTab={handleKindTab}
                  value={value}
                  openTabs={openTabs}
                  isDesktop={isDesktop}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="buy-continue-wrapper">
        <div
          onClick={handleNavigate}
          className={classNames(
            "buy-button",
            {"buy-button-enable": !isEmpty(openTabs) && method}
          )}
        >
          <button className="buy-continue">Continue</button>
        </div>
      </div>
    </LayoutContent>
  )
}
