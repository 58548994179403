export const defaultMobileNumberPrefix = "+63";
export const defaultCountry = "Philippines";
export const defaultWalletCurrency = "MGC";
export const defaultDomain = "https://metaversego.gg";
export const defaultWebAppHost = "app.metaversego.gg";
export const defaultWebAppHostDev = "app.mgodev.com";
export const defaultWebAppLocalhost = "localhost:3000";
export const defaultWebAppDomain = `https://${defaultWebAppHost}`;
export const defaultQuantity = 1;
export const defaultPaymentMethod = 'MGC';
export const defaultProductsLoadingText = 'Fetching Load Denominations';
export const defaultHeaderHeight = 64;
export const urlValidation = '://';
export const logoUrl = "https://metaversego.gg/images/mgc.png";
export const messagesEmpty = "You have no messages yet."
export const mobileNumberEmptyInputMessage = "Please enter your mobile number.";
export const mobileNumberValidationErrorMessage = "Your mobile number should be in the following format: 9XXXXXXXXX.";
export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const walletEmpty = "You have no collectibles yet";
export const walletTransactionHeader = ["Status", "Type", "Channel", "Amount", "Date Created", ""]
export const previousRoute = "previousRoute";
export const answerFormats = {
  textField: 'textfield',
  textArea: 'textarea',
  multipleChoice: 'multiplechoice',
  multipleSelect: 'multipleselect'
};
export const fieldTypes = {
  textField: 'text',
  multipleChoice: 'radio',
  multipleSelect: 'checkbox'
};
export const formInputKinds = {
  name: 'name',
  country: 'address_country',
  street: 'address_street_line_1',
  city: 'address_city',
  state: 'address_state',
  zipCode: 'address_zip_code',
  phone: 'phone',
  email: 'email',
  username: 'username',
  earlyAccessCode: 'earlyAccessCode'
};
export const defaultTextFieldPlaceholder = 'Type here...';
export const defaultTimeoutMS = 500;
export const selectPaymentMethodText = 'Select Payment Method';
export const otpVerificationText = 'Please enter 6 character verification code sent to your number';
export const otpInputTitle = '6-character verification code';
export const otpMaxLength = 6;
export const duration = {
  pointOneSecond: 100,
  pointThreeSeconds: 300,
  oneSecond: defaultTimeoutMS * 2,
  twoSeconds: defaultTimeoutMS * 4,
  threeSeconds: defaultTimeoutMS * 6,
  fourSeconds: defaultTimeoutMS * 8
};
export const isDev = window.location.href.includes(defaultWebAppLocalhost) ||
  window.location.href.includes(defaultWebAppHostDev);
export const otpDefaultTimes = {
  minutes: 1,
  seconds: 30,
};
export const responseStatus = {
  ok: 200,
  badRequest: 400,
  unauthorized: 403,
  notFound: 404
};
export const devicePlatforms = {
  desktop: 'web',
  mobile: 'mobile'
};
export const featuredBannerButtons = {
  joinTheGame: 'Join the Game',
  viewQuests: 'View Quests',
  startPlaying: 'Start Playing',
  joinDiscord: 'Join Discord',
};
export const experiences = {
  quest: 'quest',
  discordDailyBoost: 'discord-daily-boost',
};
export const defaultCTATexts = {
  back: 'Back',
  next: 'Next',
  skip: 'Skip',
  finish: 'Finish',
  viewAll: 'View All'
};
export const defaultSchedulerTimeout = 5000;
export const defaultFirstTimeCompletionRewardsTimeout = 1200;
export const storageKeys = {
  sessionToken: 'sessionToken',
  previousRoute: 'previousRoute',
  showSpinModal: 'showSpinModal',
  isSpinModalShown: 'isSpinModalShown',
  isTutorial: 'isTutorial',
  switchToApp: {
    home: 'switchToAppHome',
    wenLambo: 'switchToAppWenLambo',
    wenLamboGames: 'switchToAppWenLamboGames'
  },
  pmSource: 'pmsource',
  dynamicSlug: 'dynamicSlug',
  source: 'source',
  slug: 'slug',
  tag: 'tag',
  ref: 'ref',
  isNew: 'isNew',
  isNewUser: 'isNewUser',
  referralCode: 'referralCode',
  username: 'username',
  mobileNumber: 'mobileNumber',
  redirectUri: 'redirectUri',
  clientId: 'clientId',
  tutorialLink: 'tutorialLink',
  tournamentToken: 'tournamentToken',
  isNewlyRegistered: 'isNewlyRegistered',
  isDailyRewardsShown: 'isDailyRewardsShown',
  isWalkthroughFinished: 'isWalkthroughFinished',
  officialLaunch: 'officialLaunch',
  wenLamboOfficialLaunch: 'wenLamboOfficialLaunch',
  firebaseAuthErrorMessage: 'firebaseAuthErrorMessage',
  newPIN: 'newPIN',
  isBuyBoosterShown: 'isBuyBoosterShown',
  isLoggedInTutorial: 'isLoggedInTutorial',
  checkoutUrl: 'checkoutUrl',
  showSetPIN: 'showSetPIN',
  isFreeGamePassModalShown: 'isFreeGamePassModalShown',
  isGamePassTermsAndConditionsShown: 'isGamePassTermsAndConditionsShown',
  isFromReferral: 'isFromReferral',
  showReferralAdBanner: 'showReferralAdBanner',
  isFromLogin: 'isFromLogin',
  disconnectWallet: 'disconnectWallet'
};
export const mobileAppPromptText = {
  home: 'Enjoy a better MetaverseGo experience in app!',
  wenLambo: 'Wen Lambo Games are smoother in the app!',
  wenLamboDescription: 'Elevate your gaming experience and earn gold when you play on the app.',
  wenLamboGames: 'Want to level up the gameplay?',
  wenLamboGamesDescription: 'Beat your highest score when you play on the app for a more enjoyable experience!',
  button: 'Switch to App',
  redirectLink: 'intent://app.metaversego.gg/param#Intent;scheme=https;package=gg.metaversego.mgoandroid;end'
};
export const defaultGameUrl = 'https://games.metaversego.gg';
export const defaultTestMenuTexts = {
  title: 'Test Menu',
  conquest: 'CONQuest'
};
export const defaultModals = {
  goldBonanza: 'gold-bonanza' // add modal name here for additional modal
};
export const copyToClipboardTexts = {
  copied: 'Copied!',
  copyCode: 'Copy Code'
};