import completed from '../assets/spend/payment-success.png';
import pending from '../assets/spend/payment-process.png';
import failed from '../assets/spend/payment-failed.png';

export const transactionsTab = ['MGC', 'Fiat', 'Gold', 'Crypto'];
export const fiatText = 'Fiat';
export const maxLimit = 10;
export const minLimit = 3;
export const startingOffset = 0;
export const offsetIncrementValue = 10;
export const status = {
  completed: 'completed',
  pending: 'pending',
  failed: 'failed'
}
export const secondsInterval = 1000;
export const timeInterval = 5000;
export const secondsLimit = 10
export const channels = {
  sevenEleven: '7eleven'
};
export const direction = {
  incoming: 'incoming'
};
export const retryPaymentText = 'Retry payment';
export const transactionParams = {
  referenceId: 'referenceId',
  id: 'id',
  authStep: 'authStep',
  pin: 'pin'
};
export const statusIcons = {
  completed: completed,
  pending: pending,
  failed: failed
};