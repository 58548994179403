import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { HomeCredit } from './HomeCredit';
import {
  getGoldCoinsValue,
  getWalletCredits
} from '../../utils/getWalletCredits';
import { getUserProfile } from '../../actions';
import {
  authSteps,
  earnGoldText,
  gamePassGamesCTA,
  goldCoinsText,
  mgcDefaultCredits,
  minWidths,
  siteRoutes,
  topUpText
} from '../../constants';
import { unauthorizedRedirect } from '../../actions';
import goldCoinsDesktopBg from '../../assets/credits/gold-coins-desktop.jpg';
import goldCoinsMobileBg from '../../assets/credits/gold-coins-mobile.jpg';
import mgoCreditsDesktopBg from '../../assets/credits/mgo-credits-desktop.jpg';
import mgoCreditsMobileBg from '../../assets/credits/mgo-credits-mobile.jpg';
import './Home.scss';

export const HomeCredits = ({ crypto, transparent }) => {
  const dispatch = useDispatch();
  const isGuest = useSelector(state => state.layout.isGuest);
  const isDesktop = useMediaQuery({ minWidth: minWidths.tablet });

  let topUpLink = isDesktop ? `${siteRoutes.wallet}?topup=true` : siteRoutes.topUp;

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch])

  const walletCreditsObject = getWalletCredits(crypto);
  const walletCredits = walletCreditsObject ? walletCreditsObject.val : mgcDefaultCredits;
  const goldCoinsValue = getGoldCoinsValue(crypto);

  const handleRoute = (route) => {
    dispatch(
      unauthorizedRedirect({ 
        isGuest, 
        authSteps: authSteps.login, 
        route 
      })
    );
  }

  let mgoCreditsBackground = mgoCreditsMobileBg;
  let goldCoinsBackground = goldCoinsMobileBg;

  if (isDesktop) {
    mgoCreditsBackground = mgoCreditsDesktopBg;
    goldCoinsBackground = goldCoinsDesktopBg;
  }

  return (
    <div className={classNames(
      "home-credits",
      {"home-credits-transparent": transparent}
    )}>
      <HomeCredit
        className="home-credit-wallet"
        title="MetaverseGo Credits"
        background={mgoCreditsBackground}
        amount={walletCredits}
        shouldCenter
      >
        <div 
          onClick={() => handleRoute(topUpLink)} 
          className="home-credit-topup"
        >
          {topUpText}
          <KeyboardArrowRightRoundedIcon className="home-credit-topup-arrow" />
        </div>
      </HomeCredit>
      <HomeCredit
        className="home-credit-gold-coins"
        title={goldCoinsText}
        background={goldCoinsBackground}
        amount={goldCoinsValue}
      >
        <div
          onClick={() => handleRoute(gamePassGamesCTA)} 
          className="home-credit-topup"
        >
          {earnGoldText}
          <KeyboardArrowRightRoundedIcon className="home-credit-topup-arrow" />
        </div>
      </HomeCredit>
    </div>
  )
}