import { put, takeLatest, call } from 'redux-saga/effects';
import { 
  failedRequests,
  setDisplayToast, 
  setErrorMessage, 
  setLoading, 
  setSpinAvailable, 
  setSpinPrize, 
  setSpinPrizes, 
  setWenLamboSpinTheWheelDetails
} from '../actions';
import { 
  GET_SPIN_AVAILABLE, 
  GET_SPIN_PRIZE, 
  GET_SPIN_PRIZES, 
  GET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS
} from '../actions/constants';
import { 
  requestSpin, 
  requestSpinAvailable, 
  requestSpinPrizes, 
  requestWenLamboSpinTheWheelDetails
} from '../api/spinTheWheel';
import {
  errorCodes,
  responseStatus,
  storageKeys
} from '../constants';

const getSpinPrizeSaga = function* (payload) {
  const { token, prize, marketingId } = payload.payload;
  yield put(setLoading(true));
  try {
    const sessionToken = token.length > 0 ? token : localStorage.getItem(storageKeys.sessionToken);
    const finalPrize = prize ? `?prize=${prize}` : '';
    const response = yield call(requestSpin, finalPrize, marketingId, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setSpinPrize(response.data.d));
    }
    yield put(setLoading(false));
    } catch (e) {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const hasSessionToken = sessionToken && sessionToken.length > 0;
    if (e.response && e.response.data) {
      const { token } = payload.payload;
      if (e.response.data.code === errorCodes.unauthorized) {
        if (hasSessionToken){
          localStorage.setItem(storageKeys.previousRoute, window.location.href);
          window.location.href = '/pin';
        }
      }
      const toast = {
        result: false,
        title: e.response.data.code,
        message: e.response.data.message
      };
      if (!hasSessionToken && !token){
        yield put(setErrorMessage({
          result: true,
          message: 'Please login and redeem a Gudi Squad NFT to spin the wheel.'
        }));
      } else {
        yield put(setDisplayToast(toast));
      }
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        isExemptedFromRedirection: true
      }
      yield put(failedRequests(failedData));
    }
    yield put(setLoading(false));
  }
}

const getSpinPrizesSaga = function* (payload) {
  yield put(setLoading(true));
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestSpinPrizes, payload.payload, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      const prizeArr = response.data.d;
      let newPrizes = {};
      prizeArr.forEach((item, index) => {
        newPrizes[index + 1] = item;
      });
      yield put(setSpinPrizes(newPrizes));
    }
    yield put(setLoading(false));
  } catch (e) {
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
    yield put(setLoading(false));
  }
}

const getSpinAvailableSaga = function* (payload) {
  if (!payload.payload) return;
  const { token, marketingId } = payload.payload;
  try {
    const sessionToken = payload.payload && token.length > 0 ? token : localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestSpinAvailable, marketingId, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setSpinAvailable(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

const getWenLamboSpinTheWheelDetailsSaga = function* (payload) {
  const { marketingId, tutorial } = payload.payload;
  try {
    const sessionToken = localStorage.getItem(storageKeys.sessionToken);
    const response = yield call(requestWenLamboSpinTheWheelDetails, marketingId, tutorial, sessionToken);
    if (response.status >= responseStatus.ok && response.status < responseStatus.badRequest) {
      yield put(setWenLamboSpinTheWheelDetails(response.data.d));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response && e.response.data) {
      const failedData = {
        status: e.response.status,
        message: e.response.data.description || e.response.data.message,
        code: e.response.data.code,
        url: e.response.config.url,
        showErrorToast: true
      }
      yield put(failedRequests(failedData));
    }
  }
}

export default function* spinSaga() {
  yield takeLatest(GET_SPIN_PRIZE, getSpinPrizeSaga);
  yield takeLatest(GET_SPIN_PRIZES, getSpinPrizesSaga);
  yield takeLatest(GET_SPIN_AVAILABLE, getSpinAvailableSaga);
  yield takeLatest(GET_WEN_LAMBO_SPIN_THE_WHEEL_DETAILS, getWenLamboSpinTheWheelDetailsSaga);
}