import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import { TutorialBox } from '../Components';
import { logFirebaseEventWithTimestamp } from '../../utils/logFirebaseEvent';
import {
  firebaseEvents,
  homeMenuItemRoutes,
  storageKeys
} from '../../constants';
import './Home.scss';

export const HomeMenuItems = ({ menuItems }) => {
  const navigate = useNavigate();

  const boxRef = useRef(null);
  const [openTutorial, setTutorial] = useState(false);

  const tutorialStep = useSelector((state) => state.homeTutorial.step);

  const isNew = localStorage.getItem(storageKeys.isNew);

  useEffect(() => {
    window.dispatchEvent(new Event('storage'));
  }, []);
  
  useEffect(() => {
    if (isNew) setTutorial(true);
  }, [isNew])

  const onMenuItemClick = (route) => {
    let event = '';
    if (route === homeMenuItemRoutes.gamePass) {
      event = firebaseEvents.wenLamboHome;
    } else if (route === homeMenuItemRoutes.gameCenter) {
      event = firebaseEvents.gamesHome;
    }
    if (event !== '') {
      logFirebaseEventWithTimestamp(event);
    }
    navigate(route);
  }

  return (
    <div ref={boxRef} className="home-menu-items">
      {menuItems.map((m, mIdx) => {
        const route = m.route;
        return (
          <div key={`HomeMenu_${mIdx}_${m.name}`} className="home-menu-item">
            <div
              className="home-menu-item-link"
              onClick={() => onMenuItemClick(route)}
            >
              <div id={m.id} className={classNames(
                "home-menu-item-icon",
                `home-menu-item-icon-${kebabCase(m.name.toLowerCase())}`
              )}>
                {m.dot &&
                  <div className="home-menu-item-dot" />}
                {openTutorial &&
                  tutorialStep === m.step && 
                  <div className="home-menu-item-arrow" />}
              </div>
              <div>{m.name}</div>
            </div>
            {openTutorial &&
              tutorialStep === m.step && 
              <TutorialBox boxRef={boxRef} setTutorial={setTutorial} />}
          </div>
        );
      })}
    </div>
  )
};
