import axios from 'axios';
import { baseUrl } from './url';

export function requestSpin(prize, marketingId, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`promotions/${marketingId}/spin${prize}`)
  });
}

export function requestSpinPrizes(marketingId, sessionToken) {
  return axios.request({
    method: 'GET',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`promotions/${marketingId}/spin/prizes`)
  });
}

export function requestSpinAvailable(marketingId, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`promotions/${marketingId}/spin/available`)
  });
}

export function requestClaim(body, sessionToken) {
  return axios.request({
    method: 'POST',
    headers: { 'Authorization': sessionToken },
    data: body,
    url: baseUrl("claim/rewards")
  });
}

export function requestWenLamboSpinTheWheelDetails(marketingId, tutorial, sessionToken) {
  return axios.request({
    method: 'GET',
    params: { isTutorial: tutorial },
    headers: { 'Authorization': sessionToken },
    url: baseUrl(`wenlambo/${marketingId}/spin`)
  });
}

