import { combineReducers } from 'redux';
import login from './login';
import pin from './pin';
import layout from './layout';
import home from './home';
import games from './games';
import nft from './nft';
import launchpad from './launchpad';
import marketplace from './marketplace';
import collections from './collections';
import communities from './communities';
import spend from './spend';
import transactions from './transactions';
import chat from './chat';
import tutorial from './tutorial';
import profile from './profile';
import quests from './quests';
import homeTutorial from './homeTutorial';
import redeem from './redeem';
import spinTheWheel from './spinTheWheel';
import topup from './topup';
import tournaments from './tournaments';
import rewards from './rewards';
import withdrawal from './withdrawal';
import gamepass from './gamepass';
import referral from './referral';
import flags from './flags';
import cart from './cart';
import checkout from './checkout';
import scholarship from './scholarship';
import banners from './banners';
import vouchers from './vouchers';
import crypto from './crypto';

const rootReducer = combineReducers({
  login,
  pin,
  layout,
  home,
  games,
  nft,
  launchpad,
  marketplace,
  collections,
  communities,
  spend,
  transactions,
  chat,
  tutorial,
  profile,
  quests,
  homeTutorial,
  redeem,
  spinTheWheel,
  topup,
  tournaments,
  rewards,
  gamepass,
  referral,
  withdrawal,
  flags,
  cart,
  checkout,
  scholarship,
  banners,
  vouchers,
  crypto
});

export default rootReducer;